import React, { useState } from "react";
import { Grid, IconButton, TextField, Tooltip } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";

const AddLink = (props) => {
  const { classes, onUploaded, title } = props;
  const [url, setUrl] = useState("");

  const handleChange = (e) => {
    setUrl(e.target.value);
  };

  const handleSave = () => {
    onUploaded(url);
  };

  return (
    <Grid item xs={12} className="ims-right" style={{ paddingTop: "8px" }}>
      <TextField
        placeholder={`Add ${title} URL`}
        fullWidth
        size="small"
        value={url}
        onChange={handleChange}
        helperText={"Click Check Icon and Save..."}
        sx={classes.addLinkField}
        InputProps={{
          endAdornment: (
            <Tooltip title={`Add ${title} URL`} arrow>
              <IconButton sx={classes.addLinkBtn} onClick={handleSave}>
                <CheckIcon />
              </IconButton>
            </Tooltip>
          ),
        }}
      />
    </Grid>
  );
};

AddLink.defaultProps = {
  title: "Image",
};

export default AddLink;
