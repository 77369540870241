import React, { forwardRef } from "react";

const RoundedDark = forwardRef((props, ref) => {
  const { href, id, imagePos, handleClick, imageRef, defaultScale, readOnly } = props;
  const { calX, calY, scale } = imagePos || {
    calX: 0,
    calY: 0,
    scale: defaultScale,
  };

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="105"
      height="105"
      viewBox="0 0 105 105"
      fill="none"
      style={{ userSelect: "none" }}
      onClick={handleClick}
    >
      <g filter="url(#filter0_d_14731_299244)" ref={ref}>
        <circle
          cx="50"
          cy="50"
          r="46.5"
          stroke="#1E1E1E"
          strokeWidth="7"
          shapeRendering="crispEdges"
          fill={`url(#imageSource_${id})`}
          ref={imageRef}
          style={{ cursor: readOnly ? "pointer" : "move" }}
        />
      </g>
      <defs>
        <pattern
          id={`imageSource_${id}`}
          patternUnits="userSpaceOnUse"
          width="100"
          height="100"
        >
          <image
            x={calX}
            y={calY}
            height={`${scale || defaultScale}%`}
            href={href}
          />
        </pattern>
        <filter
          id="filter0_d_14731_299244"
          x="0"
          y="0"
          width="105"
          height="105"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="5" dy="5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.117647 0 0 0 0 0.117647 0 0 0 0 0.117647 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_14731_299244"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_14731_299244"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
});

RoundedDark.displayName = "RoundedDark";

export default RoundedDark;
