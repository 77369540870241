import { Transforms, Editor, Range, Element, Path, Node } from "slate";
import { ReactEditor } from "slate-react";
import { customInsertNode } from "./helper";

export const DEFAULT_TABLE_NODE = () => ({
  type: "table",
  children: [
    {
      type: "table-row",
      children: [
        {
          type: "table-cell",
          children: [
            {
              type: "paragraph",
              children: [{ text: "" }],
              cellBgColor: "#FFFFFF",
            },
          ],
        },
        {
          type: "table-cell",
          children: [
            {
              type: "paragraph",
              children: [{ text: "" }],
              cellBgColor: "#FFFFFF",
            },
          ],
        },
        {
          type: "table-cell",
          children: [
            {
              type: "paragraph",
              children: [{ text: "" }],
              cellBgColor: "#FFFFFF",
            },
          ],
        },
      ],
    },
    {
      type: "table-row",
      children: [
        {
          type: "table-cell",
          children: [
            {
              type: "paragraph",
              children: [{ text: "" }],
              cellBgColor: "#FFFFFF",
            },
          ],
        },
        {
          type: "table-cell",
          children: [
            {
              type: "paragraph",
              children: [{ text: "" }],
              cellBgColor: "#FFFFFF",
            },
          ],
        },
        {
          type: "table-cell",
          children: [
            {
              type: "paragraph",
              children: [{ text: "" }],
              cellBgColor: "#FFFFFF",
            },
          ],
          size: {
            widthInPercent: 100,
            height: 100,
            width: 365.3307291666667,
          },
        },
      ],
    },
    {
      type: "table-row",
      children: [
        {
          type: "table-cell",
          children: [
            {
              type: "paragraph",
              children: [{ text: "" }],
              cellBgColor: "#FFFFFF",
            },
          ],
        },
        {
          type: "table-cell",
          children: [
            {
              type: "paragraph",
              children: [{ text: "" }],
              cellBgColor: "#FFFFFF",
            },
          ],
        },
        {
          type: "table-cell",
          children: [
            {
              type: "paragraph",
              children: [{ text: "" }],
              cellBgColor: "#FFFFFF",
            },
          ],
          size: { height: 300, widthInPercent: 100 },
        },
      ],
    },
  ],
  rows: 3,
  columns: 3,
});

const prefixKey = (obj, pk = "") => {
  return Object.keys(obj).reduce((a, b) => {
    a[`${pk}${b}`] = obj[b];
    return a;
  }, {});
};

const parseByPrefixKey = (obj, pk = "") => {
  return Object.keys(obj).reduce((a, b) => {
    if (b.indexOf(pk) !== -1 && pk) {
      const key = b.split(pk)[1];
      a[key] = obj[b];
    }
    return a;
  }, {});
};

export class TableUtil {
  constructor(editor) {
    this.editor = editor;
  }

  insertTable = (rows, columns) => {
    const [tableNode] = Editor.nodes(this.editor, {
      match: (n) =>
        !Editor.isEditor(n) && Element.isElement(n) && n.type === "table",
      mode: "highest",
    });

    if (tableNode) return;
    if (!rows || !columns) {
      return;
    }
    //Creating a 2-d array of blank string as default text for the table
    const cellText = Array.from({ length: rows }, () =>
      Array.from({ length: columns }, () => "")
    );
    const newTable = createTableNode(cellText, rows, columns);
    customInsertNode(this.editor, newTable);
  };

  removeTable = () => {
    Transforms.removeNodes(this.editor, {
      match: (n) =>
        !Editor.isEditor(n) && Element.isElement(n) && n.type === "table",
      // mode:'highest'
    });
  };

  getDOMNode = (path) => {
    try {
      const [tableNode] = Editor.nodes(this.editor, {
        at: path,
        match: (n) =>
          !Editor.isEditor(n) && Element.isElement(n) && n.type === "table",
      });
      const tableDOM = ReactEditor.toDOMNode(this.editor, tableNode[0]);
      return tableDOM;
    } catch (err) {
      // console.log(err);
    }
  };

  getTotalWidth = (path, colCount = 0) => {
    try {
      let totalWidth = 0;
      for (let i = 0; i < colCount; i++) {
        const dom = ReactEditor.toDOMNode(
          this.editor,
          Node.get(this.editor, [...path, 0, i])
        );
        totalWidth += parseFloat(dom.style.width);
      }
      return totalWidth;
    } catch (err) {
      console.log(err);
    }
  };

  insertRow = (action) => {
    const { selection } = this.editor;

    if (!!selection && Range.isCollapsed(selection)) {
      const [tableNode] = Editor.nodes(this.editor, {
        match: (n) =>
          !Editor.isEditor(n) && Element.isElement(n) && n.type === "table-row",
      });
      if (tableNode) {
        const [[table, tablePath]] = Editor.nodes(this.editor, {
          match: (n) =>
            !Editor.isEditor(n) && Element.isElement(n) && n.type === "table",
        });
        const [, currentRow] = tableNode;

        const path = action === "after" ? Path.next(currentRow) : currentRow;

        Transforms.insertNodes(
          this.editor,
          createRow(Array(table.columns).fill("")),
          {
            at: path,
          }
        );
        Transforms.setNodes(
          this.editor,
          { rows: table.rows + 1 },
          {
            at: tablePath,
          }
        );
      }
    }
  };

  deleteRow = () => {
    try {
      const { selection } = this.editor;

      if (!!selection && Range.isCollapsed(selection)) {
        const [tableNode] = Editor.nodes(this.editor, {
          match: (n) =>
            !Editor.isEditor(n) &&
            Element.isElement(n) &&
            n.type === "table-row",
        });
        if (tableNode) {
          const [[table, tablePath]] = Editor.nodes(this.editor, {
            match: (n) =>
              !Editor.isEditor(n) && Element.isElement(n) && n.type === "table",
          });
          const [, currentRow] = tableNode;

          const path = currentRow;

          Transforms.removeNodes(this.editor, {
            at: path,
          });

          Transforms.setNodes(
            this.editor,
            { rows: table.rows - 1 },
            {
              at: tablePath,
            }
          );
        }
      }
    } catch (error) {
      console.log("Error", error);
    }
  };

  insertColumn = (action) => {
    const { selection } = this.editor;
    if (!!selection && Range.isCollapsed(selection)) {
      const [tableNode] = Editor.nodes(this.editor, {
        match: (n) =>
          !Editor.isEditor(n) &&
          Element.isElement(n) &&
          n.type === "table-cell",
      });
      if (tableNode) {
        const [[table, tablePath]] = Editor.nodes(this.editor, {
          match: (n) =>
            !Editor.isEditor(n) && Element.isElement(n) && n.type === "table",
        });
        const [, currentCell] = tableNode;
        const startPath =
          action === "after" ? Path.next(currentCell) : currentCell;

        // The last two indices of the path represents the row and column. We need to add one cell to each row starting from the first row
        startPath[startPath.length - 2] = 0;
        for (let row = 0; row < table.rows; row++) {
          Transforms.insertNodes(this.editor, createTableCell(""), {
            at: startPath,
          });
          startPath[startPath.length - 2]++;
        }

        Transforms.setNodes(
          this.editor,
          { columns: table.columns + 1 },
          {
            at: tablePath,
          }
        );
      }
    }
  };

  deleteColumn = () => {
    try {
      const { selection } = this.editor;
      if (!!selection && Range.isCollapsed(selection)) {
        const [tableNode] = Editor.nodes(this.editor, {
          match: (n) =>
            !Editor.isEditor(n) &&
            Element.isElement(n) &&
            n.type === "table-cell",
        });
        if (tableNode) {
          const [[table, tablePath]] = Editor.nodes(this.editor, {
            match: (n) =>
              !Editor.isEditor(n) && Element.isElement(n) && n.type === "table",
          });
          const [, currentCell] = tableNode;
          const startPath = currentCell;

          // The last two indices of the path represents the row and column. We need to add one cell to each row starting from the first row
          startPath[startPath.length - 2] = 0;
          for (let row = 0; row < table.rows; row++) {
            Transforms.removeNodes(this.editor, {
              at: startPath,
            });
            startPath[startPath.length - 2]++;
          }

          Transforms.setNodes(
            this.editor,
            { columns: table.columns - 1 },
            {
              at: tablePath,
            }
          );
        }
      }
    } catch (error) {
      console.log("Error ", error);
    }
  };

  updateTableStyle = (styleProps, paths) => {
    try {
      const { selection } = this.editor;
      if (!!selection && Range.isCollapsed(selection)) {
        const tableProps = parseByPrefixKey(styleProps, "table.");
        const rowProps = parseByPrefixKey(styleProps, "row.");
        const cellProps = parseByPrefixKey(styleProps, "col.");
        const { currentCellPath, currentRowPath, currentTablePath } = paths;
        Transforms.setNodes(
          this.editor,
          { ...tableProps },
          { at: currentTablePath }
        );
        Transforms.setNodes(
          this.editor,
          { ...rowProps, tableBorder: tableProps?.borderColor },
          { at: currentRowPath }
        );
        Transforms.setNodes(
          this.editor,
          {
            ...cellProps,
            rowBorder: rowProps?.borderColor,
            tableBorder: tableProps?.borderColor,
          },
          { at: currentCellPath }
        );

        // cell bg entire
        if (
          cellProps?.entireBgColor ||
          tableProps?.borderColor ||
          rowProps?.borderColor
        ) {
          const { rows } = tableProps;

          for (let r = 0; r < rows; r++) {
            Transforms.setNodes(
              this.editor,
              {
                entireBgColor: cellProps?.entireBgColor,
              },
              { at: [currentCellPath[0], r, currentCellPath[2]] }
            );
          }
        }

        // cell border all
        if (tableProps?.borderColor || rowProps?.borderColor) {
          const { rows, columns } = tableProps;

          for (let r = 0; r < rows; r++) {
            for (let c = 0; c < columns; c++) {
              Transforms.setNodes(
                this.editor,
                {
                  entireBorderColor: tableProps?.borderColor,
                  rowBorder: rowProps?.borderColor,
                },
                { at: [currentCellPath[0], r, c] }
              );
            }
          }
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  getTableProps = () => {
    const { selection } = this.editor;
    if (!!selection && Range.isCollapsed(selection)) {
      const [tableNode] = Editor.nodes(this.editor, {
        match: (n) =>
          !Editor.isEditor(n) && Element.isElement(n) && n.type === "table",
      });

      const [tableCellNode] = Editor.nodes(this.editor, {
        match: (n) =>
          !Editor.isEditor(n) &&
          Element.isElement(n) &&
          n.type === "table-cell",
      });
      const [tableRowNode] = Editor.nodes(this.editor, {
        match: (n) =>
          !Editor.isEditor(n) && Element.isElement(n) && n.type === "table-row",
      });

      if (tableNode && tableCellNode && tableRowNode) {
        const [currentTable, currentTablePath] = tableNode;
        const [currentCell, currentCellPath] = tableCellNode;
        const [currentRow, currentRowPath] = tableRowNode;
        const startPath = currentCell;
        Transforms.setNodes(
          this.editor,
          { cellBgColor: "#FFFFFF" },
          {
            at: startPath,
          }
        );

        if (currentTable && currentCell && currentRow) {
          const currentTableProps = { ...currentTable };
          delete currentTableProps.children;
          delete currentTableProps.type;

          const currentCellProps = { ...currentCell };
          delete currentCellProps.children;
          delete currentCellProps.type;

          const currentRowProps = { ...currentRow };
          delete currentRowProps.children;
          delete currentRowProps.type;

          return {
            styleProps: {
              ...prefixKey({ ...currentTableProps }, "table."),
              ...prefixKey({ ...currentRowProps }, "row."),
              ...prefixKey({ ...currentCellProps }, "col."),
            },
            currentCellPath,
            currentRowPath,
            currentTablePath,
          };
        }

        return null;
      }
      return null;
    }
  };

  isCellSelected = (selection) => {
    try {
      if (!selection) {
        return false;
      }

      const [tableNode] = Editor.nodes(this.editor, {
        match: (n) =>
          !Editor.isEditor(n) && Element.isElement(n) && n.type === "table",
      });

      // const [, tableCellPath] = Editor.nodes(this.editor, {
      //   at: selection,
      //   match: (n) =>
      //     !Editor.isEditor(n) &&
      //     Element.isElement(n) &&
      //     n.type === "table-cell",
      // });
      const { anchor, focus } = this.editor.selection || {};
      if (tableNode && tableNode[0] && focus?.path) {
        let startCell = anchor?.path;
        let endCell = focus?.path;

        // swap for reverse selection
        if (startCell[1] > endCell[1]) {
          startCell = focus?.path;
          endCell = anchor?.path;
        } else if (startCell[2] > endCell[2]) {
          startCell = focus?.path;
          endCell = anchor?.path;
        }

        const { columns } = tableNode[0];
        const startPath = startCell[0];
        const startRow = startCell[1];
        let startCol = startCell[2];
        const endRow = endCell[1];
        const endCol = endCell[2];
        const selectedCellsPath = [];
        let uptoCol = startRow !== endRow ? columns - 1 : endCol;
        for (let row = startRow; row <= endRow; row++) {
          for (let col = startCol; col <= uptoCol; col++) {
            let nextPath = [startPath, row, col];
            selectedCellsPath.push(nextPath);
          }
          startCol = 0;
          uptoCol = endCol;
        }
        return selectedCellsPath;
      } else {
        return false;
      }
    } catch (err) {
      console.log(err);
      return false;
    }
  };
}

const createRow = (cellText) => {
  const newRow = Array.from(cellText, (value) => createTableCell(value));
  return {
    type: "table-row",
    children: newRow,
  };
};

export const createTableCell = (text) => {
  return {
    type: "table-cell",
    children: [
      {
        type: "paragraph",
        children: [{ text }],
      },
    ],
  };
};

// const createHead = (cellText) => {
//   const newHead = Array.from(cellText, (value) => createTableHeadCell(value));
//   return {
//     type: "table-head",
//     children: [
//       {
//         type: "table-row",
//         children: newHead,
//       },
//     ],
//   };
// };

// export const createTableHeadCell = (text) => {
//   return {
//     type: "table-cell",
//     children: [
//       {
//         type: "paragraph",
//         children: [{ text }],
//       },
//     ],
//   };
// };

// const createTbody = (children) => {
//   return [
//     {
//       type: "table-body",
//       children: children,
//     },
//   ];
// };

const createTableNode = (cellText, rows, columns) => {
  // const tableHead = Array.from([cellText[0]], (value) => createHead(value));
  const tableChildren = Array.from(cellText, (value) => createRow(value));
  // const tbodyChildren = createTbody(tableChildren);
  let tableNode = {
    type: "table",
    children: tableChildren,
    rows,
    columns,
  };
  return tableNode;
};
