import Icon from "../Icon";

const textOptions = [
    {
        label: "Default Fields",
        value: "listSubHeader",
    },
    {
        label: "Text(Single Line)",
        value: "text",
        icon: <Icon icon="text" />,
    },
    {
        label: "Text(Multiple Line)",
        value: "textArea",
        icon: <Icon icon="textArea" />,
    },
    {
        label: "Contact Number",
        value: "numbers",
        icon: <Icon icon="phone" />,
    },
    {
        label: "Email",
        value: "email",
        icon: <Icon icon="mail" />,
    },
    // {
    //     label: "Radio Button",
    //     value: "radioButton",
    //     icon: <Icon icon="radioButton" />,
    // },
    // {
    //     label: "Checkbox",
    //     value: "checkbox",
    //     icon: <Icon icon="checkbox" />,
    // },
]

export default textOptions;