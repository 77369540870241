const useSwitchViewport = () => ({
  root: {
    position: "absolute",
    right: 4,
    top: 4,
    margin: "auto",
    padding: "4px",
    zIndex: 100,
    backgroundColor: "#FFF",
    borderRadius: "12px",
    display: "flex",
    justifyContent: "center",
    "& .MuiIconButton-root": {
      padding: "4px",
      "&.active": {
        color: "#2563EB",
      },
      "& svg": {
        height: "20px",
      },
    },
  },
});

export default useSwitchViewport;
