import "./style.css";

function WaveLoading() {
  return (
    <div className="bouncing-dots">
      <div className="dot"></div>
      <div className="dot"></div>
      <div className="dot"></div>
      <div className="dot"></div>
    </div>
  );
}

export default WaveLoading;
