import React from "react";
import StyleBuilder from "../../common/StyleBuilder";
import accordionTitleBtnStyle from "../../common/StyleBuilder/accordionTitleBtnStyle";
import accordionTitleStyle from "../../common/StyleBuilder/accordionTitleStyle";

const AccordionBtnPopup = (props) => {
  const { element, onSave, onClose, customProps } = props;
  return (
    <StyleBuilder
      title="Accordion Settings"
      type="accordionTitleBtnStyle"
      element={element}
      onSave={onSave}
      onClose={onClose}
      renderTabs={[...accordionTitleBtnStyle, ...accordionTitleStyle]}
      customProps={customProps}
    />
  );
};

export default AccordionBtnPopup;
