import React, { forwardRef } from "react";

const DarkFrame = forwardRef((props, ref) => {
  const { href, id, imagePos, handleClick, imageRef, defaultScale, readOnly } = props;
  const { calX, calY, scale } = imagePos || {
    calX: 0,
    calY: 0,
    scale: defaultScale,
  };

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="314"
      height="541"
      viewBox="0 0 314 541"
      fill="none"
      style={{ userSelect: "none" }}
      onClick={handleClick}
    >
      <g filter="url(#filter0_d_15055_295724)" ref={ref}>
        <rect
          x="7"
          y="7"
          width="290"
          height="517"
          rx="20"
          stroke="white"
          strokeWidth="14"
          shapeRendering="crispEdges"
          fill={`url(#imageSource_${id})`}
          ref={imageRef}
          style={{ cursor: readOnly ? "pointer" : "move" }}
        />
      </g>
      <defs>
        <pattern
          id={`imageSource_${id}`}
          patternUnits="userSpaceOnUse"
          width="290"
          height="517"
        >
          <image
            x={calX}
            y={calY}
            height={`${scale || defaultScale}%`}
            href={href}
          />
        </pattern>
        <filter
          id="filter0_d_15055_295724"
          x="0"
          y="0"
          width="314"
          height="541"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="10" dy="10" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_15055_295724"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_15055_295724"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
});

DarkFrame.displayName = "DarkFrame";

export default DarkFrame;
