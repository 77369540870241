import { Transforms } from "slate";
import insertNewLine from "./insertNewLine";

const createAttachmentsNode = ({ type, url }) => {
  return {
    type: type || "docs",
    url,
    date: new Date().getTime(),
    width: "100%",
    height: "300px",
    children: [{ text: "" }],
  };
};

export const insertAttachments = (editor, data) => {
  try {
    const { url } = data;
    const docType = url?.split(".").pop()
    if (url) {
      const attachmentsNode = createAttachmentsNode({
        ...data,
        type: docType?.includes("pdf") ? "pdf" : (docType?.includes("doc") || docType?.includes("page")) ? "docs" : (docType?.includes("xls") || docType?.includes("numbers")) ? "xls" : docType?.includes("csv") ? "csv" : "docs" ,
      });
      Transforms.insertNodes(editor, [attachmentsNode]);
      insertNewLine(editor);
    }
  } catch (err) {
    console.log(err);
  }
};
