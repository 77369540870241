import React from "react";
import ToolbarIcon from "../../common/ToolbarIcon";
import Icon from "../../common/Icon";
import { insertFreeGrid } from "../../utils/freegrid";

const FreeGridButton = (props) => {
  const { editor, icoBtnType } = props;
  const onButtonClick = () => {
    insertFreeGrid(editor);
  };
  return (
    <ToolbarIcon
      title="Free Grid"
      onClick={onButtonClick}
      icon={<Icon icon={"freegrid"} />}
      icoBtnType={icoBtnType}
    />
  );
};

export default FreeGridButton;
