function TextToolIcon(props = {}) {
    return (
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M1.55762 4.18323V3.12156C1.55762 2.45073 2.10012 1.91406 2.76512 1.91406H11.2351C11.906 1.91406 12.4426 2.45656 12.4426 3.12156V4.18323" stroke="#64748B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M7 12.0876V2.39844" stroke="#64748B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M4.70166 12.0859H9.29833" stroke="#64748B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>

    )
}

export default TextToolIcon;