const commonOptions = ["drag", "edit", "settings", "saveAsTemplate", "close"];

const textOptions = ["edit", "settings", "link", "saveAsTemplate", "close"];

const buttonOptions = ["settings", "link", "saveAsTemplate", "close"];

const imageOptions = [
  "settings",
  "link",
  "imageFrame",
  "saveAsTemplate",
  "close",
];

const videoOptions = ["settings", "saveAsTemplate", "close"];

const boxOptions = ["settings", "link", "saveAsTemplate", "close"];

const appHeaderOptions = ["settings", "saveAsTemplate", "close"];

const tableOptions = ["drag", "edit", "settings", "saveAsTemplate", "close"];

const embedScriptOptions = ["embedScript", "saveAsTemplate", "close"];

const sectionOptions = [
  "addElement",
  "settings",
  "moveUp",
  "moveDown",
  "saveAsTemplate",
  "more",
];

const formOptions = [
  "drag",
  "edit",
  "settings",
  "addFormField",
  "workFlow",
  "saveAsTemplate",
  "close",
];

const itemOptions = {
  default: commonOptions,
  text: textOptions,
  button: buttonOptions,
  image: imageOptions,
  box: boxOptions,
  appHeader: appHeaderOptions,
  form: formOptions,
  section: sectionOptions,
  table: tableOptions,
  embedScript: embedScriptOptions,
  video: videoOptions,
};

export default itemOptions;
