import React, { useState } from "react";
import ToolbarIcon from "../../common/ToolbarIcon";
import Icon from "../../common/Icon";
import EmbedScriptPopup from "./EmbedScriptPopup";

const EmbedScript = (props) => {
  const { icoBtnType } = props;
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  return (
    <>
      <ToolbarIcon
        title={"Code"}
        onClick={handleClick}
        icon={<Icon icon={"embedScript"} />}
        icoBtnType={icoBtnType}
      />
      <EmbedScriptPopup {...props} open={open} />
    </>
  );
};

export default EmbedScript;
