import { Transforms } from "slate";
import insertNewLine from "./insertNewLine";

export const insertSignature = (editor) => {
  const signature = {
    type: "signature",
    alignment: "center",
    data: {
      name: "",
      email: "",
      date: "", // YYYY-MM-DD
    },
    children: [{ text: " " }],
  };
  Transforms.insertNodes(editor, signature, {
    at: editor.selection.anchor.path,
  });
  insertNewLine(editor);
};
