import React, { useState } from "react";
import { IconButton, Menu, MenuItem, Tooltip } from "@mui/material";
import Icon from "../../../common/Icon";

function MoreOptions(props) {
  const { classes, menus, onMenuClick, selectedFlow } = props;

  //state
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <>
      <Tooltip arrow title="More Options">
        <IconButton sx={classes.moreOption} onClick={handleClick}>
          <Icon icon={"moreHorizantal"} />
        </IconButton>
      </Tooltip>
      <Menu
        id="basic-menu"
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {menus.map((m, i) => (
          <MenuItem
            key={`menu_${i}_${m}`}
            sx={{ color: "#64748B", paddingRight: "100px" }}
            onClick={onMenuClick(m, selectedFlow)}
          >
            {m}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

export default MoreOptions;
