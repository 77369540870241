import React from "react";
import {
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";

const ElementSize = (props) => {
  const { value, data, onChange } = props;
  const { key } = data;
  const widthType = value?.widthType
    ? value?.widthType
    : value?.widthInPercent
    ? "%"
    : "px";
  const wkey = widthType === "%" ? "widthInPercent" : "width";

  const handleChange = (e) => {
    onChange({
      [key]: {
        ...(value || {}),
        [e.target.name]: isNaN(e.target.value) ? "" : parseInt(e.target.value),
      },
    });
  };

  const onSizeType = (cData) => () => {
    onChange({
      [key]: {
        ...(value || {}),
        ...cData,
      },
    });
  };

  return (
    <>
      <Grid item xs={12} style={{ marginBottom: "12px" }}>
        <Typography
          variant="body1"
          color={"primary"}
          sx={{ fontSize: "14px", fontWeight: "500", marginBottom: "5px" }}
        >
          Width
        </Typography>
        <TextField
          fullWidth
          name={wkey}
          size="small"
          value={value ? value[wkey] : "100"}
          placeholder="Width"
          onChange={handleChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  className={widthType === "px" ? "active" : ""}
                  size="small"
                  onClick={onSizeType({
                    widthType: "px",
                    widthInPercent: null,
                    width: isNaN(value?.width) ? 100 : value?.width || 100,
                  })}
                >
                  <Typography variant="body2">PX</Typography>
                </IconButton>
                <IconButton
                  className={widthType === "%" ? "active" : ""}
                  size="small"
                  onClick={onSizeType({
                    widthType: "%",
                    widthInPercent: value?.widthInPercent || 100,
                  })}
                >
                  <Typography variant="body2">%</Typography>
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={12} sx={{ paddingTop: "12px" }}>
        <Typography
          variant="body1"
          color={"primary"}
          sx={{ fontSize: "14px", fontWeight: "500", marginBottom: "5px" }}
        >
          Height
        </Typography>
        <TextField
          fullWidth
          name={"height"}
          size="small"
          value={value?.height}
          placeholder="Height"
          helperText={"Leave it to auto if width is in % "}
          onChange={handleChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  className={value?.height !== "" ? "active" : ""}
                  size="small"
                  onClick={onSizeType({
                    height: value?.height || "",
                  })}
                >
                  <Typography variant="body2">PX</Typography>
                </IconButton>
                <IconButton
                  className={value?.height === "" ? "active" : ""}
                  size="small"
                  onClick={onSizeType({
                    height: "",
                  })}
                >
                  <Typography variant="body2">auto</Typography>
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>
    </>
  );
};

export default ElementSize;
