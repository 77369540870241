import React, { useEffect, useState } from "react";
import { Grid, Tabs, Tab, CircularProgress } from "@mui/material";
import TemplateCard from "./TemplateCard";
import FullViewCard from "./FullViewCard";
import ButtonTemplateCard from "./ButtonTemplatesCard";

const CATEGORIES_SORT_INDEX = {
  Brief: 1,
  Buttons: 2,
  Banners: 3,
  Tables: 4,
  Contract: 5,
  Proposal: 6,
};

const Categories = (props) => {
  const { value, handleChange, data } = props;
  return (
    <Tabs
      value={value || data[0]}
      onChange={handleChange}
      variant="scrollable"
      scrollButtons="auto"
      aria-label="Categories"
      style={{ width: "100%" }}
    >
      {data.map((m) => (
        <Tab value={m} key={m} label={m} />
      ))}
    </Tabs>
  );
};

const ProgressBar = ({ loading }) => {
  return loading ? (
    <Grid
      item
      xs={12}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        margin: 0,
        padding: 0,
        height: "50px",
        overflow: "hidden",
      }}
    >
      <CircularProgress />
    </Grid>
  ) : null;
};

const AddTemplates = (props) => {
  const {
    classes,
    editor,
    fullScreen,
    customProps,
    onClose,
    search,
    onSearch,
  } = props;
  const { services } = customProps;
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState("");
  const [templates, setTemplates] = useState([]);
  const [filteredTemplates, setFilteredTemplates] = useState([]);

  useEffect(() => {
    getTemplatesList();
  }, []);

  const sortCategory = (a, b) => {
    return (
      (CATEGORIES_SORT_INDEX[a] || Infinity) -
      (CATEGORIES_SORT_INDEX[b] || Infinity)
    );
  };

  const getTemplatesList = async () => {
    setLoading(true);
    const result = await services("listTemplates", {});
    const tempList = result?.data?.filter((f) => f.type === "Template");
    const lic = tempList
      ?.reduce((a, b) => {
        if (a.indexOf(b.category) < 0) {
          a.push(b.category);
        }
        return a;
      }, [])
      .sort(sortCategory);
    const ft = tempList?.filter((f) => f.category === lic[0]);
    setTemplates(tempList);
    setCategories(lic);
    setCategory(lic[0]);
    setFilteredTemplates(ft);
    setLoading(false);
  };

  const handleChange = (event, newValue) => {
    onSearch("");
    setCategory(newValue);
    setFilteredTemplates(templates.filter((f) => f.category === newValue));
  };

  const onSelectTemplate = (card) => () => {
    try {
      editor.insertNode(JSON.parse(card.content));
      onClose();
    } catch (err) {
      console.log(err);
    }
  };

  const filterByTitle = (f) => {
    if (!search) {
      return true;
    } else {
      const searchText = search?.toLowerCase()
      return f?.title?.toLowerCase()?.includes(searchText);
    }
  };

  const renderTemplate = (mapData) => {
    switch (mapData?.category) {
      case "Starter":
      case "Proposal":
      case "Page":
      case "Brief":
      case "Contract":
        return (
          <TemplateCard
            key={`template_Card_${mapData.id}_popup`}
            classes={classes}
            onSelectTemplate={onSelectTemplate}
            m={mapData}
            fullScreen={fullScreen}
          />
        );
      case "Buttons":
        return (
          <ButtonTemplateCard
            key={`template_Card_${mapData.id}_popup`}
            classes={classes}
            onSelectTemplate={onSelectTemplate}
            m={mapData}
            fullScreen={fullScreen}
          />
        );
      case "Banners":
      case "Tables":
        return (
          <FullViewCard
            key={`template_Card_${mapData.id}_popup`}
            classes={classes}
            onSelectTemplate={onSelectTemplate}
            m={mapData}
            fullScreen={fullScreen}
            height={"smallCard"}
          />
        );
      case "404 Page":
        return (
          <FullViewCard
            key={`template_Card_${mapData.id}_popup`}
            classes={classes}
            onSelectTemplate={onSelectTemplate}
            m={mapData}
            fullScreen={fullScreen}
            height={"mediumCard"}
          />
        );
      default:
        return (
          <TemplateCard
            key={`template_Card_${mapData.id}_popup`}
            classes={classes}
            onSelectTemplate={onSelectTemplate}
            m={mapData}
            fullScreen={fullScreen}
          />
        );
    }
  };

  return (
    <Grid
      container
      className={`templates ${fullScreen ? "fullscreen" : ""}`}
      sx={classes.textFormatWrapper}
      spacing={0}
    >
      <Grid item xs={12}>
        <Categories
          value={category}
          data={categories}
          handleChange={handleChange}
        />
      </Grid>
      <Grid
        container
        spacing={0}
        className={`${fullScreen ? "fullscreen" : ""}`}
        sx={classes.templateCardsWrpr}
      >
        <ProgressBar loading={loading} />
        {filteredTemplates.filter(filterByTitle).map((m) => {
          return renderTemplate(m);
        })}
      </Grid>
    </Grid>
  );
};

export default AddTemplates;
