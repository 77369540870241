const default_grid = [
  {
    type: "grid",
    grid: "container",
    children: [
      {
        type: "grid-item",
        grid: 6,
        children: [
          {
            type: "alignLeft",
            children: [
              {
                type: "paragraph",
                children: [
                  {
                    text: "Ship Sites with Style.",
                    fontSize: "huge",
                    fontFamily: "PoppinsBold",
                  },
                ],
              },
              {
                type: "paragraph",
                children: [
                  {
                    fontSize: "16px",
                    fontFamily: "PoppinsRegular",
                    text: "",
                  },
                ],
              },
              {
                type: "paragraph",
                children: [
                  {
                    fontSize: "16px",
                    fontFamily: "PoppinsRegular",
                    text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
                  },
                ],
              },
              {
                type: "paragraph",
                children: [
                  {
                    fontSize: "16px",
                    fontFamily: "PoppinsRegular",
                    text: "",
                  },
                ],
              },
              {
                type: "paragraph",
                children: [
                  {
                    fontSize: "16px",
                    fontFamily: "PoppinsRegular",
                    text: "",
                  },
                ],
              },
              {
                type: "paragraph",
                children: [
                  {
                    fontSize: "16px",
                    fontFamily: "PoppinsRegular",
                    text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
                  },
                ],
              },
            ],
          },
        ],
        bgColor: "rgba(255, 255, 255, 0)",
        lockSpacing: true,
        bannerSpacing: { top: "16", left: "16", right: "16", bottom: "16" },
        alignment: { horizantal: "center" },
      },
      {
        type: "grid-item",
        grid: 6,
        children: [
          {
            type: "alignLeft",
            children: [
              { type: "paragraph", children: [{ text: "" }] },
              {
                type: "paragraph",
                children: [
                  {
                    type: "image",
                    url: "https://developers.elementor.com/docs/assets/img/elementor-placeholder-image.png",
                    images: [],
                    children: [{ text: " " }],
                    size: {
                      width: 570.1953125,
                      height: 450,
                      widthInPercent: 89.06583684178412,
                    },
                    alignment: { horizantal: "center" },
                  },
                ],
              },
            ],
          },
        ],
        bgColor: "rgba(255, 255, 255, 0)",
        lockSpacing: true,
        bannerSpacing: { top: "16", left: "16", right: "16", bottom: "16" },
      },
    ],
    alignment: { flexDirection: "row" },
    lockSpacing: true,
    bannerSpacing: { top: "16", left: "16", right: "16", bottom: "16" },
  },
];

export default JSON.stringify(default_grid);
