const chipTextStyle = [
  {
    tab: "Icon",
    value: "icon",
    fields: [
      {
        label: "Font Size",
        key: "textSize",
        type: "text",
        placeholder: "16px or 1em",
      },
      {
        label: "Icon Position",
        key: "iconPosition",
        type: "textOptions",
        options: [
          {
            value: "start",
            label: "Start",
          },
          {
            value: "end",
            label: "End",
          },
        ],
      },
      {
        label: "Button Icons",
        key: "buttonIcon",
        type: "icons",
      },
    ],
  },
  {
    tab: "Banner Spacing",
    value: "bannerSpacing",
    fields: [
      {
        label: "Banner Spacing",
        key: "bannerSpacing",
        type: "bannerSpacing",
      },
    ],
  },
  {
    tab: "Border Radius",
    value: "borderRadius",
    fields: [
      {
        label: "Border Radius",
        key: "borderRadius",
        type: "borderRadius",
      },
    ],
  },
  {
    tab: "Colors",
    value: "colors",
    fields: [
      {
        label: "Text Color",
        key: "textColor",
        type: "color",
        needPreview: true,
      },
      {
        label: "Background Color",
        key: "bgColor",
        type: "color",
      },
      {
        label: "Border Color",
        key: "borderColor",
        type: "color",
      },
    ],
  },
];

export default chipTextStyle;
