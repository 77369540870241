import React from "react";
import { MenuItem, Tooltip, IconButton, Menu } from "@mui/material";
import { addMarkData } from "../../utils/SlateUtilityFunctions.js";
import { fontFamilyMap } from "../../utils/font";
import Icon from "../../common/Icon";

const FontFamilyDropdown = ({ editor, format, options }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Tooltip title={"Font Family"} arrow>
        <IconButton
          aria-label="more"
          id="long-button"
          aria-controls={open ? "long-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          onClick={handleClick}
        >
          <Icon icon={format} />
        </IconButton>
      </Tooltip>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {options.map((item, index) => (
          <MenuItem
            key={index}
            value={item.value}
            style={{ fontFamily: fontFamilyMap[item.value], fontSize: 15 }}
            onClick={() => {
              addMarkData(editor, { format, value: item.value });
              handleClose();
            }}
          >
            {item.text}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default FontFamilyDropdown;
