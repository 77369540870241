import React from "react";
import { useSlateStatic, useSelected, ReactEditor } from "slate-react";
import { Box } from "@mui/material";
import { getPageSettings } from "../../utils/pageSettings";
import { isTextSelected } from "../../utils/helper";
import { useEditorContext } from "../../hooks/useMouseMove";
import SimpleTextStyle from "./style";

const SimpleText = (props) => {
  const { theme, openAI } = useEditorContext() || {};
  const editor = useSlateStatic();
  const { element, attributes, children, customProps, isEmpty } = props;
  const { readOnly, editorPlaceholder } = customProps;
  const { element: pageSt } = getPageSettings(editor) || {};
  const { pageColor } = pageSt?.pageProps || {};
  const classes = SimpleTextStyle({
    pageColor: pageColor || theme?.palette?.editor?.background || "#FFFFFF",
  });
  const selected = useSelected();
  const path = ReactEditor.findPath(editor, element);
  const showPlaceHolder = !readOnly && path.length === 1 && isEmpty;
  const isEmptyEditor =
    !readOnly && isEmpty && editor.children.length === 1 && !selected;
  const opacity = !isTextSelected(editor?.selection);
  const nextType = element?.children[0]?.type;
  return (
    <Box
      {...element.attr}
      {...attributes}
      className={`simple-text ${nextType}`}
      sx={classes.root}
      key={`para_${path.join("|")}`}
    >
      {children}

      {openAI ? null : (
        <span className="placeholder-simple-text" contentEditable={false}>
          {isEmptyEditor ? (
            editorPlaceholder || "Write Something..."
          ) : showPlaceHolder ? (
            opacity && selected ? (
              <span
                className="placeholder-simple-text-default"
                contentEditable={false}
              >
                Type{" "}
                <span
                  style={{
                    backgroundColor: "#F2F6FA",
                    padding: "0px 2px",
                    borderRadius: "2px",
                  }}
                  contentEditable={false}
                >
                  /
                </span>{" "}
                to browse elements
              </span>
            ) : (
              ""
            )
          ) : (
            ""
          )}
        </span>
      )}
    </Box>
  );
};

export default SimpleText;
