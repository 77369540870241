import React from "react";

const DeleteCell = () => {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 4H4V20H8V18.5H5.5V13H18.5V18.5H16V20H20V4ZM5.5 8V5.5H8.5V8H5.5ZM10 8V5.5H14L13.9999 8H10ZM15.4999 8L15.5 5.5H18.5V8H15.4999ZM15.4999 9.5H18.5V11.5H15.4998L15.4999 9.5ZM8.5 11.5H5.5V9.5H8.5V11.5ZM10 11.5V9.5H13.9999L13.9998 11.5H10Z"
        fill="#64748b"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 18.7804L13.2197 20.0001L14.2803 18.9395L13.0606 17.7197L14.2802 16.5001L13.2196 15.4395L12 16.6591L10.7803 15.4395L9.71968 16.5001L10.9393 17.7197L9.71967 18.9394L10.7803 20.0001L12 18.7804Z"
        fill="#64748b"
      />
    </svg>
  );
};

export default DeleteCell;
