import { Editor, Transforms, Path, Range, Element } from "slate";

export const createChipTextNode = (href, showInNewTab, text) => ({
  type: "chip-text",
  href,
  target: showInNewTab ? "_blank" : "_self",
  children: [{ text }],
});

export const insertChipText = (editor, { url, showInNewTab }) => {
  const { selection } = editor;
  const chipText = createChipTextNode(url, showInNewTab, " ");
  if (!!selection) {
    const [parent, parentPath] = Editor.parent(editor, selection.focus.path);

    if (editor.isVoid(parent)) {
      Transforms.insertNodes(
        editor,
        { type: "paragraph", children: [chipText] },
        {
          at: Path.next(parentPath),
          select: true,
        }
      );
    } else if (Range.isCollapsed(selection)) {
      Transforms.insertNodes(editor, chipText, { select: true });
    } else {
      Transforms.wrapNodes(editor, chipText, { split: true });
    }
  } else {
    Transforms.insertNodes(editor, { type: "paragraph", children: [chipText] });
  }
};

export const removeChipText = (editor, path) => {
  Transforms.unwrapNodes(editor, {
    at: path,
    match: (n) =>
      !Editor.isEditor(n) && Element.isElement(n) && n.type === "chip-text",
  });
};
