const useOptionsPopupStyle = ({ theme }) => ({
  root: {
    zIndex: 1200,
    background: theme?.palette?.editor?.background,
    borderRadius: "7px",
    overflow: "hidden",
    boxShadow: "1px 1px 3px rgba(0,0,0,0.3)",
    marginRight: "4px !important",
    "& .papper-root": {
      width: "344px",
      boxShadow: "none",
      border: `1px solid ${theme?.palette?.editor?.borderColor}`,
      borderRadius: "7px",
      background: theme?.palette?.editor?.background,
      "& .MuiTypography-root, .MuiInputBase-root, input": {
        color: theme?.palette?.editor?.textColor,
      },
      "& .MuiInputBase-root, input": {
        border: `1px solid ${theme?.palette?.editor?.borderColor}`,
      },
      "& .borderInput": {
        background: theme?.palette?.editor?.background,
        color: theme?.palette?.editor?.textColor,
      },
      "& .MuiCheckbox-root": {
        "& svg": {
          fill: theme?.palette?.editor?.textColor,
        },
      },
    },
    "& .item-list-wrpr": {
      paddingTop: "0px",
      paddingBottom: "0px",
      padding: "12px 12px",
      "& .MuiListItemButton-root": {
        borderRadius: "7px",
        color: theme?.palette?.editor?.textColor,
        "&:hover": {
          color: theme?.palette?.editor?.activeColor,
          background: "rgba(233, 243, 254, 1)",
          "& .MuiTypography-root": {
            color: theme?.palette?.editor?.activeColor,
          },
        },
      },
    },
    "& .item-wrapper": {
      padding: "12px 12px",
      fontFamily: "sans-serif",
      "& .MuiListItemIcon-root": {
        minWidth: "30px",
      },
      "&.title": {
        display: "flex",
        fontWeight: "bold",
        justifyContent: "space-between",
        alignItems: "center",
        position: "relative",
        color: theme?.palette?.editor?.textColor,
        "&:after": {
          position: "absolute",
          width: "calc(100% - 24px)",
          bottom: 0,
          left: 12,
          content: '" "',
          borderBottom: "1px solid #ccc",
        },
        "& .MuiIconButton-root": {
          padding: "4px",
          color: theme?.palette?.editor?.textColor,
          "& svg": {
            width: "16px",
            height: "16px",
          },
        },
      },
    },
  },
});

export default useOptionsPopupStyle;
