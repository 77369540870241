import { Editor, Path, Range, Transforms } from "slate";

export const variablesTextNode = (data, text) => ({
  type: "variables",
  ...(data || { name: "" }),
  children: [{ text }],
});

export const insertVariables = (editor, data) => {
  try {
    const { selection } = editor;
    const variableText = variablesTextNode(data, " ");

    if (!!selection) {
      const [parent, parentPath] = Editor.parent(editor, selection.focus.path);
      if (editor.isVoid(parent)) {
        Transforms.insertNodes(
          editor,
          { type: "paragraph", children: [variableText] },
          {
            at: Path.next(parentPath),
            select: true,
          }
        );
      } else if (Range.isCollapsed(selection)) {
        Transforms.insertNodes(editor, variableText, { select: true });
      } else {
        Transforms.wrapNodes(editor, variableText, { split: true });
      }
    } else {
      Transforms.insertNodes(editor, { type: "paragraph", children: [variableText] });
    }
  } catch (err) {
    console.log(err);
  }
};