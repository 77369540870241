import React from "react";
import { Transforms, Node } from "slate";
import { useSlateStatic } from "slate-react";
import { DndContext } from "@dnd-kit/core";
import { customCollisionDetectionAlgorithm } from "../../helper";

const DragAndDrop = ({ children }) => {
  const editor = useSlateStatic();

  const handleDragStart = (e) => {
    try {
      // console.log(e);
    } catch (err) {
      console.log(err);
    }
  };

  const handleDragEnd = (e) => {
    try {
      const { active, over } = e;
      const fromPath = active.id
        .split("_")[1]
        .split("|")
        .map((m) => parseInt(m));
      const toPath = over.id
        .split("_")[1]
        .split("|")
        .map((m) => parseInt(m));
      const toCloneNode = JSON.stringify(Node.get(editor, fromPath));
      // set moved node delete
      Transforms.setNodes(editor, { moved: true }, { at: [...fromPath] });
      // clone node in the moved path
      Transforms.insertNodes(editor, JSON.parse(toCloneNode), {
        at: [...toPath],
      });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <DndContext
      collisionDetection={customCollisionDetectionAlgorithm}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
    >
      {children}
    </DndContext>
  );
};

export default DragAndDrop;
