import React from "react";
import { useSlateStatic } from "slate-react";
import RedoIcon from "../../assets/svg/RedoIcon";

const RedoButton = () => {

  const editor = useSlateStatic();

  const onRedo = () => {
    editor?.redo()
  };

  return (
    <RedoIcon onClick={onRedo}/>
  );
};

export default RedoButton;