import { Transforms } from "slate";
import insertNewLine from "./insertNewLine";

export const createTopBannerNode = ({ url, alt }) => ({
  type: "topbanner",
  alt,
  url,
  children: [{ text: " " }],
  size: { widthInPercent: "100%", height: "auto" },
});

export const insertTopBanner = (editor, embedData) => {
  try {
    const { url } = embedData;
    if (!url) return;
    const embed = createTopBannerNode(embedData);
    Transforms.insertNodes(editor, embed, { at: [0] });
    insertNewLine(editor);
  } catch (err) {
    console.log(err);
  }
};

export const removeTopBanner = (editor) => {
  try {
    const isTopBanner = editor?.children[0]?.type === "topbanner";
    if (isTopBanner) {
      Transforms.removeNodes(editor, { at: [0] });
    }
  } catch (err) {
    console.log(err);
  }
};

export const updateTopBanner = (editor, attr = {}) => {
  try {
    const isTopBanner = editor?.children[0]?.type === "topbanner";
    if (isTopBanner) {
      Transforms.setNodes(editor, { ...attr }, { at: [0] });
    }
  } catch (err) {
    console.log(err);
  }
};
