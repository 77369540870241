import { useEffect } from "react";

function useEditorScroll(editorWrapper = { current: null }, callback) {
  useEffect(() => {
    const handleScroll = () => {
      if (editorWrapper.current) {
        callback();
      }
    };

    const currentEditorWrapper = editorWrapper.current;
    if (currentEditorWrapper) {
      currentEditorWrapper.addEventListener("scroll", handleScroll);
    }

    // Cleanup the event listener on component unmount
    return () => {
      if (currentEditorWrapper) {
        currentEditorWrapper.removeEventListener("scroll", handleScroll);
      }
    };
  }, [editorWrapper.current]);
}

export default useEditorScroll;
