import React, { useEffect, useState, memo } from "react";
import { useSlateStatic, ReactEditor } from "slate-react";
import { Node, Transforms, Editor } from "slate";
import AspectRatioIcon from "@mui/icons-material/AspectRatio";
import useResize from "../../utils/customHooks/useResize";
import EmbedPopup from "./EmbedPopup";
import { IconButton, Tooltip, Box, useTheme } from "@mui/material";
import { GridSettingsIcon, LinkIcon } from "../../common/iconslist";
import { useEditorContext } from "../../hooks/useMouseMove";
import {
  getTRBLBreakPoints,
  getBreakPointsValue,
  groupByBreakpoint,
} from "../../helper/theme";
import Icon from "../../common/Icon";
import frames from "./Frames";
import ImageFrame from "./Frames/ImageFrame";
import LinkSettings from "../../common/LinkSettings";
import { handleLinkType } from "../../utils/helper";
import { wrapThemeBreakpoints } from "../FreeGrid/breakpointConstants";

const ToolBar = ({ isEmpty, onSettings, setOpenNav }) => {
  return !isEmpty ? (
    <div
      className="element-toolbar visible-on-hover"
      contentEditable={false}
      style={{
        top: "0px",
        right: "0px",
        left: "auto",
        margin: "0px",
      }}
    >
      <Tooltip title="Image Settings" arrow>
        <IconButton onClick={onSettings}>
          <GridSettingsIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Link Settings" arrow>
        <IconButton onClick={() => setOpenNav(true)}>
          <LinkIcon />
        </IconButton>
      </Tooltip>
    </div>
  ) : null;
};

const ImageContent = (props) => {
  const {
    alt,
    url,
    borderColor,
    borderRadius,
    borderWidth,
    boxShadow,
    frame = null,
    objectFit,
    webAddress,
    readOnly,
    setOpenSettings,
    handleImageClick,
    onTouchEnd,
    path,
    theme,
  } = props;

  return !url && !readOnly ? (
    <Box
      component="button"
      className="element-empty-btn"
      contentEditable={false}
      onClick={() => {
        setOpenSettings(true);
      }}
    >
      <Icon icon="image" />
      Add Image
    </Box>
  ) : (
    <Box
      component={"img"}
      className="emb-img"
      sx={{
        ...wrapThemeBreakpoints(
          getBreakPointsValue(
            borderRadius || {},
            null,
            "overrideBorderRadius",
            true
          ),
          "borderRadius",
          theme
        ),
        objectFit: "cover",
        boxShadow: boxShadow || "none",
        height: objectFit ? "100%" : "auto",
        opacity: frame ? 0 : 1,
        cursor: webAddress ? "pointer" : "",
        border: `1px solid ${borderColor}`,
        borderWidth: `${borderWidth}`?.includes("px")
          ? borderWidth
          : `${borderWidth}px`,
      }}
      alt={alt}
      src={url}
      onClick={handleImageClick}
      onTouchEnd={onTouchEnd} // for mobile
      data-path={path.join(",")}
      draggable={false}
    />
  );
};

const Image = (props) => {
  const { attributes, element, children, customProps } = props;
  const theme = useTheme();
  const {
    url,
    bannerSpacing,
    alignment,
    bgColor,
    width: oldWidth,
    xsHidden,
    objectFit,
    frame = null,
    webAddress,
    isNewTab,
    linkType,
    framePos,
  } = element;
  const { readOnly } = customProps;
  const { vertical, horizantal } =
    typeof alignment === "object"
      ? alignment
      : { horizantal: alignment, vertical: "center" };
  const editor = useSlateStatic();
  const [parentDOM, setParentDOM] = useState(null);
  const [openSetttings, setOpenSettings] = useState(false);
  const path = ReactEditor.findPath(editor, element);
  const [openNav, setOpenNav] = useState(false);
  const isEmpty = !url && !readOnly;

  const getSize = () => {
    if (element?.size === undefined) {
      return { widthInPercent: parseInt(oldWidth) };
    } else {
      return element?.size || {};
    }
  };

  const [size, onMouseDown, resizing, onLoad] = useResize({
    parentDOM,
    size: getSize(),
    onChange: (uSize) => {
      Transforms.setNodes(
        editor,
        {
          size: uSize,
        },
        { at: path }
      );
    },
  });

  const arr = Array.from(Node.elements(editor));
  const ele = arr.find(([elem]) => element === elem);
  const { hoverPath, selectedPath } = useEditorContext();
  const selected =
    hoverPath === path.join(",") || selectedPath === path?.join(",");
  const { onClick, onTouchEnd } = handleLinkType(
    webAddress,
    linkType,
    readOnly,
    isNewTab
  );

  const handleImageClick = () => {
    try {
      Transforms.select(editor, {
        anchor: Editor.start(editor, path),
        focus: Editor.end(editor, path),
      });

      if (onClick) {
        onClick();
      }

      if (onTouchEnd) {
        onTouchEnd();
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (editor && ele && ele[1] !== undefined) {
      const dom = ReactEditor.toDOMNode(editor, element);
      setParentDOM(dom);
      onLoad(getSize());
    }
  }, []);

  const onSettings = () => {
    setOpenSettings(true);
  };

  const onSave = (data) => {
    try {
      const updateData = { ...data };
      delete updateData.children;
      Transforms.setNodes(
        editor,
        {
          ...updateData,
        },
        {
          at: path,
        }
      );
      onClose();
    } catch (err) {
      console.log(err);
    }
  };

  const onClose = () => {
    setOpenSettings(false);
  };

  const onDelete = () => {
    Transforms.removeNodes(editor, { at: path });
  };

  const onPosChange = (data) => {
    onSave(data);
  };

  const getWidth = () => {
    if (resizing) {
      return {
        width: `${size.widthInPercent}%`,
        height: objectFit ? `${size.height}px` : "auto",
      };
    } else {
      return {
        ...groupByBreakpoint(
          {
            width: url
              ? {
                  ...getBreakPointsValue(
                    getSize(),
                    null,
                    "overrideReSize",
                    true
                  ),
                }
              : "100%",
            height:
              objectFit && url
                ? {
                    ...getBreakPointsValue(
                      getSize(),
                      null,
                      "overrideReSizeH",
                      true
                    ),
                  }
                : "auto",
          },
          theme
        ),
      };
    }
  };

  return (
    <Box
      {...attributes}
      component={"div"}
      className="embed has-hover dpath"
      sx={{
        display: {
          lg: "flex",
          xs: xsHidden ? "none" : "flex",
        },
        width: `100%`,
        maxWidth: "100%",
        ...wrapThemeBreakpoints(
          getTRBLBreakPoints(bannerSpacing),
          "padding",
          theme
        ),
        backgroundColor: bgColor,
        justifyContent: horizantal,
        alignContent: vertical,
      }}
      {...element.attr}
      data-path={path.join(",")}
      contentEditable={false}
    >
      {openSetttings ? (
        <EmbedPopup
          element={element}
          onSave={onSave}
          onClose={onClose}
          customProps={customProps}
          format={"image"}
          onDelete={onDelete}
        />
      ) : null}
      <Box
        component={"div"}
        contentEditable={false}
        className="embed-image-wrpr"
        sx={{
          position: "relative",
          ...getWidth(),
        }}
      >
        {!readOnly && (
          <ToolBar
            isEmpty={isEmpty}
            onSettings={onSettings}
            setOpenNav={setOpenNav}
          />
        )}
        <ImageContent
          {...element}
          readOnly={readOnly}
          setOpenSettings={setOpenSettings}
          handleImageClick={handleImageClick}
          onTouchEnd={onTouchEnd}
          path={path}
          theme={theme}
        />
        {url && frames[frame] ? (
          <Box
            component={"div"}
            className="image-frame"
            data-path={path.join(",")}
          >
            <ImageFrame
              frame={frame}
              framePos={framePos}
              href={url}
              id={path.join(",")}
              onChange={onPosChange}
              readOnly={readOnly}
              editor={editor}
              handleImageClick={handleImageClick}
            />
          </Box>
        ) : null}
        {selected && !readOnly && (
          <IconButton
            onPointerDown={onMouseDown}
            style={{
              opacity: 1,
              background: "#FFF",
            }}
            className="resize-br visible-on-hover"
          >
            <AspectRatioIcon />
          </IconButton>
        )}
      </Box>
      <span contentEditable={false}>{children}</span>

      {openNav ? (
        <LinkSettings
          handleClose={() => setOpenNav(false)}
          onSave={({ linkType, navValue, openInNewTab }) => {
            onSave({
              linkType,
              webAddress: navValue,
              isNewTab: openInNewTab,
            });

            setOpenNav(false);
          }}
          navType={linkType}
          navValue={webAddress}
          openInNewTab={isNewTab}
          customProps={customProps}
        />
      ) : null}
    </Box>
  );
};
export default Image;
