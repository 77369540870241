import React from "react";

const SizeHandle = ({ style, className }) => {
  return (
    <div
      className={className}
      style={{
        width: "8px",
        height: "8px",
        background: "#FFF",
        border: "1px solid #2563EB",
        position: "absolute",
        margin: "auto",
        ...style,
      }}
    />
  );
};

export default SizeHandle;
