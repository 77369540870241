import { getEditorTheme } from "../../utils/helper";

const LinkPopupStyles = (themeType) => {
  const themeStyle = getEditorTheme(themeType);

  return {
    addLinkField: {
      "& .MuiOutlinedInput-input": {
        fontSize: "12px",
        fontWeight: 500,
        color:
          themeType === "dark" ? "#FFFFFF !important" : "#000000 !important",
      },
      "& .MuiFormHelperText-root": {
        color:
          themeType === "dark" ? "#FFFFFF !important" : "#000000 !important",
      },
      "& .MuiOutlinedInput-root": {
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.16)",
        color:
          themeType === "dark" ? "#FFFFFF !important" : "#000000 !important",
        borderRadius: "7px",
        "& fieldset": {
          borderColor: "#D8DDE1",
        },
        "&:hover fieldset": {
          borderColor: "#64748B",
        },
        "&.Mui-focused fieldset": {
          borderColor: "#2563EB",
        },
        "& .MuiFormLabel-root": {},
      },
    },
    dialogRoot: {
      "& .MuiPaper-root": {
        background: `${themeStyle?.editor?.background} !important`,
      },

      "& .MuiTypography-root": {
        color: `${themeStyle?.editor?.color} !important`,
      },
    },
  };
};

export default LinkPopupStyles;
