import React from "react";

const AddElementIcon = () => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="1.25"
        y="0.75"
        width="14.5"
        height="14.5"
        rx="7.25"
        strokeWidth="1.5"
      />
      <path
        d="M8.50024 4.66748L8.50024 11.3341"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M5.16687 8.03613H11.8335"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default AddElementIcon;
