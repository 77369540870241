import { useMemo } from "react";
import { isBlockActive, toggleBlock } from "../../../utils/SlateUtilityFunctions";
import CustomSelectTool from "./CustomSelectTool"
import Icon from "../../../common/Icon";
import { insertAccordion } from "../../../utils/accordion";

function Label({ icon, label }) {
    return <span className="menuOptions"><Icon icon={icon} /> {label}</span>
}

const listOptions = [
    {
        id: 15,
        value: "orderedList",
        type: "block",
        title: <Label icon="orderedList" label="Ordered List" />,
        label: <Icon icon="orderedList" />,
        group: "list",
    },
    {
        id: 16,
        value: "unorderedList",
        type: "block",
        title: <Label icon="unorderedList" label="Bulleted List" />,
        label: <Icon icon="unorderedList" />,
        group: "list",
    },
    {
        id: 43,
        value: "check-list-item",
        type: "block",
        title: <Label icon="check-list-item" label="Check List" />,
        label: <Icon icon="check-list-item" />,
        group: "list",
    },
    {
        id: 30,
        value: "accordion",
        type: "accordion",
        group: "list",
        component: "AccordionButton",
        title: <Label icon="accordion" label="Accordion" />,
        label: <Icon icon="accordion" />,
    },
];

function SelectList({ editor, classes }) {
    const selectedList = useMemo(() => {
        return listOptions.find(t => isBlockActive(editor, t.value))
    }, [listOptions, isBlockActive, editor]);

    const onChange = (format, option) => {
        if (option.type === "block") {
            toggleBlock(editor, format);
        } else if (option.type === "accordion") {
            insertAccordion(editor);
        }
    };

    return (
        <CustomSelectTool
            options={listOptions}
            editor={editor}
            onChange={onChange}
            value={selectedList?.value || "orderedList"}
            classes={classes}
        />
    )
}

export default SelectList