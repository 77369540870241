import React, { useState } from "react";
import { Grid, TextField, Typography } from "@mui/material";

const Text = (props) => {
  const { value: pro_value, data, onChange } = props;
  const { key, placeholder, width } = data;
  const [value, setValue] = useState(pro_value);

  const handleChange = (e) => {
    setValue(e.target.value);
    onChange({ [key]: e.target.value });
  };

  return (
    <Grid item xs={width || 12}>
      <Typography
        variant="body1"
        color={"primary"}
        sx={{ fontSize: "14px", fontWeight: "500", marginBottom: "8px" }}
      >
        {data?.label}
      </Typography>
      <TextField
        name={key}
        placeholder={placeholder || ""}
        type="text"
        value={value}
        onChange={handleChange}
        size="small"
        fullWidth
      />
    </Grid>
  );
};

export default Text;
