import React, { useEffect, useRef } from "react";

const useClickOutside = ({ onClickOutside, omitIds = [], omitClass = [], refCount = 1 }) => {
  const refs = useRef([...Array(refCount)].map(() => React.createRef()));

  useEffect(() => {
    const handleClickOutside = (event) => {
      let isClickedInside = false;

      for (let ref of refs.current) {
        if (ref.current && ref.current.contains(event.target)) {
          isClickedInside = true;
          break;
        }
      }

      if (!isClickedInside) {
        if (omitIds.includes(event.target.id)) {
          return;
        }

        if (omitClass.some((c) => event.target.className.includes(c))) {
          return;
        }

        onClickOutside();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClickOutside, omitIds, omitClass]);

  return refs.current;
};

export default useClickOutside;
