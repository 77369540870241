const SettingsIcon = (props) => {
    return (
        <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M8.5 11.125C9.6736 11.125 10.625 10.1736 10.625 9C10.625 7.82639 9.6736 6.875 8.5 6.875C7.32639 6.875 6.375 7.82639 6.375 9C6.375 10.1736 7.32639 11.125 8.5 11.125Z" stroke="#64748B" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M1.4165 9.62343V8.37676C1.4165 7.6401 2.01859 7.03093 2.76234 7.03093C4.04442 7.03093 4.56859 6.12426 3.924 5.01218C3.55567 4.37468 3.77525 3.54593 4.41984 3.1776L5.64525 2.47635C6.20484 2.14343 6.92734 2.34176 7.26025 2.90135L7.33817 3.03593C7.97567 4.14801 9.024 4.14801 9.66859 3.03593L9.7465 2.90135C10.0794 2.34176 10.8019 2.14343 11.3615 2.47635L12.5869 3.1776C13.2315 3.54593 13.4511 4.37468 13.0828 5.01218C12.4382 6.12426 12.9623 7.03093 14.2444 7.03093C14.9811 7.03093 15.5903 7.63301 15.5903 8.37676V9.62343C15.5903 10.3601 14.9882 10.9693 14.2444 10.9693C12.9623 10.9693 12.4382 11.8759 13.0828 12.988C13.4511 13.6326 13.2315 14.4543 12.5869 14.8226L11.3615 15.5238C10.8019 15.8568 10.0794 15.6584 9.7465 15.0988L9.66859 14.9643C9.03109 13.8522 7.98275 13.8522 7.33817 14.9643L7.26025 15.0988C6.92734 15.6584 6.20484 15.8568 5.64525 15.5238L4.41984 14.8226C3.77525 14.4543 3.55567 13.6255 3.924 12.988C4.56859 11.8759 4.04442 10.9693 2.76234 10.9693C2.01859 10.9693 1.4165 10.3601 1.4165 9.62343Z" stroke="#64748B" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default SettingsIcon;
