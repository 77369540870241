import Collaborative from "./Editor/CollaborativeEditor";
import CommonEditor from "./Editor/CommonEditor";
import Mini from "./Editor/MiniEditor";
import EditorInFrame from "./Editor/IframeEditor";
import Chat from "./Editor/ChatEditor";
import Emoji from "./Editor/Elements/Emoji/EmojiPicker";

export const Editor = CommonEditor;
export const MiniEditor = Mini;
export const CollaborativeEditor = Collaborative;
export const IframeEditor = EditorInFrame;
export const ChatEditor = Chat;
export const EmojiPicker = Emoji
