import React, { useState } from "react";
import { ReactEditor, useSlateStatic } from "slate-react";
import {
  Grid,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Box,
  Button,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { removeDrawerMenu } from "../../utils/insertDrawerMenu";

const DrawerMenu = (props) => {
  const { attributes, element } = props;
  const { menus, anchor } = element;
  const [open, setOpen] = useState(false);
  const editor = useSlateStatic();
  const path = ReactEditor.findPath(editor, element);
  const handleClick = () => {
    setOpen(!open);
  };

  const handleDelete = () => {
    removeDrawerMenu(editor, path);
  };

  return (
    <span {...attributes} className="drawer-menu" contentEditable={false}>
      <IconButton onClick={handleClick}>
        <MenuIcon sx={{ fill: '#64748B' }} />
      </IconButton>
      <Drawer anchor={anchor || "left"} open={open} onClose={handleClick}>
        <Box
          sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
        >
          <List>
            {menus.map((m, i) => {
              return (
                <ListItem key={`em-d-${i}-${m.title}`}>
                  <ListItemButton>
                    <ListItemText>{m.text}</ListItemText>
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>
          <Grid style={{ display: "flex", justifyContent: "center" }}>
            <Button color="error" onClick={handleDelete} className="deleteBtn">
              Delete
            </Button>
          </Grid>
        </Box>
      </Drawer>
    </span>
  );
};

export default DrawerMenu;
