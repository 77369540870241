export const formSubmit = async (formData, props) => {
  try {
    const pageApiHost = (props.PAGES_API_HOST || "api/v1")
    const response = await fetch(`${pageApiHost}/form/submit`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${window.siteKey}`,
      },
      body: JSON.stringify(formData),
    });
    const result = await response.json();
    return result?.data;
  } catch (err) {
    console.log(err);
    return err;
  }
};
