const sectionStyle = [
  {
    tab: "General",
    value: "sectionName",
    fields: [
      {
        label: "Section Name",
        key: "sectionName",
        type: "text",
        placeholder: "Welcome",
      },
    ],
  },
  {
    tab: "Colors",
    value: "sectionColors",
    fields: [
      {
        label: "Background Color",
        key: "sectionBgColor",
        type: "color",
      },
    ],
  },
  {
    tab: "Background",
    value: "sectionBackgroundImage",
    fields: [
      {
        label: "Background Image URL",
        key: "sectionBackgroundImage",
        type: "text",
      },
      {
        label: "Background Image",
        key: "sectionBackgroundImage",
        type: "backgroundImage",
      },
    ],
  },
  {
    tab: "Padding",
    value: "sectionBannerSpacing",
    hideOnFGS: true,
    fields: [
      {
        label: "Padding",
        key: "sectionBannerSpacing",
        type: "bannerSpacing",
      },
    ],
  },
  {
    tab: "Border",
    value: "sectionBorderRadius",
    fields: [
      {
        label: "Border",
        key: "sectionBorderRadius",
        type: "borderRadius",
      },
    ],
  },
  {
    tab: "Position",
    value: "position",
    hideOnFGS: true,
    fields: [
      {
        label: "Set Postion (Vertical & Horizantal)",
        key: "sectionAlignment",
        type: "alignment",
      },
    ],
  },
  {
    tab: "Size",
    value: "gridSize",
    hideOnFGS: true,
    fields: [
      {
        label: "Width Size",
        key: "sectionGridSize",
        type: "gridSize",
      },
    ],
  },
];

export default sectionStyle;
