export const validationMethods = {
    isEmail: (value) => {
        const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        const isValidEmail = regex.test(String(value).toLowerCase());
        if (isValidEmail) {
            return "";
        } else {
            return "Enter valid email address";
        }
    },
    isRequired: (value, field_type = "") => {
        return value === '' ? `${field_type ? field_type : "Required"} fields should not be empty` : ""
    }
}

export const validation = (fields) => {
    let validated = [];
    let filedValue = [];
    fields.map((field) => {
        let val = field.rules.map((vm) => {
            return validationMethods[vm](field["value"], field["field_type"])
        })
        return filedValue.push(...val)
    })
    validated.push(...filedValue)
    validated = validated.filter((val) => val !== "")
    return validated.length === 0 ? false : validated
}

