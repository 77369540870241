import { Transforms, Element, Node } from "slate";

const INLINE_ELLEMENTS = ["link", "chip-text", "drawer", "icon-text", "variables"];

const withLinks = (editor) => {
  const { isInline, deleteBackward, normalizeNode } = editor;
  editor.isInline = (element) =>
    INLINE_ELLEMENTS.indexOf(element.type) > -1 ? true : isInline(element);

  // remove empty inline
  editor.normalizeNode = (entry) => {
    const [node, path] = entry;
    if (Element.isElement(node) && node.type === "paragraph") {
      const children = Array.from(Node.children(editor, path));
      for (const [child, childPath] of children) {
        // remove link nodes whose text value is empty string.
        // empty text links happen when you move from link to next line or delete link line.
        if (
          Element.isElement(child) &&
          INLINE_ELLEMENTS.indexOf(child.type) > -1 &&
          child.children[0].text === ""
        ) {
          if (children.length === 1) {
            Transforms.removeNodes(editor, { at: path });
            Transforms.insertNodes(editor, {
              type: "paragraph",
              children: [{ text: "" }],
            });
          } else {
            Transforms.removeNodes(editor, { at: childPath });
          }
          return;
        }
      }
    }
    normalizeNode(entry);
  };

  editor.deleteBackward = (unit) => {
    deleteBackward(unit);
  };

  return editor;
};

export default withLinks;
