import React from "react";
import { IconButton } from "@mui/material";
import SmartButtonIcon from "@mui/icons-material/SmartButton";
import { insertChipText } from "../../utils/insertChipText";

const ChipTextButton = (props) => {
  const { editor } = props;

  const handleClick = () => {
    insertChipText(editor, { url: "", showInNewTab: false });
  };

  return (
    <>
      <IconButton title="Icon" onClick={handleClick}>
        <SmartButtonIcon sx={{ fill: "#64748B" }} />
      </IconButton>
    </>
  );
};

export default ChipTextButton;
