import React from "react";
import StyleBuilder from "../../common/StyleBuilder";
import fieldStyle from "../../common/StyleBuilder/fieldStyle";

const FieldPopup = (props) => {
  const { element, onSave, onClose, customProps } = props;
  return (
    <StyleBuilder
      title="Form Field"
      type="fieldStyle"
      element={element}
      onSave={onSave}
      onClose={onClose}
      renderTabs={fieldStyle}
      customProps={customProps}
    />
  );
};

export default FieldPopup;
