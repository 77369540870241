import React, { useEffect, useRef } from "react";
import { Box } from "@mui/material";
import useVirtualElementStyles from "./styles";
import updateAutoProps from "./updateAutoProps";
import { calculateGridArea } from "../Utils/gridDropItem";

const ROOT_ITEM_CLASS = ".freegrid-item.path-3";

// Function to group items by path and calculate heights
function groupByPathAndCalculateHeight(data) {
  const root = {};
  const heightData = {};

  // Step 1: Group items based on their path
  data.forEach((item) => {
    const segments = item.path.split("|");
    let current = root;

    segments.forEach((segment, index) => {
      if (!current[segment]) {
        current[segment] = { children: {}, props: { height: 0 } };
      }

      if (index === segments.length - 1) {
        // Assign the properties of the item including height
        current[segment] = { ...item, children: current[segment].children };
      }

      current = current[segment].children;
    });
  });

  // Step 2: Recursively calculate the height of each parent based on children
  const calculateHeight = (node) => {
    if (!node.children || Object.keys(node.children).length === 0) {
      // Base case: If there are no children, return the node's height
      return node.props.height;
    }

    // Calculate the height by summing the heights of all children
    let totalHeight = 0;
    Object.values(node.children).forEach((child) => {
      totalHeight += calculateHeight(child);
    });

    // Update the parent's height to be the total height of its children
    node.props.height = totalHeight;
    if (node?.path) {
      heightData[node.path] = totalHeight;
    }
    return totalHeight;
  };

  // Start calculation from the root
  Object.values(root).forEach((node) => calculateHeight(node));

  return { root, heightData };
}

const VirtualElement = (props) => {
  const classes = useVirtualElementStyles();
  const { editor, path, parentEle, updated_at } = props;
  const cloneNode = parentEle?.cloneNode(true);
  const virtualRef = useRef();

  useEffect(() => {
    if (virtualRef?.current) {
      setTimeout(() => {
        const allData = calculateProps(
          path,
          virtualRef?.current,
          ROOT_ITEM_CLASS,
          []
        );
        const groupData = groupByPathAndCalculateHeight(allData);
        // it should trigger by auto alignment or on clicking mobile view change
        updateAutoProps(editor, allData, "xs", groupData);
      }, 100);
    }
  }, [updated_at, virtualRef?.current]);

  const calculateProps = (curPath, dom, domClass, allData) => {
    const rect = dom?.getBoundingClientRect();
    const sectionRect = virtualRef?.current?.getBoundingClientRect();
    const sectionProps = {
      path: curPath,
      props: {
        height: rect.height,
      },
    };
    const itemsData = [];
    const items = dom.querySelectorAll(domClass);
    const nextItemPathLength = curPath?.split("|").length + 2;
    let sectionHeight = 12;
    for (let i = 0; i < items.length; i++) {
      const itemRect = items[i]?.getBoundingClientRect();
      if (items[i]?.classList.contains("type_box")) {
        allData = calculateProps(
          items[i]?.dataset.path,
          items[i],
          `.freegrid-item.path-${nextItemPathLength}`,
          allData
        );
      } else {
        const y = Math.abs(rect.top - itemRect?.top);
        itemsData.push({
          path: items[i]?.dataset.path,
          props: {
            top: y,
            left: 24,
            marginTop: 12,
            width: itemRect?.width,
            height: itemRect?.height,
            gridArea: calculateGridArea(y),
          },
        });
        sectionHeight += itemRect?.height;
      }
    }
    if (dom?.classList.contains("type_box")) {
      const y = Math.abs(sectionRect.top - rect?.top);
      sectionProps.props.gridArea = calculateGridArea(y);
    }
    if (sectionHeight > sectionProps?.props?.height) {
      sectionProps.props.height = sectionHeight;
    }
    allData = [sectionProps, [...allData, ...itemsData]]?.flat();
    return allData;
  };

  const getItems = () => {
    const items = cloneNode?.querySelectorAll(ROOT_ITEM_CLASS) || [];
    let itemsHTML = "";
    for (let i = 0; i < items?.length; i++) {
      items[i].classList.add("exclude-virtual");
      itemsHTML += items[i].outerHTML;
    }
    return itemsHTML;
  };

  return (
    <Box
      className={"mobile-virtual-mode"}
      ref={virtualRef}
      sx={classes.root}
      dangerouslySetInnerHTML={{ __html: getItems() }}
    />
  );
};

export default VirtualElement;
