import React, { forwardRef } from "react";

const RoundedLight = forwardRef((props, ref) => {
  const { href, id, imagePos, handleClick, imageRef, defaultScale, readOnly } = props;
  const { calX, calY, scale } = imagePos || {
    calX: 0,
    calY: 0,
    scale: defaultScale,
  };

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="152"
      height="152"
      viewBox="0 0 152 152"
      fill="none"
      style={{ userSelect: "none" }}
      onClick={handleClick}
      ref={ref}
    >
      <circle
        cx="76"
        cy="76"
        r="75"
        stroke="white"
        strokeWidth="2"
        fill={`url(#imageSource_${id})`}
        ref={imageRef}
        style={{ cursor: readOnly ? "pointer" : "move" }}
      />
      <defs>
        <pattern
          id={`imageSource_${id}`}
          patternUnits="userSpaceOnUse"
          width="152"
          height="152"
        >
          <image
            x={calX}
            y={calY}
            height={`${scale || defaultScale}%`}
            href={href}
          />
        </pattern>
      </defs>
    </svg>
  );
});

RoundedLight.displayName = "RoundedLight";

export default RoundedLight;
