import React from "react";
import StyleBuilder from "../../common/StyleBuilder";
import sectionStyle from "../../common/StyleBuilder/sectionStyle";

const SectionPopup = (props) => {
  const { element, onSave, onClose, onDelete, customProps } = props;
  return (
    <StyleBuilder
      title="Section"
      type="sectionStyle"
      element={element}
      onSave={onSave}
      onClose={onClose}
      renderTabs={sectionStyle}
      customProps={customProps}
      onDelete={onDelete}
    />
  );
};

export default SectionPopup;
