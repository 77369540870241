import { Editor, Transforms, Node, Path } from "slate";
import {
  isSelectionInNodeType,
  getCaretPosition,
  onPasteRnDNode,
  bringItemToFB,
} from "../../helper";
import focusOnNewItem from "../../helper/RnD/focusOnNewItem";
const RND_ITEM_TYPES = ["freegrid", "freegridItem", "freegridBox"];

const parsePath = (path) => path?.split("|").map((m) => parseInt(m));

const selectAll = (event, { editor }) => {
  try {
    const { selected, match } = isSelectionInNodeType(editor, "freegridItem");
    const sel = getCaretPosition(editor);

    // if not selection prevent the events
    if (!sel) {
      event.preventDefault();
      return;
    }

    // only prevent default for freegrid item
    if (selected) {
      event.preventDefault();
      const [item, path] = match;
      switch (item?.childType) {
        case "text":
          const range = Editor.range(editor, path);
          Transforms.select(editor, range);
          break;
        default:
          return null;
      }
    }
  } catch (err) {
    console.log(err);
  }
};

const onCopy = async (event, { editor }) => {
  try {
    const { path, enable } = window?.selectedRnDElement || {};
    // only copy RnD items in Srag enable mode not in edit mode
    if (path && enable === 1) {
      let pPath = parsePath(path);
      let node = Node.get(editor, pPath);
      const isRnD = RND_ITEM_TYPES.indexOf(node?.type) > -1;
      if (isRnD) {
        event.preventDefault();
        if (node?.type === "freegrid") {
          node = Node.get(editor, Path.parent(pPath));
        }
        const jsonFragment = JSON.stringify(node);
        window.copiedNode = jsonFragment;
      }
    }
  } catch (err) {
    console.log(err);
  }
};

const onCut = (event, { editor }) => {
  try {
    const { path, enable } = window?.selectedRnDElement || {};
    onCopy(event, { editor });
    // remove the node
    if (path && enable === 1) {
      const pPath = parsePath(path);
      Transforms.removeNodes(editor, { at: pPath });
      // change / remove the focus to parent
      const currentSelectedNode = Node.get(editor, pPath);
      const sPath =
        currentSelectedNode?.type !== "freegrid" ? Path.parent(pPath) : pPath;
      focusOnNewItem(editor, sPath, {
        setSelectedElement: window.updateSelectedItem,
      });
    }
  } catch (err) {
    console.log(err);
  }
};

const onPaste = async (event, { editor }) => {
  try {
    const { path, enable } = window?.selectedRnDElement || {};
    if (path && enable === 1) {
      event.preventDefault();
      const pPath = parsePath(path);
      const currentSelectedNode = Node.get(editor, pPath);
      let sPath =
        currentSelectedNode?.type !== "freegrid" ? Path.parent(pPath) : pPath;
      const sectionNode = Node.get(editor, sPath);
      const np = onPasteRnDNode(editor, {
        path: sPath,
        children: sectionNode?.children,
        slateNodes: null,
      });
      if (np && window.updateSelectedItem) {
        focusOnNewItem(editor, np, {
          setSelectedElement: window.updateSelectedItem,
        });
      }
    }
  } catch (err) {
    console.log(err);
  }
};

const onBringFront = (event, { editor }) => {
  try {
    const { path, enable } = window?.selectedRnDElement || {};
    if (path && enable === 1) {
      event.preventDefault();
      bringItemToFB(editor, { path: parsePath(path), moveFront: true });
    }
  } catch (err) {
    console.log(err);
  }
};

const onBringBack = (event, { editor }) => {
  try {
    const { path, enable } = window?.selectedRnDElement || {};
    if (path && enable === 1) {
      event.preventDefault();
      bringItemToFB(editor, { path: parsePath(path), moveFront: false });
    }
  } catch (err) {
    console.log(err);
  }
};

export const onInsertFragment = async ({ editor, slateNodes }) => {
  try {
    const { path, enable } = window?.selectedRnDElement || {};

    if (path && enable === 1) {
      const pPath = parsePath(path);
      const currentSelectedNode = Node.get(editor, pPath);
      let sPath =
        currentSelectedNode?.type !== "freegrid" ? Path.parent(pPath) : pPath;
      const sectionNode = Node.get(editor, sPath);
      const np = onPasteRnDNode(editor, {
        path: sPath,
        children: sectionNode?.children,
        slateNodes: slateNodes,
      });
      if (np && window.updateSelectedItem) {
        focusOnNewItem(editor, np, {
          setSelectedElement: window.updateSelectedItem,
        });
      }
    }
  } catch (err) {
    console.log(err);
  }
};

const RnDCtrlCmds = {
  ArrowUp: onBringFront,
  ArrowDown: onBringBack,
  a: selectAll,
  c: onCopy,
  v: onPaste,
  x: onCut,
};

export default RnDCtrlCmds;
