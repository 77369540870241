import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tooltip,
} from "@mui/material";
import { insertEmbedScript, updateEmbedScript } from "../../utils/embedScript";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const EmbedScriptPopup = (props) => {
  const { open, editor, customProps, onClose, updatePath, parentPath } = props;
  const [code, setCode] = useState("");
  const [apiStatus, setApiStatus] = useState({
    loading: false,
    error: false,
  });
  const [allowedDomains, setAllowedDomains] = useState([]);

  const { loading, error } = apiStatus;

  useEffect(() => {
    if (customProps?.services) {
      customProps
        .services("allowedDomains", {})
        .then((data) => {
          setAllowedDomains(data?.data || []);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  const updateApiStatus = (update) => {
    setApiStatus((prev) => ({ ...prev, ...update }));
  };

  const handleChange = (e) => {
    setCode(e.target.value);
  };

  const onSubmit = async () => {
    updateApiStatus({ loading: true });
    const result = await customProps?.services("validateCode", { code });
    const { error, sanitizedCode } = result?.data || {};
    updateApiStatus({ loading: false, error });

    if (!error) {
      if (updatePath) {
        updateEmbedScript(editor, sanitizedCode || "", updatePath, parentPath);
      } else {
        insertEmbedScript(editor, sanitizedCode || "");
      }
    }
    onClose();
  };

  return (
    <>
      <Dialog open={open} fullWidth>
        <DialogTitle>
          <Box
            component={"div"}
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "6px",
            }}
          >
            Embed Code
            {allowedDomains?.length ? (
              <Tooltip
                title={
                  <Box sx={{ textTransform: "none" }}>
                    <span style={{ fontWeight: "bold" }}>Allowed Domains</span>
                    <br />
                    {allowedDomains.join(", ")}
                  </Box>
                }
              >
                {
                  <InfoOutlinedIcon
                    color="gray"
                    fontSize="14px"
                    style={{
                      fill: "#94A3B8",
                      cursor: "pointer",
                    }}
                  />
                }
              </Tooltip>
            ) : null}
          </Box>
        </DialogTitle>
        <DialogContent>
          <textarea
            value={code}
            onChange={handleChange}
            style={{
              minHeight: "200px",
              width: "100%",
              resize: "none",
              padding: "4px",
              boxSizing: "border-box",
            }}
          />

          <Box component="div" color={"red"}>
            {error ? "There was some error on this code." : ""}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onSubmit} disabled={loading}>
            {loading ? "Validating" : "Save"}
          </Button>
          <Button onClick={onClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EmbedScriptPopup;
