import React from "react";

const DeleteCell = () => {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 16V20L4 20V4H20V8H18.5V5.5H15V18.5L18.5 18.5V16H20ZM5.5 16.5V18.5H8.5V16.5L5.5 16.5ZM10 16.5V18.5L13.5 18.5V16.5L10 16.5ZM13.5 8.01957V5.5H10V8.01958L13.5 8.01957ZM8.5 8.01958V5.5H5.5V8.01959L8.5 8.01958ZM8.5 9.51958L5.5 9.51959V11.5L8.5 11.5V9.51958ZM8.5 13L5.5 13V15L8.5 15V13ZM10 13V15L13.5 15V13L10 13ZM10 11.5L13.5 11.5V9.51957L10 9.51958V11.5Z"
        fill="#64748b"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.7176 13.0606L19.9373 14.2803L20.9979 13.2197L19.7782 12L20.9979 10.7803L19.9372 9.71967L18.7176 10.9393L17.4979 9.71968L16.4373 10.7803L17.6569 12L16.4373 13.2196L17.4979 14.2803L18.7176 13.0606Z"
        fill="#64748b"
      />
    </svg>
  );
};

export default DeleteCell;
