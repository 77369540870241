import React from "react";
import { TextField } from "@mui/material";
import { addMarkData, activeMark } from "../../utils/SlateUtilityFunctions.js";
import { sizeMap } from "../../utils/font.js";

const Text = ({ editor, format, options, style = {} }) => {
  const value = activeMark(editor, format);

  const changeMarkData = (event, format) => {
    event.preventDefault();
    const value = event.target.value;
    addMarkData(editor, { format, value });
  };

  return (
    <TextField
      size="small"
      style={style}
      value={sizeMap[value]}
      className="editor-text"
      onChange={(e) => changeMarkData(e, format)}
    />
  );
};

export default Text;
