import React from "react";
import { Box, useTheme } from "@mui/material";
import useGuideLineStyle from "./styles";

const SVGDashed = () => {
  return (
    <svg
      width="1"
      height="100%"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        strokeDasharray="5, 5"
        x1="0"
        y1="100%"
        x2="1"
        y2="1"
        style={{
          stroke: "rgb(0, 0, 0)",
          strokeWidth: 2,
        }}
      ></line>
    </svg>
  );
};

const BoundaryLine = () => {
  const theme = useTheme();
  const classes = useGuideLineStyle({ theme });
  return (
    <Box component={"div"} sx={classes.boundaryLine}>
      <Box
        component={"div"}
        className="rnd-guideline-lv"
        contentEditable={false}
      >
        <SVGDashed />
      </Box>
      <Box
        component={"div"}
        className="rnd-guideline-rv"
        contentEditable={false}
      >
        <SVGDashed />
      </Box>
      <Box component={"div"} className="white-wrapper w_left" />
      <Box component={"div"} className="white-wrapper w_right" />
    </Box>
  );
};

export default BoundaryLine;
