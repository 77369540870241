import React from "react";
import { useSlateStatic } from "slate-react";
import UndoIcon from "../../assets/svg/UndoIcon";

const UndoButton = () => {

  const editor = useSlateStatic();

  const onUndo = () => {
    editor?.undo()
  };

  return (
    <UndoIcon onClick={onUndo}/>
  );
};

export default UndoButton;
