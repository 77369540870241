const useElementOptionsStyle = ({ theme }) => ({
  root: {
    zIndex: 1200,
    "& .papper-root": {
      background: "transparent",
      boxShadow: "none",
    },
    "& .MuiButton-root": {
      padding: "4px",
      fontSize: "10px",
      textTransform: "none",
      marginRight: "6px",
      background: theme?.palette?.editor?.background,
      boxShadow: "1px 1px 3px rgba(0,0,0,0.3)",
      "&.active,&:hover ": {
        color: theme?.palette?.editor?.textColor,
        background: "#1976d2",
      },
    },
    "& .MuiIconButton-root": {
      padding: "4px",
      color: theme?.palette?.editor?.textColor,
      background: theme?.palette?.editor?.background,
      borderRadius: "50%",
      marginRight: "6px",
      boxShadow: "1px 1px 3px rgba(0,0,0,0.3)",
      border: "1px solid transparent",
      "& .strokePath": {
        stroke: theme?.palette?.editor?.textColor,
      },
      "& svg": {
        width: "16px",
        height: "16px",
      },
      "&.active,&:hover ": {
        color: "#2563EB",
        background: theme?.palette?.editor?.background,
        border: "1px solid #2563EB",
        boxShadow: "1px 1px 3px #2563EB",
        "& .strokePath": {
          stroke: "#2563EB",
        },
      },
    },
  },
});

export default useElementOptionsStyle;
