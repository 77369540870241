import { useEffect, useState } from "react";
import { Editor, Range } from "slate";

const SHORTHAND_CMDS = ["@", "/"];

const TYPES = {
  "@": "mentions",
  "/": "elements",
};

const getStartEnd = ({ selection, editor }) => {
  try {
    // Get start and end, modify it as we move along.
    let [start, end] = Range.edges(selection);

    // Move backwards to find the start of the word
    while (true) {
      const before = Editor.before(editor, start, {
        unit: "character",
      });
      const wordBefore =
        before && Editor.string(editor, { anchor: before, focus: start });

      if (before) {
        if (wordBefore.trim() === "") {
          break;
        } else if (SHORTHAND_CMDS.indexOf(wordBefore) < 0) {
          start = before;
        } else {
          start = before;
          break;
        }
      } else {
        break;
      }
    }

    const wordRange = { anchor: start, focus: end };
    const word = Editor.string(editor, wordRange);
    const firstChar = word[0];

    // Handle the commands
    if (firstChar === '@') {
      // Only trigger @ if preceded by a space
      const isPrecededBySpace = Editor.string(editor, {
        anchor: { path: start.path, offset: start.offset - 1 },
        focus: start,
      }).trim() === "";
      if (isPrecededBySpace) {
        return {
          word,
          search: word.substring(1),
          target: wordRange,
          type: TYPES[firstChar],
        };
      }
    } else if (firstChar === '/') {
      return {
        word,
        search: word.substring(1),
        target: wordRange,
        type: TYPES[firstChar],
      };
    }
    return { word: "", wordRange: null, type: null };
  } catch (err) {
    return { word: "", wordRange: null, type: null };
  }
};


const useMentions = (props) => {
  const { editor, selection } = props;
  const [mentions, setMentions] = useState({
    target: null,
    index: null,
    search: null,
    type: null,
  });

  useEffect(() => {
    if (selection && Range.isCollapsed(selection)) {
      const { target, search, type } = getStartEnd({ selection, editor });
      if (target && type) {
        setMentions({
          target,
          search,
          index: 0,
          type: type,
        });
      } else {
        setMentions({
          target: null,
          search: null,
          index: null,
          type: null,
        });
      }
    } else {
      setMentions({
        target: null,
        search: null,
        index: null,
        type: null,
      });
    }
  }, [selection, editor]);

  return [mentions, setMentions];
};

export default useMentions;
