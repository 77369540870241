const carousel_item = (itemNo) => ({
  type: "paragraph",
  children: [
    {
      type: "grid",
      grid: "container",
      children: [
        {
          type: "grid-item",
          grid: 12,
          children: [
            {
              type: "alignLeft",
              children: [
                {
                  type: "paragraph",
                  children: [
                    {
                      text: `Slide ${itemNo}`,
                      fontSize: "huge",
                      fontFamily: "PoppinsBold",
                    },
                  ],
                },
                {
                  type: "paragraph",
                  children: [
                    {
                      fontSize: "16px",
                      fontFamily: "PoppinsRegular",
                      text: "",
                    },
                  ],
                },
                {
                  type: "paragraph",
                  children: [
                    {
                      fontSize: "16px",
                      fontFamily: "PoppinsRegular",
                      text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
                    },
                  ],
                },
              ],
            },
          ],
          lockSpacing: true,
          borderRadius: {
            topLeft: 8,
            topRight: 8,
            bottomRight: 8,
            bottomLeft: 8,
          },
          bannerSpacing: {
            top: 16,
            left: 16,
            right: 16,
            bottom: 16,
            undefined: 16,
          },
          alignment: { horizontal: "center" },
        },
      ],
      bgColor: "#ECF4FF",
      alignment: { flexDirection: "row" },
      lockSpacing: true,
      bannerSpacing: {
        top: "16",
        left: "16",
        right: "16",
        bottom: "16",
      },
    },
  ],
});

export default carousel_item;
