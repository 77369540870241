import React from "react";
import { Transforms, Node } from "slate";
import { Box } from "@mui/material";
import { StyleContent } from "../../../StyleBuilder";
import formStyle from "../../../StyleBuilder/formStyle";
import { ReactEditor } from "slate-react";

const FormSettings = (props) => {
  const { editor, path, customProps } = props;
  const item_path = path?.split("|").map((m) => parseInt(m));
  const element_path = [...item_path];
  const element = Node.get(editor, element_path);
  const styleMaps = formStyle?.filter((f) => !f?.hideOnFGS);

  const onChange = (data) => {
    const currentPath = ReactEditor.findPath(editor, element);
    const updateData = { ...data };
    delete updateData.children;
    Transforms.setNodes(
      editor,
      {
        ...updateData,
      },
      {
        at: [...currentPath, 0],
      }
    );
  };

  const handleClose = () => {
    console.log("close");
  };

  return (
    <Box component={"div"} className="item-w">
      {styleMaps?.map((m, i) => {
        return (
          <StyleContent
            key={`tab_${m.value}_$${i}`}
            renderTabs={styleMaps}
            value={m.value}
            element={element}
            customElement={element?.children?.[0] || null}
            onChange={onChange}
            customProps={customProps}
            handleClose={handleClose}
          />
        );
      })}
    </Box>
  );
};

export default FormSettings;
