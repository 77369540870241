import { useMemo } from "react";
import { activeMark, toggleMark } from "../../../utils/SlateUtilityFunctions";
import CustomSelectTool from "./CustomSelectTool";

function Label({ label, type }) {
    return <span><strong>{label}</strong> {type}</span>
}

const superSubscriptOptions = [
    {
        id: 9,
        format: "superscript",
        type: "mark",
        title: <Label label={"X²"} type={"Superscript"}/>,
        label: "X²",
        group: "superSubscript",
        value: "superscript"
    },
    {
        id: 10,
        format: "subscript",
        type: "mark",
        title: <Label label={"X₂"} type={"Subscript"}/>,
        label: "X₂",
        group: "superSubscript",
        value: "subscript"
    },
];

function SelectSuperSubscript({ editor, classes, closeMainPopup }) {

    const selectedMark = useMemo(() => {
        return superSubscriptOptions.find(option => activeMark(editor, option.format));
    }, [editor]);

    const onChange = (format) => {
        toggleMark(editor, format);
        closeMainPopup();
    };

    return (
        <CustomSelectTool
            options={superSubscriptOptions}
            editor={editor}
            onChange={onChange}
            value={selectedMark?.value || "superscript"}
            classes={classes}
        />
    );
}

export default SelectSuperSubscript;
