export function TextAlignLeftIcon(props) {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.875 2.8125H13.125"
        stroke="#9C9C9C"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.76465 6.17578H13.2352"
        stroke="#9C9C9C"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.875 9.0625H13.125"
        stroke="#9C9C9C"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.875 12.1875H7.79375"
        stroke="#9C9C9C"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function MakeShorterIcon(props) {
  return (
    <svg
      width="13"
      height="5"
      viewBox="0 0 13 5"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.10938 1H12.3594"
        stroke="#9C9C9C"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 4.36328H12.4706"
        stroke="#9C9C9C"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function EditIcon(props) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.84006 2.4008L3.36673 8.19413C3.16006 8.41413 2.96006 8.84746 2.92006 9.14746L2.6734 11.3075C2.58673 12.0875 3.14673 12.6208 3.92006 12.4875L6.06673 12.1208C6.36673 12.0675 6.78673 11.8475 6.9934 11.6208L12.4667 5.82746C13.4134 4.82746 13.8401 3.68746 12.3667 2.29413C10.9001 0.914129 9.78673 1.4008 8.84006 2.4008Z"
        stroke="#9C9C9C"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.92676 3.36719C8.21342 5.20719 9.70676 6.61385 11.5601 6.80052"
        stroke="#9C9C9C"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 14.668H14"
        stroke="#9C9C9C"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function BookIcon(props) {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.5834 11.8583V3.30876C15.5834 2.45876 14.8892 1.82835 14.0463 1.89918H14.0038C12.5163 2.02668 10.2567 2.7846 8.99592 3.57793L8.8755 3.65585C8.67008 3.78335 8.33008 3.78335 8.12467 3.65585L7.94758 3.5496C6.68675 2.76335 4.43425 2.01251 2.94675 1.8921C2.10383 1.82126 1.41675 2.45876 1.41675 3.30168V11.8583C1.41675 12.5383 1.96925 13.1758 2.64925 13.2608L2.85466 13.2892C4.39175 13.4946 6.76467 14.2738 8.12467 15.0175L8.153 15.0317C8.34425 15.1379 8.64883 15.1379 8.833 15.0317C10.193 14.2808 12.573 13.4946 14.1172 13.2892L14.3509 13.2608C15.0309 13.1758 15.5834 12.5383 15.5834 11.8583Z"
        stroke="#9C9C9C"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.5 3.89062V14.5156"
        stroke="#9C9C9C"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.4895 6.01562H3.89575"
        stroke="#9C9C9C"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.02075 8.14062H3.89575"
        stroke="#9C9C9C"
        strokeLinecap="round"
        strokeLinejoin="round"
        {...props}
      />
    </svg>
  );
}

export function CloseIcon(props) {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.49992 17.4154C13.8541 17.4154 17.4166 13.8529 17.4166 9.4987C17.4166 5.14453 13.8541 1.58203 9.49992 1.58203C5.14575 1.58203 1.58325 5.14453 1.58325 9.4987C1.58325 13.8529 5.14575 17.4154 9.49992 17.4154Z"
        stroke="#9C9C9C"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.25952 11.7386L11.7404 7.25781"
        stroke="#9C9C9C"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.7404 11.7386L7.25952 7.25781"
        stroke="#9C9C9C"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function SummarizeIcon(props) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.5 3.375H15.75"
        stroke="#9C9C9C"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.5 7.125H15.75"
        stroke="#9C9C9C"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.25 10.875H15.75"
        stroke="#9C9C9C"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.25 14.625H15.75"
        stroke="#9C9C9C"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.125 6.3225V4.1775C7.125 3.3375 6.7875 3 5.94 3H3.8025C2.9625 3 2.625 3.3375 2.625 4.1775V6.315C2.625 7.1625 2.9625 7.5 3.8025 7.5H5.94C6.7875 7.5 7.125 7.1625 7.125 6.3225Z"
        stroke="#9C9C9C"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function ActionItemsIcon(props) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.25 14.625H15.75"
        stroke="#9C9C9C"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.25 9.375H15.75"
        stroke="#9C9C9C"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.25 4.125H15.75"
        stroke="#9C9C9C"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.25 4.125L3 4.875L5.25 2.625"
        stroke="#9C9C9C"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.25 9.375L3 10.125L5.25 7.875"
        stroke="#9C9C9C"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.25 14.625L3 15.375L5.25 13.125"
        stroke="#9C9C9C"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function TranslateIcon(props) {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.7066 13.4443L11.2799 10.5977L9.85327 13.4443"
        stroke="#9C9C9C"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.1133 12.9375H12.46"
        stroke="#9C9C9C"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.28 15.6678C9.41331 15.6678 7.89331 14.1545 7.89331 12.2812C7.89331 10.4145 9.40665 8.89453 11.28 8.89453C13.1466 8.89453 14.6666 10.4078 14.6666 12.2812C14.6666 14.1545 13.1533 15.6678 11.28 15.6678Z"
        stroke="#9C9C9C"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.34659 2.33203H5.95992C7.33992 2.33203 8.00659 2.99871 7.97326 4.34538V6.9587C8.00659 8.3387 7.33992 9.00538 5.95992 8.97205H3.34659C1.99992 8.99871 1.33325 8.33203 1.33325 6.95203V4.3387C1.33325 2.9987 1.99992 2.33203 3.34659 2.33203Z"
        stroke="#9C9C9C"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.00647 4.89844H3.2998"
        stroke="#9C9C9C"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.64648 4V4.6"
        stroke="#9C9C9C"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.32655 4.89062C5.32655 6.05729 4.41321 7.00395 3.29321 7.00395"
        stroke="#9C9C9C"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.00652 7.00537C5.51985 7.00537 5.07986 6.74536 4.77319 6.33203"
        stroke="#9C9C9C"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.33325 11C1.33325 13.58 3.41992 15.6667 5.99992 15.6667L5.29992 14.5"
        stroke="#9C9C9C"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.6667 6.9987C14.6667 4.4187 12.58 2.33203 10 2.33203L10.7 3.4987"
        stroke="#9C9C9C"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function ToneIcon(props) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.5399 2.62658C13.5133 5.18658 10.9399 8.66658 8.7866 10.3933L7.47327 11.4466C7.3066 11.5666 7.13993 11.6733 6.95327 11.7466C6.95327 11.6266 6.9466 11.4933 6.9266 11.3666C6.85327 10.8066 6.59993 10.2866 6.15326 9.83992C5.69993 9.38658 5.1466 9.11992 4.57993 9.04658C4.4466 9.03992 4.31326 9.02658 4.17993 9.03992C4.25326 8.83325 4.3666 8.63992 4.5066 8.47992L5.5466 7.16658C7.2666 5.01325 10.7599 2.42658 13.3133 1.40658C13.7066 1.25992 14.0866 1.36658 14.3266 1.61325C14.5799 1.85992 14.6999 2.23992 14.5399 2.62658Z"
        stroke="#9C9C9C"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.95343 11.7485C6.95343 12.4818 6.67343 13.1818 6.14677 13.7152C5.7401 14.1218 5.18677 14.4018 4.52677 14.4885L2.88677 14.6685C1.99343 14.7685 1.22677 14.0085 1.33343 13.1018L1.51343 11.4618C1.67343 10.0018 2.89343 9.06849 4.18677 9.04182C4.3201 9.03516 4.4601 9.04182 4.58677 9.04849C5.15343 9.12182 5.70677 9.38182 6.1601 9.84182C6.60677 10.2885 6.8601 10.8085 6.93343 11.3685C6.9401 11.4952 6.95343 11.6218 6.95343 11.7485Z"
        stroke="#9C9C9C"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.49342 9.64552C9.49342 7.90552 8.08009 6.49219 6.34009 6.49219"
        stroke="#9C9C9C"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.4133 8.48828L13.9066 8.97495C14.9 9.96828 14.9 10.9483 13.9066 11.9416L11.9333 13.9149C10.9533 14.8949 9.95998 14.8949 8.97998 13.9149"
        stroke="#9C9C9C"
        strokeLinecap="round"
      />
      <path
        d="M2.07338 7.00703C1.09338 6.0137 1.09338 5.0337 2.07338 4.04036L4.04671 2.06703C5.02671 1.08703 6.02005 1.08703 7.00005 2.06703L7.49338 2.56036"
        stroke="#9C9C9C"
        strokeLinecap="round"
      />
      <path
        d="M7.50011 2.56641L5.03345 5.03307"
        stroke="#9C9C9C"
        strokeLinecap="round"
      />
      <path
        d="M13.4133 8.48828L11.4399 10.4549"
        stroke="#9C9C9C"
        strokeLinecap="round"
      />
    </svg>
  );
}

export function ClarifyIcon(props) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.75 12.8243H9.75L6.41249 15.0442C5.91749 15.3742 5.25 15.0218 5.25 14.4218V12.8243C3 12.8243 1.5 11.3243 1.5 9.07426V4.57422C1.5 2.32422 3 0.824219 5.25 0.824219H12.75C15 0.824219 16.5 2.32422 16.5 4.57422V9.07426C16.5 11.3243 15 12.8243 12.75 12.8243Z"
        stroke="#9C9C9C"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.00011 7.51953V7.36206C9.00011 6.85206 9.31513 6.58205 9.63013 6.36455C9.93763 6.15455 10.2451 5.88456 10.2451 5.38956C10.2451 4.69956 9.69011 4.14453 9.00011 4.14453C8.31011 4.14453 7.75513 4.69956 7.75513 5.38956"
        stroke="#9C9C9C"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.99662 9.3125H9.00337"
        stroke="#9C9C9C"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function CheckIcon(props) {
  return (
    <svg
      width="14"
      height="10"
      viewBox="0 0 14 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1 5L4.99529 9L13 1"
        stroke="#9C9C9C"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function TryAgainIcon(props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.13086 18.3086H15.1309C17.8909 18.3086 20.1309 16.0686 20.1309 13.3086C20.1309 10.5486 17.8909 8.30859 15.1309 8.30859H4.13086"
        stroke="#9C9C9C"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.42914 10.8114L3.86914 8.25141L6.42914 5.69141"
        stroke="#9C9C9C"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function DeleteIcon(props) {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.625 4.73568C13.9888 4.47443 11.3367 4.33984 8.6925 4.33984C7.125 4.33984 5.5575 4.41901 3.99 4.57734L2.375 4.73568"
        stroke="#9C9C9C"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.72852 3.93328L6.90268 2.8962C7.02935 2.14411 7.12435 1.58203 8.46227 1.58203H10.5364C11.8743 1.58203 11.9773 2.17578 12.096 2.90411L12.2702 3.93328"
        stroke="#9C9C9C"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.922 7.23438L14.4074 15.2065C14.3203 16.4494 14.2491 17.4152 12.0403 17.4152H6.95784C4.74909 17.4152 4.67784 16.4494 4.59076 15.2065L4.07617 7.23438"
        stroke="#9C9C9C"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.17773 13.0625H10.814"
        stroke="#9C9C9C"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.52148 9.89453H11.4798"
        stroke="#9C9C9C"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export const PauseRecordingIcon = () => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="15"
      cy="15"
      r="14.5"
      fill="white"
      stroke="url(#paint0_linear_29403_449555)"
    />
    <path
      d="M13.6916 21.125H10.8817C10.5953 21.125 10.3447 20.8744 10.3447 20.5881V9.84943C10.3447 9.54517 10.5953 9.3125 10.8817 9.3125H13.6916C13.932 9.3125 14.2285 9.54517 14.2285 9.84943V20.5881C14.2285 20.8744 13.932 21.125 13.6916 21.125Z"
      fill="url(#paint1_linear_29403_449555)"
    />
    <path
      d="M20.1346 21.125H17.3426C17.0383 21.125 16.8057 20.8744 16.8057 20.5881V9.84943C16.8057 9.54517 17.0383 9.3125 17.3426 9.3125H20.1525C20.4568 9.3125 20.6895 9.54517 20.6895 9.84943V20.5881C20.6716 20.8744 20.4389 21.125 20.1346 21.125Z"
      fill="url(#paint2_linear_29403_449555)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_29403_449555"
        x1="15"
        y1="0"
        x2="15"
        y2="30"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8061FD" />
        <stop offset="1" stopColor="#2C64ED" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_29403_449555"
        x1="12.2866"
        y1="9.3125"
        x2="12.2866"
        y2="21.125"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8161FD" />
        <stop offset="1" stopColor="#2A64EC" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_29403_449555"
        x1="18.7476"
        y1="9.3125"
        x2="18.7476"
        y2="21.125"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8161FD" />
        <stop offset="1" stopColor="#2A64EC" />
      </linearGradient>
    </defs>
  </svg>
);

export const CloseGreyCircle = () => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ border: "1.3px solid #2563EB", borderRadius: "50%" }}
  >
    <rect width="30" height="30" rx="15" fill="#DAE4ED" />
    <path
      d="M19.1245 10.875L10.8749 19.1246"
      stroke="#2563EB"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.875 10.875L19.1246 19.1246"
      stroke="#2563EB"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const TickBlueCircle = () => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      width="30"
      height="30"
      rx="15"
      fill="url(#paint0_linear_30220_64249)"
    />
    <path
      d="M9 15L13.0011 19L21 11"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <defs>
      <linearGradient
        id="paint0_linear_30220_64249"
        x1="15"
        y1="0"
        x2="15"
        y2="30"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8360FD" />
        <stop offset="1" stopColor="#2563EB" />
      </linearGradient>
    </defs>
  </svg>
);

export const ChatMicIcon = (props) => (
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13 19.3194C15.9882 19.3194 18.4166 16.8909 18.4166 13.9028V9.38887C18.4166 6.40067 15.9882 3.9722 13 3.9722C10.0118 3.9722 7.58331 6.40067 7.58331 9.38887V13.9028C7.58331 16.8909 10.0118 19.3194 13 19.3194Z"
      stroke="#94A3B8"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.875 12.0972V13.9028C4.875 18.3896 8.51319 22.0278 13 22.0278C17.4868 22.0278 21.125 18.3896 21.125 13.9028V12.0972"
      stroke="#94A3B8"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.3913 8.91914C11.9982 8.33233 13.7496 8.33233 15.3565 8.91914"
      stroke="#94A3B8"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.2218 11.6295C12.3051 11.3316 13.4516 11.3316 14.535 11.6295"
      stroke="#94A3B8"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
