import React from "react";
import { insertSignature } from "../../utils/signature";
import ToolbarIcon from "../../common/ToolbarIcon";
import Icon from "../../common/Icon";

const SignatureButton = (props) => {
  const { editor, icoBtnType } = props;
  const handleInsertSignature = () => {
    insertSignature(editor);
  };
  return (
    <ToolbarIcon
      title="Signature"
      onClick={handleInsertSignature}
      icon={<Icon icon="signature" />}
      icoBtnType={icoBtnType}
    />
  );
};

export default SignatureButton;
