import default_grid from "../Elements/Grid/templates/default_grid";
import { gridItem } from "./gridItem";
import { customInsertNode } from "./helper";

export const insertPlainGrid = (count) => {
  const size = 12 / count;
  const items = Array.from(Array(count).keys()).map(() =>
    gridItem({
      grid: size,
      cellGHeight: "100%",
      lockSpacing: true,
      bannerSpacing: { top: "16", left: "16", right: "16", bottom: "16" },
    })
  );
  return {
    type: "grid",
    grid: "container",
    children: [...items],
    alignment: { flexDirection: "row" },
    lockSpacing: true,
    bannerSpacing: { top: "16", left: "16", right: "16", bottom: "16" },
    gridSize: 8,
  };
};

export const insertGrid = (editor, item, path) => {
  try {
    const grid = !item ? JSON.parse(default_grid) : item;
    const { selection } = editor;
    customInsertNode(editor, grid, {
      at: path || selection.focus.path,
      select: true,
    });
  } catch (err) {
    console.log(err);
  }
};
