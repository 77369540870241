import React from "react";
import { insertDivider } from "../../utils/divider";
import ToolbarIcon from "../../common/ToolbarIcon";
import Icon from "../../common/Icon";

const DividerButton = (props) => {
  const { editor, icoBtnType } = props;
  const handleClick = () => {
    insertDivider(editor);
  };
  return (
    <ToolbarIcon
      title="Divider"
      onClick={handleClick}
      icon={<Icon icon={"divider"} />}
      icoBtnType={icoBtnType}
    />
  );
};

export default DividerButton;
