import { Node } from "slate";
import { ReactEditor } from "slate-react";

function safeToDOMNode(editor, node, np, extProps) {
  try {
    if (Node.has(editor, node)) {
      const domNode = ReactEditor.toDOMNode(editor, node);
      return domNode;
    }
  } catch (error) {
    console.error("Focusing Again");
    focusOnNewItem(editor, np, extProps);
  }
  return null;
}

function focusOnNewItem(editor, newPath, extProps) {
  try {
    const sectionNode = Node.get(editor, newPath);
    requestAnimationFrame(() => {
      const domNode = safeToDOMNode(editor, sectionNode, newPath, extProps);
      if (domNode) {
        const sectionDom = ReactEditor.toDOMNode(editor, sectionNode);
        if (sectionDom) {
          sectionDom.scrollIntoView({ behavior: "smooth", block: "center" });
          extProps?.setSelectedElement({
            enable: 1,
            path: [...newPath].join("|"),
            cursor: "move",
          });
        }
      }
    });
  } catch (err) {
    console.log(err);
  }
}

export default focusOnNewItem;
