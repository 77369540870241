const useDragInfoStyle = () => ({
  root: {
    zIndex: 1200,
    "& .papper-root": {
      padding: "4px 6px",
      background: "#2563EB",
      color: "#FFF",
      fontSize: "12px",
      left: "12px",
      marginLeft: "6px",
      borderRadius: "2px",
    },
  },
});

export default useDragInfoStyle;
