import React from "react";

const Title = (props) => {
  const { attributes, children } = props;
  return (
    <div {...attributes} style={{ fontWeight: "bold", fontSize: "20px" }}>
      {children}
    </div>
  );
};

export default Title;
