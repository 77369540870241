const embedImageStyle = [
  {
    tab: "URL",
    value: "url",
    fields: [
      {
        label: "Image URL",
        key: "url",
        type: "text",
        placeholder: "Add Image URL here",
      },
      {
        label: "File",
        key: "url",
        type: "backgroundImage",
      },
      {
        label: "Image Frame",
        key: "frame",
        type: "textOptions",
        options: [
          {
            value: "",
            label: "No Frame",
          },
          {
            value: "InstaFrame",
            label: "Instagram Light Frame",
          },
          {
            value: "InstaFrameDark",
            label: "Instagram Dark Frame",
          },
          {
            value: "DarkFrame",
            label: "Portrait Dark Frame",
          },
          {
            value: "LiteFrame",
            label: "Portrait Lite Frame",
          },
          {
            value: "RoundedDark",
            label: "Rounded Dark Frame",
          },
          {
            value: "RoundedLight",
            label: "Rounded Light Frame",
          },
          {
            value: "RoundedLightB2",
            label: "Rounded Light B2 Frame",
          },
        ],
      },
      // {
      //   label: "Add web address here",
      //   key: "webAddress",
      //   type: "text",
      //   placeholder: "https://",
      // },
      // {
      //   label: "Open in new tab",
      //   key: "isNewTab",
      //   type: "selectBox",
      //   placeholder: "Open in new tab",
      // },
    ],
  },
  {
    tab: "Banner Spacing",
    value: "bannerSpacing",
    fields: [
      {
        label: "Banner Spacing",
        key: "bannerSpacing",
        type: "bannerSpacing",
      },
    ],
  },
  {
    tab: "Border",
    value: "border",
    fields: [
      {
        label: "Border Color",
        key: "borderColor",
        type: "color",
      },
      {
        label: "Border Radius",
        key: "borderRadius",
        type: "borderRadius",
      },
      {
        label: "Border Width",
        key: "borderWidth",
        type: "text",
        placeholder: "1px",
      },
    ],
  },
  {
    tab: "Position",
    value: "position",
    fields: [
      {
        label: "Disable Aspect Ratio",
        key: "objectFit",
        type: "selectBox",
        placeholder: "Disable Aspect Ratio",
      },
      {
        label: "Hide on Mobile",
        key: "xsHidden",
        type: "selectBox",
        placeholder: "Hide on Mobile",
      },
      {
        label: "Set Postion (Vertical & Horizantal)",
        key: "alignment",
        type: "alignment",
      },
    ],
  },
  {
    tab: "Colors",
    value: "colors",
    fields: [
      {
        label: "Background Color",
        key: "bgColor",
        type: "color",
      },
      {
        label: "Box Shadow",
        key: "boxShadow",
        type: "text",
        placeholder: "Enter Box Shadow Code",
      },
    ],
  },
  {
    tab: "Size",
    value: "elementSize",
    fields: [
      {
        label: "Element Size",
        key: "size",
        type: "elementSize",
      },
    ],
  },
];

export default embedImageStyle;
