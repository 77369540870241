import React from "react";
import { IconButton } from "@mui/material";
import Icon from "../../common/Icon";

export const PrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <IconButton
      className={className}
      style={{ ...style, position: "absolute", left: '5px', zIndex: 3 }}
      onClick={onClick}
      sx={{'&.MuiButtonBase-root': {
        lineHeight: "0px !important"
       }}}
      contentEditable={false}
    >
      <Icon icon="leftArrow" />
    </IconButton>
  );
};

export const NextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <IconButton
      className={className}
      style={{ ...style, position: "absolute", right: '5px', zIndex: 3 }}
      sx={{
        '&.MuiButtonBase-root': {
          lineHeight: "0px !important"
        }
      }}
      onClick={onClick}
      contentEditable={false}
    >
      <Icon icon="rightArrow" />
    </IconButton>
  );
};
