import React from "react";
import { Box } from "@mui/material";
import Check from "@mui/icons-material/Check";
import { getBreakPointsValue } from "../../helper/theme";

const InlineIcon = (props) => {
  const { attributes, children, element } = props;
  const { bgColor, textColor, bannerSpacing, borderRadius, borderColor } =
    element;
  const { left, top, right, bottom } = bannerSpacing || {};

  return (
    <Box
      {...attributes}
      className="editor-icon-text"
      contentEditable={false}
      component={"button"}
      sx={{
        display: "inline",
        paddingLeft: `${left}px`,
        paddingRight: `${right}px`,
        paddingTop: `${top}px`,
        paddingBottom: `${bottom}px`,
        border: borderColor ? `1px solid ${borderColor}` : "none",
        borderRadius: {
          ...getBreakPointsValue(
            borderRadius || {},
            null,
            "overrideBorderRadius",
            true
          ),
        },
        background: bgColor || "#CCC",
        color: textColor,
      }}
    >
      <Check />
      <div contentEditable={true}>{children}</div>
    </Box>
  );
};

export default InlineIcon;
