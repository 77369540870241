import React from "react";
import StyleBuilder from "../../common/StyleBuilder";
import tableStyle from "../../common/StyleBuilder/tableStyle";

const TablePopup = (props) => {
  const { element, onSave, onClose, customProps } = props;
  return (
    <StyleBuilder
      title="Table"
      type="gridStyle"
      element={element}
      onSave={onSave}
      onClose={onClose}
      renderTabs={tableStyle}
      customProps={customProps}
    />
  );
};

export default TablePopup;
