import { Transforms } from "slate";
import insertNewLine from "./insertNewLine";

export const insertDivider = (editor) => {
  const divider = {
    type: "divider",
    children: [{ text: "" }],
  };
  Transforms.insertNodes(editor, divider);
  insertNewLine(editor);
};
