import React from "react";
import { getBorderColor, getTextColor } from "../../helper";
import { Box } from "@mui/material";
import { getBreakPointsValue, getTRBLBreakPoints } from "../../helper/theme";

const AccordionSummary = (props) => {
  const { attributes, children, element } = props;
  const { textColor, bgColor, borderColor, borderRadius, bannerSpacing } =
    element;
  const textStyles = getTextColor(textColor);
  const borderStyle = getBorderColor(borderColor);

  return (
    <Box
      className={`accordion-summary-container`}
      {...attributes}
      style={{
        width: "100%",
        position: "relative",
        background: bgColor,
        ...borderStyle,
      }}
      sx={{
        borderRadius: {
          ...getBreakPointsValue(
            borderRadius || {},
            null,
            "overrideBorderRadius",
            true
          ),
        },
        padding: {
          ...getTRBLBreakPoints(bannerSpacing),
        },

        '& span[data-slate-string="true"]': textStyles,
      }}
      component={"div"}
    >
      {children}
    </Box>
  );
};

export default AccordionSummary;
