import React from "react";

const AccordionDetails = (props) => {
  const { attributes, children } = props;

  return (
    <div
      className={`accordion-details-container`}
      {...attributes}
      style={{
        height: "auto",
      }}
    >
      {children}
    </div>
  );
};

export default AccordionDetails;
