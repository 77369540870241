import React from "react";

const ImageTextWrapper = (props) => {
  const { attributes, children } = props;

  return (
    <div {...attributes} className="image-text-wrapper">
      {children}
    </div>
  );
};

export default ImageTextWrapper;
