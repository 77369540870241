const useDragOverStyle = () => ({
  root: {
    pointerEvents: "none",
    position: "absolute",
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
    outline: "2px solid #116dff",
  },
  popper: {
    pointerEvents: "none",
    zIndex: 1200,
    "& .papper-root": {
      padding: "8px 12px",
      color: "#FFF",
      fontSize: "14px",
      left: "12px",
      background: "#116dff",
    },
  },
});

export default useDragOverStyle;
