import React from "react";
import { useDroppable } from "@dnd-kit/core";
import { Box } from "@mui/material";

const Droppable = (props) => {
  const { id, classes } = props;
  const { isOver, setNodeRef } = useDroppable({
    id: id,
  });

  const dropStyle = {
    backgroundColor: isOver ? "#47bbf5" : undefined,
  };

  return (
    <Box
      ref={setNodeRef}
      component={"div"}
      className={`droppable-para`}
      contentEditable={false}
      style={dropStyle}
      sx={classes.dropAreaOver}
    />
  );
};

export default Droppable;
