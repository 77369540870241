import React from "react";
import { Box, Card, CardMedia, Grid } from "@mui/material";

// const Select = (props) => {
//     const { classes, data, onSelectTemplate } = props;
//     return (
//         <Box
//             className="template-card-action"
//             component={"div"}
//             sx={classes.templateCardBtnGrp}
//             style={{background: "transparent"}}
//         >
//             <Button className="blueBtn" onClick={onSelectTemplate(data)}>
//                 Select
//             </Button>
//         </Box>
//     );
// };

const FullViewCard = (props) => {

    const { classes, m, onSelectTemplate, height = 'smallCard' } = props;

    return(
        <Grid item xs={12} key={`template_${m.id}`}>
            <Card sx={classes.paperOverrides} className="paperOverrides">
                <Box sx={classes.fullViewCardMediaWrpr} onClick = {onSelectTemplate(m)}>
                    <div className="img-wrapper">
                        <CardMedia
                            component="div"
                            image={m?.thumbnail}
                            alt={m?.title}
                            sx={classes.fullViewCardMedia}
                            className={height}
                        />
                    </div>
                    {/* <Select
                        classes={classes}
                        onSelectTemplate={onSelectTemplate}
                        data={m}
                    /> */}
                </Box>
            </Card>
        </Grid>
    )
}

export default FullViewCard

