import React, { useRef, useState } from "react";
import SignatureCanvas from "react-signature-canvas";
import { Grid } from "@mui/material";

const DrawSignature = (props) => {
  let canvasRef = useRef();
  const {
    onDataChange,
    customProps: { brush, services },
  } = props;
  const [uploading, setUploading] = useState(false);

  const onSigned = async () => {
    const strImage = canvasRef.getTrimmedCanvas().toDataURL();
    setUploading(true);
    const result = await services("uploadFile", {
      image: strImage,
    });
    setUploading(false);
    onDataChange({
      signature: result?.imageURL || strImage,
    });
  };

  return (
    <Grid container>
      {uploading ? "Uploading..." : ""}
      <Grid item xs={12} style={{ width: "100%", height: "209px" }}>
        <SignatureCanvas
          canvasProps={{
            className: "signature-canvas",
          }}
          onEnd={onSigned}
          ref={(ref) => (canvasRef = ref)}
          maxWidth={brush.size || 2}
          penColor={brush?.color || "#000"}
        />
      </Grid>
    </Grid>
  );
};

export default DrawSignature;
