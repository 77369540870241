
const UndoIcon = (props) => {
    return(
        <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M4.75 14.5H10.75C12.82 14.5 14.5 12.484 14.5 10C14.5 7.516 12.82 5.5 10.75 5.5H2.5" stroke="#64748B" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M4.5 7.5L2.5 5.5L4.5 3.5" stroke="#64748B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default UndoIcon;