import React from "react";
import { IconButton, Tooltip } from "@mui/material";

const Button = (props) => {
  const { children, format, active, title, ...rest } = props;
  return (
    <Tooltip title={title} arrow>
      <IconButton className={active ? "btnActive" : ""} {...rest}>
        {children}
      </IconButton>
    </Tooltip>
  );
};

export default Button;
