import React, { useState } from "react";
import { ReactEditor } from "slate-react";
import { Transforms } from "slate";
import ColorPickerTool from "react-gcolor-picker";
import { IconButton, Tooltip, Box, Popover } from "@mui/material";
import { addMarkData, activeMark } from "../../utils/SlateUtilityFunctions";
import ColorButtons from "./ColorButtons";
import ColorPickerStyles from "./Styles";
import colorWheel from "./colorWheel.png";
import "./ColorPicker.css";
import { useEditorContext } from "../../hooks/useMouseMove";

const DEFAULT_COLOR = {
  color: "#000000",
  bgcolor: "#FFFFFF",
};

const ColorPicker = (props) => {
  const { id, format, editor, showHex, title, classes, forMiniTool, openColorTool, closeColorTool } = props;

  const { theme } = useEditorContext();
  const [selection, setSelection] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const pickerStyles = ColorPickerStyles(theme);

  const onOpen = (e) => {
    e.preventDefault();
    setAnchorEl(e.currentTarget);
    setSelection(editor.selection);
    selection && ReactEditor.focus(editor);
  };

  const handleFormSubmit = (color) => {
    if (!color) return;
    selection && Transforms.select(editor, selection);
    addMarkData(editor, { format, value: color });
    selection && ReactEditor.focus(editor);
    handleClose();
  };

  const onSelect = (color) => {
    handleFormSubmit(color);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const activeColor = activeMark(editor, format) || DEFAULT_COLOR[format];

  return (
    <>
    {forMiniTool ? null :(
      <Tooltip title={title} arrow>
        <IconButton sx={pickerStyles.colorPickerIcon} onClick={onOpen}>
          <img src={colorWheel} alt="color wheel" />
        </IconButton>
      </Tooltip>
    )}

      {showHex ? <Box sx={classes.hexStyle}>{activeColor}</Box> : null}

        <ColorButtons
          classes={pickerStyles}
          onSelect={onSelect}
          activeColor={activeColor}
          id={id}
          key={id}
          forMiniTool={forMiniTool}
          openColorTool={openColorTool}
          onClose={closeColorTool}
          onColorPickerClick={onOpen}
        />

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "top",
        }}
        sx={classes.colorPickerPopup}
      >
        <ColorPickerTool
          gradient
          value={activeColor}
          onChange={handleFormSubmit}
        />
      </Popover>
    </>
  );
};

ColorPicker.defaultProps = {
  rounded: false,
  classes: {},
};

export default ColorPicker;
