import { Transforms } from "slate";
import insertNewLine from "./insertNewLine";
import scrollToNewSection from "../helper/RnD/scrollToNewSection";

const Default_text_Node = {
  type: "paragraph",
  children: [{ text: "Effortless Business Growth" }],
};

export const insertFreeGridItem = (
  childType,
  childTypeNode,
  extProps = {},
  type = "freegridItem"
) => {
  return {
    type: type || "freegridItem",
    childType: childType || "text",
    children: [childTypeNode || Default_text_Node],
    gridArea: "1 / 1 / 2 / 2",
    height: 300,
    width: 250,
    left: 401,
    top: 0,
    marginTop: 47,
    left_xs: 24,
    top_xs: 24,
    marginTop_xs: 12,
    width_xs: 250,
    height_xs: 300,
    gridArea_xs: "1 / 1 / 2 / 2",
    sectionBgColor: "rgb(240, 108, 131)",
    ...(extProps || {}),
  };
};

export const insertFreeGrid = (editor, path, extProps) => {
  const freegridData = [
    {
      type: "freegrid",
      height: 508,
      gridTemplateRows: "var(--rows)",
      gridTemplateColumns: "var(--cols)",
      children: [
        {
          type: "freegridItem",
          childType: "text",
          children: [
            {
              type: "alignCenter",
              children: [
                {
                  type: "paragraph",
                  children: [
                    {
                      text: "Lorem ipsum sit ",
                      fontSize: {
                        xs: "80px",
                        sm: "80px",
                        md: "80px",
                        lg: "80px",
                      },
                      fontFamily: "PoppinsBold",
                      color: "rgb(132, 141, 151)",
                    },
                  ],
                },
              ],
            },
            {
              type: "alignCenter",
              children: [
                {
                  type: "paragraph",
                  children: [
                    {
                      fontSize: {
                        xs: "80px",
                        sm: "80px",
                        md: "80px",
                        lg: "80px",
                      },
                      fontFamily: "PoppinsBold",
                      text: "amet.",
                      color: "rgb(132, 141, 151)",
                    },
                    {
                      fontSize: {
                        xs: "80px",
                        sm: "80px",
                        md: "80px",
                        lg: "80px",
                      },
                      fontFamily: "PoppinsBold",
                      text: " ",
                    },
                    {
                      fontSize: {
                        xs: "80px",
                        sm: "80px",
                        md: "80px",
                        lg: "80px",
                      },
                      fontFamily: "PoppinsBold",
                      text: "Lorem",
                      color: "rgb(131, 96, 253)",
                    },
                  ],
                },
              ],
            },
          ],
          gridArea: "3 / 1 / 4 / 2",
          height: 226,
          width: 746,
          left: 143,
          marginTop: 24,
          top_xs: 24,
          left_xs: 24,
          marginTop_xs: 12,
          width_xs: 272,
          height_xs: 571.9921875,
          gridArea_xs: "1 / 1 / 2 / 2",
          updated_at: 1729091202016,
          lg_updatedOn: 1729091202016,
          xs_updatedOn: null,
        },
        {
          type: "freegridItem",
          childType: "button",
          children: [
            {
              type: "button",
              children: [{ text: "" }],
              buttonLink: { linkType: "webAddress" },
              iconPosition: "start",
              bgColor: "#2563EB",
              textColor: "#FFF",
              borderRadius: {
                topLeft: 30,
                topRight: 30,
                bottomLeft: 30,
                bottomRight: 30,
              },
              bannerSpacing: {
                left: 12,
                top: 12,
                right: 12,
                bottom: 12,
              },
              textAlign: "center",
              label: "Get Started",
            },
          ],
          gridArea: "8 / 1 / 9 / 2",
          left: 428,
          marginTop: 38,
          top: 0,
          width: 143,
          height: 50,
          lg_updatedOn: 1729091190567,
          updated_at: 1729091202016,
          top_xs: 619.9921875,
          left_xs: 24,
          marginTop_xs: 12,
          width_xs: 272,
          height_xs: 48,
          gridArea_xs: "13 / 1 / 14 / 2",
        },
      ],
      height_xs: 667.9921875,
      updated_at: 1729091202016,
      width: 0,
      sectionBgColor: "rgb(255, 255, 255)",
      xs_updatedOn: null,
      sectionName: "Home",
    },
  ];
  const newPath = path ? path : editor?.selection?.anchor?.path;
  let upNode = [...freegridData];
  if (path) {
    upNode = [
      {
        type: "paragraph",
        children: upNode,
      },
    ];
  }
  Transforms.insertNodes(editor, upNode, {
    at: newPath,
  });
  scrollToNewSection(editor, newPath, extProps);
  insertNewLine(editor);
};
