import React, { forwardRef } from "react";

const DarkFrame = forwardRef((props, ref) => {
  const { href, id, imagePos, handleClick, imageRef, defaultScale, readOnly } = props;
  const { calX, calY, scale } = imagePos || {
    calX: 0,
    calY: 0,
    scale: defaultScale,
  };

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="330"
      height="557"
      viewBox="0 0 330 557"
      fill="none"
      onClick={handleClick}
    >
      <g filter="url(#filter0_d_14731_299242)" ref={ref}>
        <rect
          x="7.5"
          y="7.5"
          width="305"
          height="532"
          rx="27.5"
          stroke="#1E1E1E"
          strokeWidth="15"
          shapeRendering="crispEdges"
          fill={`url(#imageSource_${id})`}
          ref={imageRef}
          style={{ cursor: readOnly ? "pointer" : "move" }}
        />
      </g>
      <defs>
        <pattern
          id={`imageSource_${id}`}
          patternUnits="userSpaceOnUse"
          width="305"
          height="532"
        >
          <image
            x={calX}
            y={calY}
            height={`${scale || defaultScale}%`}
            href={href}
          />
        </pattern>
        <filter
          id="filter0_d_14731_299242"
          x="0"
          y="0"
          width="330"
          height="557"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="10" dy="10" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.117647 0 0 0 0 0.117647 0 0 0 0 0.117647 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_14731_299242"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_14731_299242"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
});

DarkFrame.displayName = "DarkFrame";

export default DarkFrame;
