import React from "react";
import { useSlateStatic } from "slate-react";
import { MenuItem, Select } from "@mui/material";
import { insertVariables } from "../../utils/variables";
import VariableStyles from "./Style";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const VariableButton = (props) => {
    const classes = VariableStyles();
    const editor = useSlateStatic();

    const { options } = props;

    const updateVariable = (e) => {
        insertVariables(editor, { name: e.target.value })
    }

    return (
      <Select
        labelId="variable-selection"
        id="variable-selection-mini"
        displayEmpty
        value={""}
        sx={classes.variableBtn}
        onChange={updateVariable}
        IconComponent={() => <KeyboardArrowDownIcon />}
        MenuProps={{
          sx: classes.variableMenuItem,
          PaperProps: {
            style: {
              maxHeight: 300,
              overflowY: "auto",
            },
            sx: {
              "&::-webkit-scrollbar-track": {
                display: "none",
              },
              "&::-webkit-scrollbar-thumb": {
                borderRadius: "16px",
              },
            },
          },
        }}
      >
        <MenuItem value="">Variables</MenuItem>
        {(options || []).map((item, index) => (
          <MenuItem key={`mini-edit-sele-${index}`} value={item.key}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
    );
};

VariableButton.defaultProps = {
    options: []
}

export default VariableButton;
