import React, { forwardRef } from "react";

const InstaFrame = forwardRef((props, ref) => {
  const { href, id, imagePos, handleClick, imageRef, defaultScale, readOnly } = props;
  const { calX, calY, scale } = imagePos || {
    calX: 0,
    calY: 0,
    scale: defaultScale,
  };

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="366"
      height="489"
      viewBox="0 0 366 489"
      fill="none"
      style={{ userSelect: "none" }}
      onClick={handleClick}
    >
      <rect
        x="2.5"
        y="2.5"
        width="361"
        height="484"
        rx="17.5"
        fill="#12141d"
        stroke="#D5D5D5"
        strokeWidth="5"
        ref={ref}
      />
      <rect
        x="27.5"
        y="28.5"
        width="35"
        height="35"
        rx="17.5"
        fill="#D5D5D5"
        stroke="#D5D5D5"
        strokeWidth="5"
      />
      <rect
        x="80.5"
        y="40.5"
        width="121"
        height="11"
        rx="5.5"
        fill="#D5D5D5"
        stroke="#D5D5D5"
        strokeWidth="5"
      />
      <path
        d="M328.287 453.992L318.575 447.922L308.863 453.992L308.863 427.284C308.863 426.962 308.991 426.654 309.219 426.426C309.447 426.198 309.755 426.07 310.077 426.07L327.073 426.07C327.395 426.07 327.704 426.198 327.932 426.426C328.159 426.654 328.287 426.962 328.287 427.284L328.287 453.992Z"
        stroke="#D5D5D5"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M48.4231 452.778C48.4231 452.778 33.248 444.28 33.248 433.961C33.248 432.137 33.8801 430.369 35.0366 428.959C36.1931 427.548 37.8027 426.581 39.5915 426.224C41.3803 425.866 43.2378 426.139 44.8479 426.996C46.4581 427.854 47.7215 429.242 48.4231 430.926V430.926C49.1247 429.242 50.3881 427.854 51.9982 426.996C53.6084 426.139 55.4659 425.866 57.2547 426.224C59.0435 426.581 60.653 427.548 61.8096 428.959C62.9661 430.369 63.5981 432.137 63.5981 433.961C63.5981 444.28 48.4231 452.778 48.4231 452.778Z"
        stroke="#D5D5D5"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M90.8913 446.86C89.0838 443.81 88.4515 440.206 89.1132 436.723C89.775 433.24 91.6851 430.119 94.4852 427.945C97.2852 425.771 100.783 424.693 104.321 424.915C107.859 425.137 111.194 426.642 113.701 429.149C116.207 431.656 117.713 434.991 117.935 438.529C118.156 442.067 117.079 445.565 114.905 448.365C112.731 451.165 109.609 453.075 106.127 453.737C102.644 454.398 99.0397 453.766 95.9901 451.959V451.959L90.952 453.385C90.7456 453.445 90.5267 453.449 90.3184 453.396C90.11 453.343 89.9198 453.234 89.7678 453.082C89.6157 452.93 89.5073 452.74 89.454 452.532C89.4007 452.323 89.4045 452.104 89.4648 451.898L90.8913 446.86Z"
        stroke="#D5D5D5"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M170.92 425.447L142.634 433.414C142.393 433.479 142.179 433.618 142.019 433.81C141.86 434.002 141.764 434.239 141.744 434.488C141.724 434.737 141.781 434.985 141.908 435.201C142.035 435.416 142.225 435.587 142.452 435.69L155.442 441.836C155.696 441.954 155.901 442.158 156.018 442.412L162.164 455.402C162.268 455.629 162.438 455.819 162.654 455.946C162.869 456.073 163.118 456.13 163.366 456.11C163.615 456.09 163.852 455.994 164.044 455.835C164.236 455.675 164.375 455.461 164.441 455.22L172.408 426.934C172.468 426.727 172.472 426.509 172.418 426.3C172.365 426.092 172.257 425.902 172.105 425.75C171.953 425.597 171.762 425.489 171.554 425.436C171.346 425.383 171.127 425.386 170.92 425.447V425.447Z"
        stroke="#D5D5D5"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M155.836 442.019L162.695 435.16"
        stroke="#D5D5D5"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="27.5"
        y="86.5"
        width="311"
        height="311"
        rx="17.5"
        stroke="#D5D5D5"
        strokeWidth="5"
        fill={`url(#imageSource_${id})`}
        ref={imageRef}
        style={{ cursor: readOnly ? "pointer" : "move" }}
      />
      <defs>
        <pattern
          id={`imageSource_${id}`}
          patternUnits="userSpaceOnUse"
          width="338.5"
          height="397.5"
        >
          <image
            x={calX}
            y={calY}
            height={`${scale || defaultScale}%`}
            href={href}
          />
        </pattern>
      </defs>
    </svg>
  );
});

InstaFrame.displayName = "InstaFrame";

export default InstaFrame;
