import React, { useState } from "react";
import { convertBase64 } from "../../../utils/helper";
import { Grid, Typography } from "@mui/material";
import { CloudUpload } from "../../../common/EditorIcons";

const UploadSignature = (props) => {
  const { customProps, onUploadDone } = props;
  const [base64, setBase64] = useState(null);
  const [uploading, setUploading] = useState(false);

  const onChange = async (e) => {
    const file = e.target.files[0];
    const strImage = await convertBase64(file);
    setBase64(strImage);
    doUpload(strImage);
  };

  const doUpload = async (strImage) => {
    setUploading(true);
    const result = await customProps?.services("uploadFile", {
      image: strImage,
    });
    setUploading(false);
    onUploadDone({
      signature: result?.imageURL || strImage,
    });
  };

  return (
    <div className="signature-tab2">
      {uploading ? "Uploading..." : ""}
      {base64 ? (
        <div className="upload-sign-img-wrapper">
          <img className="upload-sign-img" src={base64} alt="Signature" />
        </div>
      ) : (
        <div className="upload-input-wrapper">
          <input type="file" onChange={onChange} />
          <div className="upload-wrapper-ui">
            <Grid container justifyContent={"center"} alignItems={"center"}>
              <CloudUpload />
              <Typography className="upload-wrapper-ui-text" variant="h6" color={"textSecondary"} sx={{ ml: 1 }}>
                Upload your signature
              </Typography>
            </Grid>
          </div>
        </div>
      )}
    </div>
  );
};

export default UploadSignature;
