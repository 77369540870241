import { Node } from "slate";
import { ReactEditor } from "slate-react";

function scrollToNewSection(editor, newPath, extProps) {
  try {
    const sectionNode = Node.get(editor, newPath);
    requestAnimationFrame(() => {
      const sectionDom = ReactEditor.toDOMNode(editor, sectionNode);
      if (sectionDom) {
        sectionDom.scrollIntoView({ behavior: "smooth", block: "center" });
        extProps?.setSelectedElement({
          enable: 1,
          path: [...newPath, 0].join("|"),
          cursor: "move",
        });
      }
    });
  } catch (err) {
    console.log(err);
  }
}

export default scrollToNewSection;
