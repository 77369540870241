import React from "react";
import { Popover, MenuItem } from "@mui/material";
import CMenus from "./CMenus";
import useContextMenuStyle from "./styles";

const ContextMenu = (props) => {
  const { theme } = props;
  const classes = useContextMenuStyle({ theme });
  const { open, type, contextMenu, handleClose } = props;
  const { path, x, y } = contextMenu;
  const menus = CMenus[type] || [];

  return open && path ? (
    <Popover
      open={contextMenu !== null}
      sx={classes.root}
      onClose={handleClose(null)}
      anchorReference="anchorPosition"
      anchorPosition={contextMenu !== null ? { top: y, left: x } : undefined}
    >
      {menus.map((m, i) => {
        return (
          <MenuItem
            className="cm-menu-item"
            key={`cm_${i}`}
            onClick={handleClose(m)}
          >
            {m?.render ? m.render() : m.label}
          </MenuItem>
        );
      })}
    </Popover>
  ) : null;
};

export default ContextMenu;
