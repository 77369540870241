import React, { useEffect, useRef, useState } from "react";
import { Box, Paper, Popover } from "@mui/material";
import useDragOverStyle from "./styles";

const DragOver = (props) => {
  const ref = useRef();
  const classes = useDragOverStyle();
  const { status, hover_on, path, type } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const isSectionHover = status && type === "parent";
  const isContainerHover = hover_on === path && type === "parent-container";
  console.log(isSectionHover, isContainerHover);

  useEffect(() => {
    if (ref?.current) {
      const getBoundingClientRect = () => ref?.current?.getBoundingClientRect();
      setAnchorEl({
        getBoundingClientRect,
        nodeType: 1,
      });
    }
  }, [ref?.current]);

  const handleClose = () => {};

  return isSectionHover || isContainerHover ? (
    <>
      <Box component={"div"} sx={classes.root} ref={ref} />
      <Popover
        sx={classes.popper}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <Paper className="papper-root">
          {isSectionHover
            ? "Attach to this Section"
            : "Attach to this Container"}
        </Paper>
      </Popover>
    </>
  ) : null;
};

export default DragOver;
