import React from "react";
import {
  Grid,
  Typography,
  Slider,
  FormControlLabel,
  Checkbox,
  Box,
} from "@mui/material";
import { radiusStyle } from "./radiusStyle";
import useWindowResize from "../../../hooks/useWindowResize";
import {
  getBreakPointsValue,
  getCustomizationValue,
} from "../../../helper/theme";

const BORDER_RADIUS_KEYS = ["topLeft", "topRight", "bottomLeft", "bottomRight"];

const BorderRadius = (props) => {
  const { value: val, data, onChange, elementProps, classes } = props;
  let { lockRadius } = elementProps;
  if (lockRadius === undefined) {
    lockRadius = true;
  }
  const { key } = data;
  const [size] = useWindowResize();
  const value = getBreakPointsValue(val, size?.device);

  const handleChange = (e) => {
    let changeAll = {};
    if (lockRadius) {
      BORDER_RADIUS_KEYS.forEach((m) => {
        changeAll[m] = e.target.value;
      });
    } else {
      changeAll = val ? val[size?.device] : {};
    }

    onChange({
      [key]: {
        ...getBreakPointsValue(val, null),
        [size?.device]: {
          ...(changeAll || {}),
          [e.target.name]: e.target.value,
        },
      },
    });
  };

  const onLockRadius = () => {
    onChange({ lockRadius: !lockRadius });
  };

  return (
    <Grid item xs={12}>
      <Typography
        variant="body1"
        color={"primary"}
        style={{ fontSize: "14px", fontWeight: 500 }}
      >
        {data?.label}
      </Typography>
      <Grid container wrap="nowrap" className="sld-wrpr">
        <Slider
          sx={{ opacity: !lockRadius && 0.5 }}
          disabled={!lockRadius}
          className="spacingSlider"
          defaultValue={value?.topLeft || 0}
          value={value?.topLeft || 0}
          aria-label="Default"
          valueLabelDisplay="auto"
          max={100}
          name={"topLeft"}
          onChange={handleChange}
        />
        <Box
          sx={classes.sapcingInput}
          component={"input"}
          value={!lockRadius ? "" : getCustomizationValue(value?.topLeft)}
          className="sliderInput removeScroll"
          name={"topLeft"}
          disabled={!lockRadius}
          onChange={handleChange}
          type="number"
          placeholder="0"
        />
      </Grid>
      <FormControlLabel
        className="ccheckbox-primary"
        control={
          <Checkbox
            value={!lockRadius}
            checked={!lockRadius}
            onChange={onLockRadius}
          />
        }
        label={
          <Typography variant="body1" sx={{ fontSize: "14px" }}>
            Customize
          </Typography>
        }
      />
      {!lockRadius ? (
        <Grid container>
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              flexDirection: "column",
              marginBottom: "48px",
              marginTop: "42px",
            }}
            justifyContent={"center"}
            alignContent={"center"}
            alignItems={"center"}
          >
            <div
              style={{
                width: "100px",
                height: "50px",
                border: "none",
                position: "relative",
                display: "flex",
                borderColor: `#2563eb`,
                borderRadius: `${value?.topLeft}px ${value?.topRight}px ${value?.bottomLeft}px ${value?.bottomRight}px`,
              }}
            >
              <input
                type="number"
                name="topLeft"
                value={getCustomizationValue(value?.topLeft)}
                className="borderInput removeScroll"
                placeholder="0"
                style={{
                  ...radiusStyle.topLeft,
                  borderTopLeftRadius: `${value?.topLeft}px`,
                }}
                onChange={handleChange}
              />
              <input
                type="number"
                name="topRight"
                value={getCustomizationValue(value?.topRight)}
                className="borderInput removeScroll"
                placeholder="0"
                style={{
                  ...radiusStyle.topRight,
                  borderTopRightRadius: `${value?.topRight}px`,
                  textAlign: "center",
                }}
                onChange={handleChange}
              />
              <input
                type="number"
                name="bottomLeft"
                value={getCustomizationValue(value?.bottomLeft)}
                className="borderInput removeScroll"
                placeholder="0"
                style={{
                  ...radiusStyle.bottomLeft,
                  borderBottomLeftRadius: `${value?.bottomLeft}px`,
                  textAlign: "center",
                }}
                onChange={handleChange}
              />
              <input
                type="number"
                name="bottomRight"
                value={getCustomizationValue(value?.bottomRight)}
                className="borderInput removeScroll"
                placeholder="0"
                style={{
                  ...radiusStyle.bottomRight,
                  borderBottomRightRadius: `${value?.bottomRight}px`,
                  textAlign: "center",
                }}
                onChange={handleChange}
              />
              <button
                style={{
                  border: "none",
                  position: "absolute",
                  right: 0,
                  left: 0,
                  top: 0,
                  bottom: 0,
                  background: "none",
                }}
                onClick={onLockRadius}
              ></button>
            </div>
          </Grid>
        </Grid>
      ) : null}
    </Grid>
  );
};

export default BorderRadius;
