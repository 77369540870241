import FormEmail from "./FormEmail";
import FormText from "./FormText";
import FormTextArea from "./FormTextArea";
import FormNumbers from "./FormNumbers";
import FormDate from "./FormDate";
import FormCheckbox from "./FormCheckbox";
import FormRadioButton from "./FormRadioButton";

const FormElements = {
  text: FormText,
  textArea: FormTextArea,
  email: FormEmail,
  numbers: FormNumbers,
  date: FormDate,
  checkbox: FormCheckbox,
  radioButton: FormRadioButton
};

export default FormElements;
