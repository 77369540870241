import React, { forwardRef } from "react";

const RoundedLightB2 = forwardRef((props, ref) => {
  const { href, id, imagePos, handleClick, imageRef, defaultScale, readOnly } = props;
  const { calX, calY, scale } = imagePos || {
    calX: 0,
    calY: 0,
    scale: defaultScale,
  };

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="62"
      height="62"
      viewBox="0 0 62 62"
      fill="none"
      style={{ userSelect: "none" }}
      onClick={handleClick}
      ref={ref}
    >
      <circle
        cx="31"
        cy="31"
        r="28.5"
        stroke="#E0E0E0"
        strokeWidth="5"
        fill={`url(#imageSource_${id})`}
        ref={imageRef}
        style={{ cursor: readOnly ? "pointer" : "move" }}
      />
      <defs>
        <pattern
          id={`imageSource_${id}`}
          patternUnits="userSpaceOnUse"
          width="124"
          height="124"
        >
          <image
            x={calX}
            y={calY}
            height={`${scale || defaultScale}%`}
            href={href}
          />
        </pattern>
      </defs>
    </svg>
  );
});

RoundedLightB2.displayName = "RoundedLightB2";

export default RoundedLightB2;
