import React from "react";
import { useSlateStatic } from "slate-react";
import { insertForm } from "../../utils/form";
import ToolbarIcon from "../../common/ToolbarIcon";
import Icon from "../../common/Icon";

const FormButton = ({ icoBtnType }) => {
  const editor = useSlateStatic();
  const onClick = () => {
    insertForm(editor);
  };

  return (
    <ToolbarIcon
      title="Form"
      onClick={onClick}
      icon={<Icon icon="form" />}
      icoBtnType={icoBtnType}
    />
  );
};

export default FormButton;
