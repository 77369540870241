import React, { useState, forwardRef, useImperativeHandle } from "react";
import { useSlateStatic, ReactEditor } from "slate-react";
import { Paper, Popover } from "@mui/material";
import data from "@emoji-mart/data";
import { insertEmoji } from "../../utils/emoji";
import ToolbarIcon from "../../common/ToolbarIcon";
import Icon from "../../common/Icon";
import EmojiPicker from "./EmojiPicker";
import SwipeableDrawerComponent from "../../common/SwipeableDrawer";

const EmojiButton = forwardRef((props, ref) => {
  const { icoBtnType, selectionTarget, theme = 'light' } = props;
  const editor = useSlateStatic();
  const [anchorEl, setAnchorEl] = useState(null);
  const [target, setTarget] = useState(selectionTarget);
  const open = Boolean(anchorEl);
  const isMobile = window.matchMedia("(max-width: 899px)")?.matches || false;

  useImperativeHandle(ref, () => ({
    triggerClick: (target) => {
      setTarget(target);
      onClick();
    },
  }));

  const onClick = () => {
    try {
      if (editor.selection) {
        const domRange = ReactEditor.toDOMRange(editor, editor.selection);
        const rect = domRange.getBoundingClientRect();
        setAnchorEl({
          getBoundingClientRect: () => rect,
          nodeType: 1,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const onEmojiSelect = (emoji) => {
    insertEmoji(editor, emoji?.native, target || editor.selection);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <ToolbarIcon
        title="Emoji"
        onClick={onClick}
        icon={<Icon icon="emoji" />}
        icoBtnType={icoBtnType}
      />
      {
        isMobile ?
          <SwipeableDrawerComponent
            open={open}
            onClose={handleClose}
            customClass="emojiDrawer"
          >
            <Paper style={{ width: '100%' }} sx={{ '& em-emoji-picker': { width: '100%' } }}>
              <EmojiPicker data={data} onEmojiSelect={onEmojiSelect} theme={theme?.palette?.type} dynamicWidth={true} />
            </Paper>
          </SwipeableDrawerComponent>
          :
          <Popover
            style={{ zIndex: 10001 }}
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            onClose={handleClose}
          >
            <Paper>
              <EmojiPicker data={data} onEmojiSelect={onEmojiSelect} theme={theme?.palette?.type} />
            </Paper>
          </Popover>
      }
    </>
  );
});

EmojiButton.displayName = "EmojiButton";

export default EmojiButton;
