import React from "react";
import StyleBuilder from "../../common/StyleBuilder";
import buttonStyle from "../../common/StyleBuilder/buttonStyle";

const ButtonPopup = (props) => {
  const { element, onSave, customProps, onClose, style, styleName, onDelete } =
    props;
  return (
    <StyleBuilder
      title="Button"
      type={styleName ? "" : "buttonStyle"}
      element={element}
      onSave={onSave}
      onClose={onClose}
      onDelete={onDelete}
      renderTabs={style ? style : buttonStyle}
      customProps={customProps}
    />
  );
};

export default ButtonPopup;
