const MUIFilledIcons = [
  "Abc",
  "AcUnit",
  "AccessAlarm",
  "AccessAlarms",
  "AccessTime",
  "AccessTimeFilled",
  "Accessibility",
  "AccessibilityNew",
  "Accessible",
  "AccessibleForward",
  "AccountBalance",
  "AccountBalanceWallet",
  "AccountBox",
  "AccountCircle",
  "AccountTree",
  "AdUnits",
  "Adb",
  "Add",
  "AddAPhoto",
  "AddAlarm",
  "AddAlert",
  "AddBox",
  "AddBusiness",
  "AddCard",
  "AddCircle",
  "AddCircleOutline",
  "AddComment",
  "AddHome",
  "AddHomeWork",
  "AddIcCall",
  "AddLink",
  "AddLocation",
  "AddLocationAlt",
  "AddModerator",
  "AddPhotoAlternate",
  "AddReaction",
  "AddRoad",
  "AddShoppingCart",
  "AddTask",
  "AddToDrive",
  "AddToHomeScreen",
  "AddToPhotos",
  "AddToQueue",
  "Addchart",
  "AdfScanner",
  "Adjust",
  "AdminPanelSettings",
  "AdsClick",
  "Agriculture",
  "Air",
  "AirlineSeatFlat",
  "AirlineSeatFlatAngled",
  "AirlineSeatIndividualSuite",
  "AirlineSeatLegroomExtra",
  "AirlineSeatLegroomNormal",
  "AirlineSeatLegroomReduced",
  "AirlineSeatReclineExtra",
  "AirlineSeatReclineNormal",
  "AirlineStops",
  "Airlines",
  "AirplaneTicket",
  "AirplanemodeActive",
  "AirplanemodeInactive",
  "Airplay",
  "AirportShuttle",
  "Alarm",
  "AlarmAdd",
  "AlarmOff",
  "AlarmOn",
  "Album",
  "AlignHorizontalCenter",
  "AlignHorizontalLeft",
  "AlignHorizontalRight",
  "AlignVerticalBottom",
  "AlignVerticalCenter",
  "AlignVerticalTop",
  "AllInbox",
  "AllInclusive",
  "AllOut",
  "AltRoute",
  "AlternateEmail",
  "Analytics",
  "Anchor",
  "Android",
  "Animation",
  "Announcement",
  "Aod",
  "Apartment",
  "Api",
  "AppBlocking",
  "AppRegistration",
  "AppSettingsAlt",
  "AppShortcut",
  "Apple",
  "Approval",
  "Apps",
  "AppsOutage",
  "Architecture",
  "Archive",
  "ArrowBack",
  "ArrowBackIos",
  "ArrowBackIosNew",
  "ArrowCircleDown",
  "ArrowCircleLeft",
  "ArrowCircleRight",
  "ArrowCircleUp",
  "ArrowDownward",
  "ArrowDropDown",
  "ArrowDropDownCircle",
  "ArrowDropUp",
  "ArrowForward",
  "ArrowForwardIos",
  "ArrowLeft",
  "ArrowOutward",
  "ArrowRight",
  "ArrowRightAlt",
  "ArrowUpward",
  "ArtTrack",
  "Article",
  "AspectRatio",
  "Assessment",
  "Assignment",
  "AssignmentInd",
  "AssignmentLate",
  "AssignmentReturn",
  "AssignmentReturned",
  "AssignmentTurnedIn",
  "AssistWalker",
  "Assistant",
  "AssistantDirection",
  "AssistantPhoto",
  "AssuredWorkload",
  "Atm",
  "AttachEmail",
  "AttachFile",
  "AttachMoney",
  "Attachment",
  "Attractions",
  "Attribution",
  "AudioFile",
  "Audiotrack",
  "AutoAwesome",
  "AutoAwesomeMosaic",
  "AutoAwesomeMotion",
  "AutoDelete",
  "AutoFixHigh",
  "AutoFixNormal",
  "AutoFixOff",
  "AutoGraph",
  "AutoMode",
  "AutoStories",
  "AutofpsSelect",
  "Autorenew",
  "AvTimer",
  "BabyChangingStation",
  "BackHand",
  "Backpack",
  "Backspace",
  "Backup",
  "BackupTable",
  "Badge",
  "BakeryDining",
  "Balance",
  "Balcony",
  "Ballot",
  "BarChart",
  "BatchPrediction",
  "Bathroom",
  "Bathtub",
  "Battery0Bar",
  "Battery1Bar",
  "Battery20",
  "Battery2Bar",
  "Battery30",
  "Battery3Bar",
  "Battery4Bar",
  "Battery50",
  "Battery5Bar",
  "Battery60",
  "Battery6Bar",
  "Battery80",
  "Battery90",
  "BatteryAlert",
  "BatteryCharging20",
  "BatteryCharging30",
  "BatteryCharging50",
  "BatteryCharging60",
  "BatteryCharging80",
  "BatteryCharging90",
  "BatteryChargingFull",
  "BatteryFull",
  "BatterySaver",
  "BatteryStd",
  "BatteryUnknown",
  "BeachAccess",
  "Bed",
  "BedroomBaby",
  "BedroomChild",
  "BedroomParent",
  "Bedtime",
  "BedtimeOff",
  "Beenhere",
  "Bento",
  "BikeScooter",
  "Biotech",
  "Blender",
  "Blind",
  "Blinds",
  "BlindsClosed",
  "Block",
  "Bloodtype",
  "Bluetooth",
  "BluetoothAudio",
  "BluetoothConnected",
  "BluetoothDisabled",
  "BluetoothDrive",
  "BluetoothSearching",
  "BlurCircular",
  "BlurLinear",
  "BlurOff",
  "BlurOn",
  "Bolt",
  "Book",
  "BookOnline",
  "Bookmark",
  "BookmarkAdd",
  "BookmarkAdded",
  "BookmarkBorder",
  "BookmarkRemove",
  "Bookmarks",
  "BorderAll",
  "BorderBottom",
  "BorderClear",
  "BorderColor",
  "BorderHorizontal",
  "BorderInner",
  "BorderLeft",
  "BorderOuter",
  "BorderRight",
  "BorderStyle",
  "BorderTop",
  "BorderVertical",
  "Boy",
  "BrandingWatermark",
  "BreakfastDining",
  "Brightness1",
  "Brightness2",
  "Brightness3",
  "Brightness4",
  "Brightness5",
  "Brightness6",
  "Brightness7",
  "BrightnessAuto",
  "BrightnessHigh",
  "BrightnessLow",
  "BrightnessMedium",
  "BroadcastOnHome",
  "BroadcastOnPersonal",
  "BrokenImage",
  "BrowseGallery",
  "BrowserNotSupported",
  "BrowserUpdated",
  "BrunchDining",
  "Brush",
  "BubbleChart",
  "BugReport",
  "Build",
  "BuildCircle",
  "Bungalow",
  "BurstMode",
  "BusAlert",
  "Business",
  "BusinessCenter",
  "Cabin",
  "Cable",
  "Cached",
  "Cake",
  "Calculate",
  "CalendarMonth",
  "CalendarToday",
  "CalendarViewDay",
  "CalendarViewMonth",
  "CalendarViewWeek",
  "Call",
  "CallEnd",
  "CallMade",
  "CallMerge",
  "CallMissed",
  "CallMissedOutgoing",
  "CallReceived",
  "CallSplit",
  "CallToAction",
  "Camera",
  "CameraAlt",
  "CameraEnhance",
  "CameraFront",
  "CameraIndoor",
  "CameraOutdoor",
  "CameraRear",
  "CameraRoll",
  "Cameraswitch",
  "Campaign",
  "Cancel",
  "CancelPresentation",
  "CancelScheduleSend",
  "CandlestickChart",
  "CarCrash",
  "CarRental",
  "CarRepair",
  "CardGiftcard",
  "CardMembership",
  "CardTravel",
  "Carpenter",
  "Cases",
  "Casino",
  "Cast",
  "CastConnected",
  "CastForEducation",
  "Castle",
  "CatchingPokemon",
  "Category",
  "Celebration",
  "CellTower",
  "CellWifi",
  "CenterFocusStrong",
  "CenterFocusWeak",
  "Chair",
  "ChairAlt",
  "Chalet",
  "ChangeCircle",
  "ChangeHistory",
  "ChargingStation",
  "Chat",
  "ChatBubble",
  "ChatBubbleOutline",
  "Check",
  "CheckBox",
  "CheckBoxOutlineBlank",
  "CheckCircle",
  "CheckCircleOutline",
  "Checklist",
  "ChecklistRtl",
  "Checkroom",
  "ChevronLeft",
  "ChevronRight",
  "ChildCare",
  "ChildFriendly",
  "ChromeReaderMode",
  "Church",
  "Circle",
  "CircleNotifications",
  "Class",
  "CleanHands",
  "CleaningServices",
  "Clear",
  "ClearAll",
  "Close",
  "CloseFullscreen",
  "ClosedCaption",
  "ClosedCaptionDisabled",
  "ClosedCaptionOff",
  "Cloud",
  "CloudCircle",
  "CloudDone",
  "CloudDownload",
  "CloudOff",
  "CloudQueue",
  "CloudSync",
  "CloudUpload",
  "Co2",
  "CoPresent",
  "Code",
  "CodeOff",
  "Coffee",
  "CoffeeMaker",
  "Collections",
  "CollectionsBookmark",
  "ColorLens",
  "Colorize",
  "Comment",
  "CommentBank",
  "CommentsDisabled",
  "Commit",
  "Commute",
  "Compare",
  "CompareArrows",
  "CompassCalibration",
  "Compress",
  "Computer",
  "ConfirmationNumber",
  "ConnectWithoutContact",
  "ConnectedTv",
  "ConnectingAirports",
  "Construction",
  "ContactEmergency",
  "ContactMail",
  "ContactPage",
  "ContactPhone",
  "ContactSupport",
  "Contactless",
  "Contacts",
  "ContentCopy",
  "ContentCut",
  "ContentPaste",
  "ContentPasteGo",
  "ContentPasteOff",
  "ContentPasteSearch",
  "Contrast",
  "ControlCamera",
  "ControlPoint",
  "ControlPointDuplicate",
  "Cookie",
  "CopyAll",
  "Copyright",
  "Coronavirus",
  "CorporateFare",
  "Cottage",
  "Countertops",
  "Create",
  "CreateNewFolder",
  "CreditCard",
  "CreditCardOff",
  "CreditScore",
  "Crib",
  "CrisisAlert",
  "Crop",
  "Crop169",
  "Crop32",
  "Crop54",
  "Crop75",
  "CropDin",
  "CropFree",
  "CropLandscape",
  "CropOriginal",
  "CropPortrait",
  "CropRotate",
  "CropSquare",
  "Css",
  "CurrencyBitcoin",
  "CurrencyExchange",
  "CurrencyFranc",
  "CurrencyLira",
  "CurrencyPound",
  "CurrencyRuble",
  "CurrencyRupee",
  "CurrencyYen",
  "CurrencyYuan",
  "Curtains",
  "CurtainsClosed",
  "Cyclone",
  "Dangerous",
  "DarkMode",
  "Dashboard",
  "DashboardCustomize",
  "DataArray",
  "DataObject",
  "DataSaverOff",
  "DataSaverOn",
  "DataThresholding",
  "DataUsage",
  "Dataset",
  "DatasetLinked",
  "DateRange",
  "Deblur",
  "Deck",
  "Dehaze",
  "Delete",
  "DeleteForever",
  "DeleteOutline",
  "DeleteSweep",
  "DeliveryDining",
  "DensityLarge",
  "DensityMedium",
  "DensitySmall",
  "DepartureBoard",
  "Description",
  "Deselect",
  "DesignServices",
  "Desk",
  "DesktopAccessDisabled",
  "DesktopMac",
  "DesktopWindows",
  "Details",
  "DeveloperBoard",
  "DeveloperBoardOff",
  "DeveloperMode",
  "DeviceHub",
  "DeviceThermostat",
  "DeviceUnknown",
  "Devices",
  "DevicesFold",
  "DevicesOther",
  "DialerSip",
  "Dialpad",
  "Diamond",
  "Difference",
  "Dining",
  "DinnerDining",
  "Directions",
  "DirectionsBike",
  "DirectionsBoat",
  "DirectionsBoatFilled",
  "DirectionsBus",
  "DirectionsBusFilled",
  "DirectionsCar",
  "DirectionsCarFilled",
  "DirectionsOff",
  "DirectionsRailway",
  "DirectionsRailwayFilled",
  "DirectionsRun",
  "DirectionsSubway",
  "DirectionsSubwayFilled",
  "DirectionsTransit",
  "DirectionsTransitFilled",
  "DirectionsWalk",
  "DirtyLens",
  "DisabledByDefault",
  "DiscFull",
  "Discount",
  "DisplaySettings",
  "Diversity1",
  "Diversity2",
  "Diversity3",
  "Dns",
  "DoDisturb",
  "DoDisturbAlt",
  "DoDisturbOff",
  "DoDisturbOn",
  "DoNotDisturb",
  "DoNotDisturbAlt",
  "DoNotDisturbOff",
  "DoNotDisturbOn",
  "DoNotDisturbOnTotalSilence",
  "DoNotStep",
  "DoNotTouch",
  "Dock",
  "DocumentScanner",
  "Domain",
  "DomainAdd",
  "DomainDisabled",
  "DomainVerification",
  "Done",
  "DoneAll",
  "DoneOutline",
  "DonutLarge",
  "DonutSmall",
  "DoorBack",
  "DoorFront",
  "DoorSliding",
  "Doorbell",
  "DoubleArrow",
  "DownhillSkiing",
  "Download",
  "DownloadDone",
  "DownloadForOffline",
  "Downloading",
  "Drafts",
  "DragHandle",
  "DragIndicator",
  "Draw",
  "DriveEta",
  "DriveFileMove",
  "DriveFileRenameOutline",
  "DriveFolderUpload",
  "Dry",
  "DryCleaning",
  "Duo",
  "Dvr",
  "DynamicFeed",
  "DynamicForm",
  "EMobiledata",
  "Earbuds",
  "EarbudsBattery",
  "East",
  "EdgesensorHigh",
  "EdgesensorLow",
  "Edit",
  "EditAttributes",
  "EditCalendar",
  "EditLocation",
  "EditLocationAlt",
  "EditNote",
  "EditNotifications",
  "EditOff",
  "EditRoad",
  "Egg",
  "EggAlt",
  "EightK",
  "EightKPlus",
  "EightMp",
  "EighteenMp",
  "EighteenUpRating",
  "EightteenMp",
  "Eject",
  "Elderly",
  "ElderlyWoman",
  "ElectricBike",
  "ElectricBolt",
  "ElectricCar",
  "ElectricMeter",
  "ElectricMoped",
  "ElectricRickshaw",
  "ElectricScooter",
  "ElectricalServices",
  "Elevator",
  "ElevenMp",
  "Email",
  "EmergencyRecording",
  "EmergencyShare",
  "EmojiEmotions",
  "EmojiEvents",
  "EmojiFlags",
  "EmojiFoodBeverage",
  "EmojiNature",
  "EmojiObjects",
  "EmojiPeople",
  "EmojiSymbols",
  "EmojiTransportation",
  "EnergySavingsLeaf",
  "Engineering",
  "EnhancedEncryption",
  "Equalizer",
  "Error",
  "ErrorOutline",
  "Escalator",
  "EscalatorWarning",
  "Euro",
  "EuroSymbol",
  "EvStation",
  "Event",
  "EventAvailable",
  "EventBusy",
  "EventNote",
  "EventRepeat",
  "EventSeat",
  "ExitToApp",
  "Expand",
  "ExpandCircleDown",
  "ExpandLess",
  "ExpandMore",
  "Explicit",
  "Explore",
  "ExploreOff",
  "Exposure",
  "Extension",
  "ExtensionOff",
  "Face",
  "Face2",
  "Face3",
  "Face4",
  "Face5",
  "Face6",
  "FaceRetouchingNatural",
  "FaceRetouchingOff",
  "Facebook",
  "FactCheck",
  "Factory",
  "FamilyRestroom",
  "FastForward",
  "FastRewind",
  "Fastfood",
  "Favorite",
  "FavoriteBorder",
  "Fax",
  "FeaturedPlayList",
  "FeaturedVideo",
  "Feed",
  "Feedback",
  "Female",
  "Fence",
  "Festival",
  "FiberDvr",
  "FiberManualRecord",
  "FiberNew",
  "FiberPin",
  "FiberSmartRecord",
  "FifteenMp",
  "FileCopy",
  "FileDownload",
  "FileDownloadDone",
  "FileDownloadOff",
  "FileOpen",
  "FilePresent",
  "FileUpload",
  "Filter",
  "Filter1",
  "Filter2",
  "Filter3",
  "Filter4",
  "Filter5",
  "Filter6",
  "Filter7",
  "Filter8",
  "Filter9",
  "Filter9Plus",
  "FilterAlt",
  "FilterAltOff",
  "FilterBAndW",
  "FilterCenterFocus",
  "FilterDrama",
  "FilterFrames",
  "FilterHdr",
  "FilterList",
  "FilterListOff",
  "FilterNone",
  "FilterTiltShift",
  "FilterVintage",
  "FindInPage",
  "FindReplace",
  "Fingerprint",
  "FireExtinguisher",
  "FireHydrantAlt",
  "FireTruck",
  "Fireplace",
  "FirstPage",
  "FitScreen",
  "Fitbit",
  "FitnessCenter",
  "FiveG",
  "FiveK",
  "FiveKPlus",
  "FiveMp",
  "FivteenMp",
  "Flag",
  "FlagCircle",
  "Flaky",
  "Flare",
  "FlashAuto",
  "FlashOff",
  "FlashOn",
  "FlashlightOff",
  "FlashlightOn",
  "Flatware",
  "Flight",
  "FlightClass",
  "FlightLand",
  "FlightTakeoff",
  "Flip",
  "FlipCameraAndroid",
  "FlipCameraIos",
  "FlipToBack",
  "FlipToFront",
  "Flood",
  "Fluorescent",
  "FlutterDash",
  "FmdBad",
  "FmdGood",
  "Folder",
  "FolderCopy",
  "FolderDelete",
  "FolderOff",
  "FolderOpen",
  "FolderShared",
  "FolderSpecial",
  "FolderZip",
  "FollowTheSigns",
  "FontDownload",
  "FontDownloadOff",
  "FoodBank",
  "Forest",
  "ForkLeft",
  "ForkRight",
  "FormatAlignCenter",
  "FormatAlignJustify",
  "FormatAlignLeft",
  "FormatAlignRight",
  "FormatBold",
  "FormatClear",
  "FormatColorFill",
  "FormatColorReset",
  "FormatColorText",
  "FormatIndentDecrease",
  "FormatIndentIncrease",
  "FormatItalic",
  "FormatLineSpacing",
  "FormatListBulleted",
  "FormatListNumbered",
  "FormatListNumberedRtl",
  "FormatOverline",
  "FormatPaint",
  "FormatQuote",
  "FormatShapes",
  "FormatSize",
  "FormatStrikethrough",
  "FormatTextdirectionLToR",
  "FormatTextdirectionRToL",
  "FormatUnderlined",
  "Fort",
  "Forum",
  "Forward",
  "Forward10",
  "Forward30",
  "Forward5",
  "ForwardToInbox",
  "Foundation",
  "FourGMobiledata",
  "FourGPlusMobiledata",
  "FourK",
  "FourKPlus",
  "FourMp",
  "FourteenMp",
  "FreeBreakfast",
  "Fullscreen",
  "FullscreenExit",
  "Functions",
  "GMobiledata",
  "GTranslate",
  "Gamepad",
  "Games",
  "Garage",
  "GasMeter",
  "Gavel",
  "Gesture",
  "GetApp",
  "Gif",
  "GifBox",
  "Girl",
  "GitHub",
  "Gite",
  "GolfCourse",
  "Google",
  "GppBad",
  "GppGood",
  "GppMaybe",
  "GpsFixed",
  "GpsNotFixed",
  "GpsOff",
  "Grade",
  "Gradient",
  "Grading",
  "Grain",
  "GraphicEq",
  "Grass",
  "Grid3x3",
  "Grid4x4",
  "GridGoldenratio",
  "GridOff",
  "GridOn",
  "GridView",
  "Group",
  "GroupAdd",
  "GroupRemove",
  "GroupWork",
  "Groups",
  "Groups2",
  "Groups3",
  "HMobiledata",
  "HPlusMobiledata",
  "Hail",
  "Handshake",
  "Handyman",
  "Hardware",
  "Hd",
  "HdrAuto",
  "HdrAutoSelect",
  "HdrEnhancedSelect",
  "HdrOff",
  "HdrOffSelect",
  "HdrOn",
  "HdrOnSelect",
  "HdrPlus",
  "HdrStrong",
  "HdrWeak",
  "Headphones",
  "HeadphonesBattery",
  "Headset",
  "HeadsetMic",
  "HeadsetOff",
  "Healing",
  "HealthAndSafety",
  "Hearing",
  "HearingDisabled",
  "HeartBroken",
  "HeatPump",
  "Height",
  "Help",
  "HelpCenter",
  "HelpOutline",
  "Hevc",
  "Hexagon",
  "HideImage",
  "HideSource",
  "HighQuality",
  "Highlight",
  "HighlightAlt",
  "HighlightOff",
  "Hiking",
  "History",
  "HistoryEdu",
  "HistoryToggleOff",
  "Hive",
  "Hls",
  "HlsOff",
  "HolidayVillage",
  "Home",
  "HomeMax",
  "HomeMini",
  "HomeRepairService",
  "HomeWork",
  "HorizontalRule",
  "HorizontalSplit",
  "HotTub",
  "Hotel",
  "HourglassBottom",
  "HourglassDisabled",
  "HourglassEmpty",
  "HourglassFull",
  "HourglassTop",
  "House",
  "HouseSiding",
  "Houseboat",
  "HowToReg",
  "HowToVote",
  "Html",
  "Http",
  "Https",
  "Hub",
  "Hvac",
  "IceSkating",
  "Icecream",
  "Image",
  "ImageAspectRatio",
  "ImageNotSupported",
  "ImageSearch",
  "ImagesearchRoller",
  "ImportContacts",
  "ImportExport",
  "ImportantDevices",
  "Inbox",
  "IndeterminateCheckBox",
  "Info",
  "Input",
  "InsertChart",
  "InsertComment",
  "InsertDriveFile",
  "InsertEmoticon",
  "InsertInvitation",
  "InsertLink",
  "InsertPageBreak",
  "InsertPhoto",
  "Insights",
  "Instagram",
  "InstallDesktop",
  "InstallMobile",
  "IntegrationInstructions",
  "Interests",
  "InterpreterMode",
  "Inventory",
  "Inventory2",
  "InvertColors",
  "InvertColorsOff",
  "IosShare",
  "Iron",
  "Iso",
  "Javascript",
  "JoinFull",
  "JoinInner",
  "JoinLeft",
  "JoinRight",
  "Kayaking",
  "KebabDining",
  "Key",
  "KeyOff",
  "Keyboard",
  "KeyboardAlt",
  "KeyboardArrowDown",
  "KeyboardArrowLeft",
  "KeyboardArrowRight",
  "KeyboardArrowUp",
  "KeyboardBackspace",
  "KeyboardCapslock",
  "KeyboardCommandKey",
  "KeyboardControlKey",
  "KeyboardDoubleArrowDown",
  "KeyboardDoubleArrowLeft",
  "KeyboardDoubleArrowRight",
  "KeyboardDoubleArrowUp",
  "KeyboardHide",
  "KeyboardOptionKey",
  "KeyboardReturn",
  "KeyboardTab",
  "KeyboardVoice",
  "KingBed",
  "Kitchen",
  "Kitesurfing",
  "Label",
  "LabelImportant",
  "LabelOff",
  "Lan",
  "Landscape",
  "Landslide",
  "Language",
  "Laptop",
  "LaptopChromebook",
  "LaptopMac",
  "LaptopWindows",
  "LastPage",
  "Launch",
  "Layers",
  "LayersClear",
  "Leaderboard",
  "LeakAdd",
  "LeakRemove",
  "LegendToggle",
  "Lens",
  "LensBlur",
  "LibraryAdd",
  "LibraryAddCheck",
  "LibraryBooks",
  "LibraryMusic",
  "Light",
  "LightMode",
  "Lightbulb",
  "LightbulbCircle",
  "LineAxis",
  "LineStyle",
  "LineWeight",
  "LinearScale",
  "Link",
  "LinkOff",
  "LinkedCamera",
  "LinkedIn",
  "Liquor",
  "List",
  "ListAlt",
  "LiveHelp",
  "LiveTv",
  "Living",
  "LocalActivity",
  "LocalAirport",
  "LocalAtm",
  "LocalBar",
  "LocalCafe",
  "LocalCarWash",
  "LocalConvenienceStore",
  "LocalDining",
  "LocalDrink",
  "LocalFireDepartment",
  "LocalFlorist",
  "LocalGasStation",
  "LocalGroceryStore",
  "LocalHospital",
  "LocalHotel",
  "LocalLaundryService",
  "LocalLibrary",
  "LocalMall",
  "LocalMovies",
  "LocalOffer",
  "LocalParking",
  "LocalPharmacy",
  "LocalPhone",
  "LocalPizza",
  "LocalPlay",
  "LocalPolice",
  "LocalPostOffice",
  "LocalPrintshop",
  "LocalSee",
  "LocalShipping",
  "LocalTaxi",
  "LocationCity",
  "LocationDisabled",
  "LocationOff",
  "LocationOn",
  "LocationSearching",
  "Lock",
  "LockClock",
  "LockOpen",
  "LockPerson",
  "LockReset",
  "Login",
  "LogoDev",
  "Logout",
  "Looks",
  "Looks3",
  "Looks4",
  "Looks5",
  "Looks6",
  "LooksOne",
  "LooksTwo",
  "Loop",
  "Loupe",
  "LowPriority",
  "Loyalty",
  "LteMobiledata",
  "LtePlusMobiledata",
  "Luggage",
  "LunchDining",
  "Lyrics",
  "MacroOff",
  "Mail",
  "MailLock",
  "MailOutline",
  "Male",
  "Man",
  "Man2",
  "Man3",
  "Man4",
  "ManageAccounts",
  "ManageHistory",
  "ManageSearch",
  "Map",
  "MapsHomeWork",
  "MapsUgc",
  "Margin",
  "MarkAsUnread",
  "MarkChatRead",
  "MarkChatUnread",
  "MarkEmailRead",
  "MarkEmailUnread",
  "MarkUnreadChatAlt",
  "Markunread",
  "MarkunreadMailbox",
  "Masks",
  "Maximize",
  "MediaBluetoothOff",
  "MediaBluetoothOn",
  "Mediation",
  "MedicalInformation",
  "MedicalServices",
  "Medication",
  "MedicationLiquid",
  "MeetingRoom",
  "Memory",
  "Menu",
  "MenuBook",
  "MenuOpen",
  "Merge",
  "MergeType",
  "Message",
  "Mic",
  "MicExternalOff",
  "MicExternalOn",
  "MicNone",
  "MicOff",
  "Microsoft",
  "Microwave",
  "MilitaryTech",
  "Minimize",
  "MinorCrash",
  "MiscellaneousServices",
  "MissedVideoCall",
  "Mms",
  "MobileFriendly",
  "MobileOff",
  "MobileScreenShare",
  "MobiledataOff",
  "Mode",
  "ModeComment",
  "ModeEdit",
  "ModeEditOutline",
  "ModeFanOff",
  "ModeNight",
  "ModeOfTravel",
  "ModeStandby",
  "ModelTraining",
  "MonetizationOn",
  "Money",
  "MoneyOff",
  "MoneyOffCsred",
  "Monitor",
  "MonitorHeart",
  "MonitorWeight",
  "MonochromePhotos",
  "Mood",
  "MoodBad",
  "Moped",
  "More",
  "MoreHoriz",
  "MoreTime",
  "MoreVert",
  "Mosque",
  "MotionPhotosAuto",
  "MotionPhotosOff",
  "Mouse",
  "MoveDown",
  "MoveToInbox",
  "MoveUp",
  "Movie",
  "MovieCreation",
  "MovieFilter",
  "Moving",
  "Mp",
  "MultilineChart",
  "MultipleStop",
  "Museum",
  "MusicNote",
  "MusicOff",
  "MusicVideo",
  "MyLocation",
  "Nat",
  "Nature",
  "NaturePeople",
  "NavigateBefore",
  "NavigateNext",
  "Navigation",
  "NearMe",
  "NearMeDisabled",
  "NearbyError",
  "NearbyOff",
  "NestCamWiredStand",
  "NetworkCell",
  "NetworkCheck",
  "NetworkLocked",
  "NetworkPing",
  "NetworkWifi",
  "NetworkWifi1Bar",
  "NetworkWifi2Bar",
  "NetworkWifi3Bar",
  "NewReleases",
  "Newspaper",
  "NextPlan",
  "NextWeek",
  "Nfc",
  "NightShelter",
  "Nightlife",
  "Nightlight",
  "NightlightRound",
  "NightsStay",
  "NineK",
  "NineKPlus",
  "NineMp",
  "NineteenMp",
  "NoAccounts",
  "NoAdultContent",
  "NoBackpack",
  "NoCell",
  "NoCrash",
  "NoDrinks",
  "NoEncryption",
  "NoEncryptionGmailerrorred",
  "NoFlash",
  "NoFood",
  "NoLuggage",
  "NoMeals",
  "NoMeetingRoom",
  "NoPhotography",
  "NoSim",
  "NoStroller",
  "NoTransfer",
  "NoiseAware",
  "NoiseControlOff",
  "NordicWalking",
  "North",
  "NorthEast",
  "NorthWest",
  "NotAccessible",
  "NotInterested",
  "NotListedLocation",
  "NotStarted",
  "Note",
  "NoteAdd",
  "NoteAlt",
  "Notes",
  "NotificationAdd",
  "NotificationImportant",
  "Notifications",
  "NotificationsActive",
  "NotificationsNone",
  "NotificationsOff",
  "NotificationsPaused",
  "Numbers",
  "OfflineBolt",
  "OfflinePin",
  "OfflineShare",
  "OilBarrel",
  "OnDeviceTraining",
  "OndemandVideo",
  "OneK",
  "OneKPlus",
  "OneKk",
  "OnlinePrediction",
  "Opacity",
  "OpenInBrowser",
  "OpenInFull",
  "OpenInNew",
  "OpenInNewOff",
  "OpenWith",
  "OtherHouses",
  "Outbound",
  "Outbox",
  "OutdoorGrill",
  "Outlet",
  "Output",
  "Padding",
  "Pages",
  "Pageview",
  "Paid",
  "Palette",
  "PanTool",
  "PanToolAlt",
  "Panorama",
  "PanoramaFishEye",
  "PanoramaHorizontal",
  "PanoramaHorizontalSelect",
  "PanoramaPhotosphere",
  "PanoramaPhotosphereSelect",
  "PanoramaVertical",
  "PanoramaVerticalSelect",
  "PanoramaWideAngle",
  "PanoramaWideAngleSelect",
  "Paragliding",
  "Park",
  "PartyMode",
  "Password",
  "Pattern",
  "Pause",
  "PauseCircle",
  "PauseCircleFilled",
  "PauseCircleOutline",
  "PausePresentation",
  "Payment",
  "Payments",
  "PedalBike",
  "Pending",
  "PendingActions",
  "Pentagon",
  "People",
  "PeopleAlt",
  "PeopleOutline",
  "Percent",
  "PermCameraMic",
  "PermContactCalendar",
  "PermDataSetting",
  "PermDeviceInformation",
  "PermIdentity",
  "PermMedia",
  "PermPhoneMsg",
  "PermScanWifi",
  "Person",
  "Person2",
  "Person3",
  "Person4",
  "PersonAdd",
  "PersonAddAlt",
  "PersonAddAlt1",
  "PersonAddDisabled",
  "PersonOff",
  "PersonOutline",
  "PersonPin",
  "PersonPinCircle",
  "PersonRemove",
  "PersonRemoveAlt1",
  "PersonSearch",
  "PersonalVideo",
  "PestControl",
  "PestControlRodent",
  "Pets",
  "Phishing",
  "Phone",
  "PhoneAndroid",
  "PhoneBluetoothSpeaker",
  "PhoneCallback",
  "PhoneDisabled",
  "PhoneEnabled",
  "PhoneForwarded",
  "PhoneInTalk",
  "PhoneIphone",
  "PhoneLocked",
  "PhoneMissed",
  "PhonePaused",
  "Phonelink",
  "PhonelinkErase",
  "PhonelinkLock",
  "PhonelinkOff",
  "PhonelinkRing",
  "PhonelinkSetup",
  "Photo",
  "PhotoAlbum",
  "PhotoCamera",
  "PhotoCameraBack",
  "PhotoCameraFront",
  "PhotoFilter",
  "PhotoLibrary",
  "PhotoSizeSelectActual",
  "PhotoSizeSelectLarge",
  "PhotoSizeSelectSmall",
  "Php",
  "Piano",
  "PianoOff",
  "PictureAsPdf",
  "PictureInPicture",
  "PictureInPictureAlt",
  "PieChart",
  "PieChartOutline",
  "Pin",
  "PinDrop",
  "Pinch",
  "Pinterest",
  "PivotTableChart",
  "Pix",
  "Place",
  "Plagiarism",
  "PlayArrow",
  "PlayCircle",
  "PlayCircleFilled",
  "PlayCircleFilledWhite",
  "PlayCircleOutline",
  "PlayDisabled",
  "PlayForWork",
  "PlayLesson",
  "PlaylistAdd",
  "PlaylistAddCheck",
  "PlaylistAddCheckCircle",
  "PlaylistAddCircle",
  "PlaylistPlay",
  "PlaylistRemove",
  "Plumbing",
  "PlusOne",
  "Podcasts",
  "PointOfSale",
  "Policy",
  "Poll",
  "Polyline",
  "Pool",
  "PortableWifiOff",
  "Portrait",
  "PostAdd",
  "Power",
  "PowerInput",
  "PowerOff",
  "PowerSettingsNew",
  "PrecisionManufacturing",
  "PregnantWoman",
  "PresentToAll",
  "Preview",
  "PriceChange",
  "PriceCheck",
  "Print",
  "PrintDisabled",
  "PriorityHigh",
  "PrivacyTip",
  "ProductionQuantityLimits",
  "Propane",
  "PropaneTank",
  "Psychology",
  "PsychologyAlt",
  "Public",
  "PublicOff",
  "Publish",
  "PublishedWithChanges",
  "PunchClock",
  "PushPin",
  "QrCode",
  "QrCode2",
  "QrCodeScanner",
  "QueryBuilder",
  "QueryStats",
  "QuestionAnswer",
  "QuestionMark",
  "Queue",
  "QueueMusic",
  "QueuePlayNext",
  "Quickreply",
  "Quiz",
  "RMobiledata",
  "Radar",
  "Radio",
  "RadioButtonChecked",
  "RadioButtonUnchecked",
  "RailwayAlert",
  "RamenDining",
  "RampLeft",
  "RampRight",
  "RateReview",
  "RawOff",
  "RawOn",
  "ReadMore",
  "Receipt",
  "ReceiptLong",
  "RecentActors",
  "Recommend",
  "RecordVoiceOver",
  "Rectangle",
  "Recycling",
  "Reddit",
  "Redeem",
  "Redo",
  "ReduceCapacity",
  "Refresh",
  "RememberMe",
  "Remove",
  "RemoveCircle",
  "RemoveCircleOutline",
  "RemoveDone",
  "RemoveFromQueue",
  "RemoveModerator",
  "RemoveRedEye",
  "RemoveRoad",
  "RemoveShoppingCart",
  "Reorder",
  "Repartition",
  "Repeat",
  "RepeatOn",
  "RepeatOne",
  "RepeatOneOn",
  "Replay",
  "Replay10",
  "Replay30",
  "Replay5",
  "ReplayCircleFilled",
  "Reply",
  "ReplyAll",
  "Report",
  "ReportGmailerrorred",
  "ReportOff",
  "ReportProblem",
  "RequestPage",
  "RequestQuote",
  "ResetTv",
  "RestartAlt",
  "Restaurant",
  "RestaurantMenu",
  "Restore",
  "RestoreFromTrash",
  "RestorePage",
  "Reviews",
  "RiceBowl",
  "RingVolume",
  "Rocket",
  "RocketLaunch",
  "RollerShades",
  "RollerShadesClosed",
  "RollerSkating",
  "Roofing",
  "Room",
  "RoomPreferences",
  "RoomService",
  "Rotate90DegreesCcw",
  "Rotate90DegreesCw",
  "RotateLeft",
  "RotateRight",
  "RoundaboutLeft",
  "RoundaboutRight",
  "Route",
  "Router",
  "Rowing",
  "RssFeed",
  "Rsvp",
  "Rtt",
  "Rule",
  "RuleFolder",
  "RunCircle",
  "RunningWithErrors",
  "RvHookup",
  "SafetyCheck",
  "SafetyDivider",
  "Sailing",
  "Sanitizer",
  "Satellite",
  "SatelliteAlt",
  "Save",
  "SaveAlt",
  "SaveAs",
  "SavedSearch",
  "Savings",
  "Scale",
  "Scanner",
  "ScatterPlot",
  "Schedule",
  "ScheduleSend",
  "Schema",
  "School",
  "Science",
  "Score",
  "Scoreboard",
  "ScreenLockLandscape",
  "ScreenLockPortrait",
  "ScreenLockRotation",
  "ScreenRotation",
  "ScreenRotationAlt",
  "ScreenSearchDesktop",
  "ScreenShare",
  "Screenshot",
  "ScreenshotMonitor",
  "ScubaDiving",
  "Sd",
  "SdCard",
  "SdCardAlert",
  "SdStorage",
  "Search",
  "SearchOff",
  "Security",
  "SecurityUpdate",
  "SecurityUpdateGood",
  "SecurityUpdateWarning",
  "Segment",
  "SelectAll",
  "SelfImprovement",
  "Sell",
  "Send",
  "SendAndArchive",
  "SendTimeExtension",
  "SendToMobile",
  "SensorDoor",
  "SensorOccupied",
  "SensorWindow",
  "Sensors",
  "SensorsOff",
  "SentimentDissatisfied",
  "SentimentNeutral",
  "SentimentSatisfied",
  "SentimentSatisfiedAlt",
  "SentimentVeryDissatisfied",
  "SentimentVerySatisfied",
  "SetMeal",
  "Settings",
  "SettingsAccessibility",
  "SettingsApplications",
  "SettingsBackupRestore",
  "SettingsBluetooth",
  "SettingsBrightness",
  "SettingsCell",
  "SettingsEthernet",
  "SettingsInputAntenna",
  "SettingsInputComponent",
  "SettingsInputComposite",
  "SettingsInputHdmi",
  "SettingsInputSvideo",
  "SettingsOverscan",
  "SettingsPhone",
  "SettingsPower",
  "SettingsRemote",
  "SettingsSuggest",
  "SettingsSystemDaydream",
  "SettingsVoice",
  "SevenK",
  "SevenKPlus",
  "SevenMp",
  "SeventeenMp",
  "SevereCold",
  "ShapeLine",
  "Share",
  "ShareLocation",
  "Shield",
  "ShieldMoon",
  "Shop",
  "Shop2",
  "ShopTwo",
  "ShoppingBag",
  "ShoppingBasket",
  "ShoppingCart",
  "ShoppingCartCheckout",
  "ShortText",
  "Shortcut",
  "ShowChart",
  "Shower",
  "Shuffle",
  "ShuffleOn",
  "ShutterSpeed",
  "Sick",
  "SignLanguage",
  "SignalCellular0Bar",
  "SignalCellular1Bar",
  "SignalCellular2Bar",
  "SignalCellular3Bar",
  "SignalCellular4Bar",
  "SignalCellularAlt",
  "SignalCellularAlt1Bar",
  "SignalCellularAlt2Bar",
  "SignalCellularConnectedNoInternet0Bar",
  "SignalCellularConnectedNoInternet1Bar",
  "SignalCellularConnectedNoInternet2Bar",
  "SignalCellularConnectedNoInternet3Bar",
  "SignalCellularConnectedNoInternet4Bar",
  "SignalCellularNoSim",
  "SignalCellularNodata",
  "SignalCellularNull",
  "SignalCellularOff",
  "SignalWifi0Bar",
  "SignalWifi1Bar",
  "SignalWifi1BarLock",
  "SignalWifi2Bar",
  "SignalWifi2BarLock",
  "SignalWifi3Bar",
  "SignalWifi3BarLock",
  "SignalWifi4Bar",
  "SignalWifi4BarLock",
  "SignalWifiBad",
  "SignalWifiConnectedNoInternet4",
  "SignalWifiOff",
  "SignalWifiStatusbar4Bar",
  "SignalWifiStatusbarConnectedNoInternet4",
  "SignalWifiStatusbarNull",
  "Signpost",
  "SimCard",
  "SimCardAlert",
  "SimCardDownload",
  "SingleBed",
  "Sip",
  "SixK",
  "SixKPlus",
  "SixMp",
  "SixteenMp",
  "SixtyFps",
  "SixtyFpsSelect",
  "Skateboarding",
  "SkipNext",
  "SkipPrevious",
  "Sledding",
  "Slideshow",
  "SlowMotionVideo",
  "SmartButton",
  "SmartDisplay",
  "SmartScreen",
  "SmartToy",
  "Smartphone",
  "SmokeFree",
  "SmokingRooms",
  "Sms",
  "SmsFailed",
  "SnippetFolder",
  "Snooze",
  "Snowboarding",
  "Snowmobile",
  "Snowshoeing",
  "Soap",
  "SocialDistance",
  "SolarPower",
  "Sort",
  "SortByAlpha",
  "Sos",
  "SoupKitchen",
  "Source",
  "South",
  "SouthAmerica",
  "SouthEast",
  "SouthWest",
  "Spa",
  "SpaceBar",
  "SpaceDashboard",
  "SpatialAudio",
  "SpatialAudioOff",
  "SpatialTracking",
  "Speaker",
  "SpeakerGroup",
  "SpeakerNotes",
  "SpeakerNotesOff",
  "SpeakerPhone",
  "Speed",
  "Spellcheck",
  "Splitscreen",
  "Spoke",
  "Sports",
  "SportsBar",
  "SportsBaseball",
  "SportsBasketball",
  "SportsCricket",
  "SportsEsports",
  "SportsFootball",
  "SportsGolf",
  "SportsGymnastics",
  "SportsHandball",
  "SportsHockey",
  "SportsKabaddi",
  "SportsMartialArts",
  "SportsMma",
  "SportsMotorsports",
  "SportsRugby",
  "SportsScore",
  "SportsSoccer",
  "SportsTennis",
  "SportsVolleyball",
  "Square",
  "SquareFoot",
  "SsidChart",
  "StackedBarChart",
  "StackedLineChart",
  "Stadium",
  "Stairs",
  "Star",
  "StarBorder",
  "StarBorderPurple500",
  "StarHalf",
  "StarOutline",
  "StarPurple500",
  "StarRate",
  "Stars",
  "Start",
  "StayCurrentLandscape",
  "StayCurrentPortrait",
  "StayPrimaryLandscape",
  "StayPrimaryPortrait",
  "StickyNote2",
  "Stop",
  "StopCircle",
  "StopScreenShare",
  "Storage",
  "Store",
  "StoreMallDirectory",
  "Storefront",
  "Storm",
  "Straight",
  "Straighten",
  "Stream",
  "Streetview",
  "StrikethroughS",
  "Stroller",
  "Style",
  "SubdirectoryArrowLeft",
  "SubdirectoryArrowRight",
  "Subject",
  "Subscript",
  "Subscriptions",
  "Subtitles",
  "SubtitlesOff",
  "Subway",
  "Summarize",
  "Superscript",
  "SupervisedUserCircle",
  "SupervisorAccount",
  "Support",
  "SupportAgent",
  "Surfing",
  "SurroundSound",
  "SwapCalls",
  "SwapHoriz",
  "SwapHorizontalCircle",
  "SwapVert",
  "SwapVerticalCircle",
  "Swipe",
  "SwipeDown",
  "SwipeDownAlt",
  "SwipeLeft",
  "SwipeLeftAlt",
  "SwipeRight",
  "SwipeRightAlt",
  "SwipeUp",
  "SwipeUpAlt",
  "SwipeVertical",
  "SwitchAccessShortcut",
  "SwitchAccessShortcutAdd",
  "SwitchAccount",
  "SwitchCamera",
  "SwitchLeft",
  "SwitchRight",
  "SwitchVideo",
  "Synagogue",
  "Sync",
  "SyncAlt",
  "SyncDisabled",
  "SyncLock",
  "SyncProblem",
  "SystemSecurityUpdate",
  "SystemSecurityUpdateGood",
  "SystemSecurityUpdateWarning",
  "SystemUpdate",
  "SystemUpdateAlt",
  "Tab",
  "TabUnselected",
  "TableBar",
  "TableChart",
  "TableRestaurant",
  "TableRows",
  "TableView",
  "Tablet",
  "TabletAndroid",
  "TabletMac",
  "Tag",
  "TagFaces",
  "TakeoutDining",
  "TapAndPlay",
  "Tapas",
  "Task",
  "TaskAlt",
  "TaxiAlert",
  "Telegram",
  "TempleBuddhist",
  "TempleHindu",
  "TenMp",
  "Terminal",
  "Terrain",
  "TextDecrease",
  "TextFields",
  "TextFormat",
  "TextIncrease",
  "TextRotateUp",
  "TextRotateVertical",
  "TextRotationAngledown",
  "TextRotationAngleup",
  "TextRotationDown",
  "TextRotationNone",
  "TextSnippet",
  "Textsms",
  "Texture",
  "TheaterComedy",
  "Theaters",
  "Thermostat",
  "ThermostatAuto",
  "ThirteenMp",
  "ThirtyFps",
  "ThirtyFpsSelect",
  "ThreeDRotation",
  "ThreeGMobiledata",
  "ThreeK",
  "ThreeKPlus",
  "ThreeMp",
  "ThreeP",
  "ThreeSixty",
  "ThumbDown",
  "ThumbDownAlt",
  "ThumbDownOffAlt",
  "ThumbUp",
  "ThumbUpAlt",
  "ThumbUpOffAlt",
  "ThumbsUpDown",
  "Thunderstorm",
  "TimeToLeave",
  "Timelapse",
  "Timeline",
  "Timer",
  "Timer10",
  "Timer10Select",
  "Timer3",
  "Timer3Select",
  "TimerOff",
  "TimesOneMobiledata",
  "TipsAndUpdates",
  "TireRepair",
  "Title",
  "Toc",
  "Today",
  "ToggleOff",
  "ToggleOn",
  "Token",
  "Toll",
  "Tonality",
  "Topic",
  "Tornado",
  "TouchApp",
  "Tour",
  "Toys",
  "TrackChanges",
  "Traffic",
  "Train",
  "Tram",
  "Transcribe",
  "TransferWithinAStation",
  "Transform",
  "Transgender",
  "TransitEnterexit",
  "Translate",
  "TravelExplore",
  "TrendingDown",
  "TrendingFlat",
  "TrendingUp",
  "TripOrigin",
  "Troubleshoot",
  "Try",
  "Tsunami",
  "Tty",
  "Tune",
  "Tungsten",
  "TurnLeft",
  "TurnRight",
  "TurnSlightLeft",
  "TurnSlightRight",
  "TurnedIn",
  "TurnedInNot",
  "Tv",
  "TvOff",
  "TwelveMp",
  "TwentyFourMp",
  "TwentyOneMp",
  "TwentyThreeMp",
  "TwentyTwoMp",
  "TwentyZeroMp",
  "Twitter",
  "TwoK",
  "TwoKPlus",
  "TwoMp",
  "TwoWheeler",
  "TypeSpecimen",
  "UTurnLeft",
  "UTurnRight",
  "Umbrella",
  "Unarchive",
  "Undo",
  "UnfoldLess",
  "UnfoldLessDouble",
  "UnfoldMore",
  "UnfoldMoreDouble",
  "Unpublished",
  "Unsubscribe",
  "Upcoming",
  "Update",
  "UpdateDisabled",
  "Upgrade",
  "Upload",
  "UploadFile",
  "Usb",
  "UsbOff",
  "Vaccines",
  "VapeFree",
  "VapingRooms",
  "Verified",
  "VerifiedUser",
  "VerticalAlignBottom",
  "VerticalAlignCenter",
  "VerticalAlignTop",
  "VerticalShades",
  "VerticalShadesClosed",
  "VerticalSplit",
  "Vibration",
  "VideoCall",
  "VideoCameraBack",
  "VideoCameraFront",
  "VideoChat",
  "VideoFile",
  "VideoLabel",
  "VideoLibrary",
  "VideoSettings",
  "VideoStable",
  "Videocam",
  "VideocamOff",
  "VideogameAsset",
  "VideogameAssetOff",
  "ViewAgenda",
  "ViewArray",
  "ViewCarousel",
  "ViewColumn",
  "ViewComfy",
  "ViewComfyAlt",
  "ViewCompact",
  "ViewCompactAlt",
  "ViewCozy",
  "ViewDay",
  "ViewHeadline",
  "ViewInAr",
  "ViewKanban",
  "ViewList",
  "ViewModule",
  "ViewQuilt",
  "ViewSidebar",
  "ViewStream",
  "ViewTimeline",
  "ViewWeek",
  "Vignette",
  "Villa",
  "Visibility",
  "VisibilityOff",
  "VoiceChat",
  "VoiceOverOff",
  "Voicemail",
  "Volcano",
  "VolumeDown",
  "VolumeMute",
  "VolumeOff",
  "VolumeUp",
  "VolunteerActivism",
  "VpnKey",
  "VpnKeyOff",
  "VpnLock",
  "Vrpano",
  "Wallet",
  "Wallpaper",
  "Warehouse",
  "Warning",
  "WarningAmber",
  "Wash",
  "Watch",
  "WatchLater",
  "WatchOff",
  "Water",
  "WaterDamage",
  "WaterDrop",
  "WaterfallChart",
  "Waves",
  "WavingHand",
  "WbAuto",
  "WbCloudy",
  "WbIncandescent",
  "WbIridescent",
  "WbShade",
  "WbSunny",
  "WbTwilight",
  "Wc",
  "Web",
  "WebAsset",
  "WebAssetOff",
  "WebStories",
  "Webhook",
  "Weekend",
  "West",
  "WhatsApp",
  "Whatshot",
  "WheelchairPickup",
  "WhereToVote",
  "Widgets",
  "WidthFull",
  "WidthNormal",
  "WidthWide",
  "Wifi",
  "Wifi1Bar",
  "Wifi2Bar",
  "WifiCalling",
  "WifiCalling3",
  "WifiChannel",
  "WifiFind",
  "WifiLock",
  "WifiOff",
  "WifiPassword",
  "WifiProtectedSetup",
  "WifiTethering",
  "WifiTetheringError",
  "WifiTetheringOff",
  "WindPower",
  "Window",
  "WineBar",
  "Woman",
  "Woman2",
  "Work",
  "WorkHistory",
  "WorkOff",
  "WorkOutline",
  "WorkspacePremium",
  "Workspaces",
  "WrapText",
  "WrongLocation",
  "Wysiwyg",
  "Yard",
  "YouTube",
  "YoutubeSearchedFor",
  "ZoomIn",
  "ZoomInMap",
  "ZoomOut",
  "ZoomOutMap",
];

export default MUIFilledIcons;
