import React from "react";

const Divider = (props) => {
  const { attributes, children } = props;
  return (
    <div
      {...attributes}
      className="dividerComponent"
      contentEditable="false"
      style={{ userSelect: "none" }}
    >
      <hr
        contentEditable="false"
        className="editorDivider"
        style={{ userSelect: "none" }}
      />
      <span style={{ display: "none" }}>{children}</span>
    </div>
  );
};

export default Divider;
