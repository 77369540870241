import Settings from "./Settings";
import Link from "./Link";
import SavePopup from "./SaveAsTemplate";

const OtherSettings = {
  link: Link,
  settings: Settings,
  saveAsTemplate: SavePopup,
};

export default OtherSettings;
