import React, { useState } from "react";
import { Node, Transforms } from "slate";
import {
  ReactEditor,
  useFocused,
  useSelected,
  useSlateStatic,
} from "slate-react";
import { IconButton, Tooltip } from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import EditIcon from "@mui/icons-material/Edit";
import LinkOffIcon from "@mui/icons-material/LinkOff";
import { removeLink } from "../../utils/link";
import LinkPopup from "./LinkPopup";
import "./styles.css";
import { absoluteLink } from "../../utils/helper";

const Link = (props) => {
  const { attributes, element, children } = props;
  const editor = useSlateStatic();
  const selected = useSelected();
  const focused = useFocused();
  const [showInput, setShowInput] = useState(false);
  const [linkData, setLinkData] = useState({
    name: "",
    url: "",
    showInNewTab: true,
  });
  const path = ReactEditor.findPath(editor, element);
  const urlPath = element.url || element.href;
  const absLink = absoluteLink(urlPath);

  const updateLink = () => {
    Transforms.setNodes(
      editor,
      {
        href: linkData?.url,
        showInNewTab: linkData?.showInNewTab,
      },
      { at: path }
    );
    setLinkData({
      name: "",
      url: "",
      showInNewTab: true,
    });
    setShowInput(false);
  };

  const onEditLink = () => {
    setLinkData({
      name: Node.string(element),
      url: urlPath || "",
      showInNewTab: element?.showInNewTab,
    });
    setShowInput(true);
  };

  const handleInputChange = ({ target }) => {
    let val = target.type === "checkbox" ? target.checked : target.value;
    setLinkData({
      ...linkData,
      [target.name]: val,
    });
  };

  const handleClose = () => {
    setShowInput(false);
  };

  const Toolbar = () => {
    return selected && focused ? (
      <div
        className="element-toolbar hr"
        contentEditable={false}
        style={{ width: "150px", top: "100%", left: "0px" }}
      >
        <Tooltip title="Open">
          <IconButton href={absLink} target="_blank">
            <OpenInNewIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Edit">
          <IconButton onClick={onEditLink}>
            <EditIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Remove">
          <IconButton onClick={() => removeLink(editor)}>
            <LinkOffIcon />
          </IconButton>
        </Tooltip>
      </div>
    ) : null;
  };

  return (
    <div className="link">
      <a
        href={absLink}
        {...attributes}
        {...element.attr}
        target={element.target}
      >
        {children}
      </a>
      <Toolbar />
      <LinkPopup
        open={showInput}
        linkData={linkData}
        handleClose={handleClose}
        handleInputChange={handleInputChange}
        handleInsertLink={updateLink}
      />
    </div>
  );
};

export default Link;
