import React, { useEffect, useState } from "react";
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  IconButton,
  Typography,
  Drawer,
  SwipeableDrawer,
} from "@mui/material";
import FieldMap from "./fieldTypes";
import CloseIcon from "@mui/icons-material/Close";
import useCommonStyle from "../../commonStyle";
import { useEditorContext } from "../../hooks/useMouseMove";

export const StyleContent = (props) => {
  const {
    value,
    renderTabs,
    onChange,
    element: propsElement,
    customProps,
    handleClose,
    customElement,
  } = props;
  const element = customElement ? customElement : propsElement;
  const { hideTools } = customProps || {};
  const { theme } = useEditorContext();
  const classes = useCommonStyle(theme);
  const tabContent = renderTabs.find((f) => f.value === value);
  const { fields } = tabContent || { fields: [] };
  const filteredFields =
    (hideTools || [])?.length > 0
      ? fields.filter((f) => (hideTools || []).indexOf(f.key) === -1)
      : fields;

  return (
    <Grid container spacing={2} key={value} className="sidebar-wrpr-eds">
      {[...filteredFields].map((m, i) => {
        const FieldComponent = FieldMap[m.type];
        return FieldComponent ? (
          <FieldComponent
            data={m}
            key={`ei_stt_tab_${value}_${m.key}_${i}`}
            value={element[m.key]}
            onChange={onChange}
            elementProps={element}
            customProps={customProps}
            handleClose={handleClose}
            classes={classes}
          />
        ) : null;
      })}
    </Grid>
  );
};

const StyleBuilder = (props) => {
  const { title, renderTabs, element, onSave, onClose, onDelete, customProps } =
    props;
  const { theme } = useEditorContext();
  const classes = useCommonStyle(theme);
  const isMobile = customProps?.isMobile || false;
  const [elementProps, setElementProps] = useState(element);
  const [tab] = useState(renderTabs[0]?.value);
  const tabVal = renderTabs?.find((f) => f.value === tab);
  const { needActions = true } = tabVal || { needActions: true };

  useEffect(() => {
    if (customProps?.onDrawerOpen) {
      customProps?.onDrawerOpen(true);
    }
    return () => {
      if (customProps?.onDrawerOpen) {
        customProps?.onDrawerOpen(false);
      }
    };
  }, []);

  const onElementPropsChange = (data) => {
    setElementProps({
      ...elementProps,
      ...data,
      field_type: data?.element,
    });
    if (data?.hasOwnProperty("name")) {
      setElementProps({
        ...elementProps,
        ...data,
        key: data?.name,
        label: data?.name,
      });
    }
  };

  const handleSave = () => {
    onSave(elementProps);
  };

  const handleClose = () => {
    onClose();
  };

  const renderDrawerMenu = () => {
    return (
      <Grid item xs={12} sx={{ p: 2, width: isMobile ? "100%" : "350px" }}>
        <DialogTitle sx={{ p: 0, pb: 2 }} className="optionePopupHeader">
          <Grid container justifyContent={"space-between"}>
            <Typography variant="h6" className="popupTitle" color={"primary"}>
              {title}
            </Typography>
            <Grid style={{ display: "flex" }}>
              <IconButton onClick={onClose} className="close-popupbtn">
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent
          sx={{
            maxHeight: isMobile ? `500px` : `${window.innerHeight - 125}px`,
            padding: "8px",
          }}
        >
          {renderTabs.map((m, i) => {
            return (
              <StyleContent
                key={`tab_${m.value}_$${i}`}
                renderTabs={renderTabs}
                value={m.value}
                element={elementProps}
                onChange={onElementPropsChange}
                customProps={customProps}
                handleClose={handleClose}
              />
            );
          })}
        </DialogContent>
        {needActions ? (
          <DialogActions sx={{ p: 0, pt: 2, justifyContent: "space-between" }}>
            {onDelete ? (
              <Button onClick={onDelete} color="error" className="deleteBtn">
                Delete
              </Button>
            ) : null}
            <Button onClick={handleSave} className="primaryBtn">
              Save
            </Button>
          </DialogActions>
        ) : null}
      </Grid>
    );
  };

  return (
    <>
      {isMobile ? (
        <SwipeableDrawer
          open={true}
          className={` dialogComp tools-drawer`}
          anchor={"bottom"}
          onClose={onClose}
          style={{ zIndex: "1300" }}
          sx={classes.bottomBarDrawer}
        >
          {renderDrawerMenu()}
        </SwipeableDrawer>
      ) : (
        <Drawer
          open={true}
          className={` dialogComp tools-drawer`}
          anchor={"right"}
          onClose={onClose}
          style={{ zIndex: "1300" }}
          sx={classes.sideBarDrawer}
        >
          {renderDrawerMenu()}
        </Drawer>
      )}
    </>
  );
};

// export default StyleBuilder;
export default StyleBuilder;
