import { useEffect, useState } from "react";

const ePos = {
  x: 0,
  y: 0,
};

const useDragDom = (props) => {
  const { refDom, readOnly } = props || {};
  const [event, setEvent] = useState("");
  const [delta, setDelta] = useState({ x: 0, y: 0 });
  useEffect(() => {
    if (!readOnly) {
      addListener();
      return () => {
        removeListener();
      };
    }
  }, [refDom, event]);

  const onMouseDown = (e) => {
    ePos.x = e.x;
    ePos.y = e.y;
    setDelta({
      x: 0,
      y: 0,
    });
    setEvent("start");
  };

  const onMouseMove = (e) => {
    if (ePos.x) {
      setDelta({
        x: e.x - ePos.x,
        y: e.y - ePos.y,
      });
    }
  };

  const onMouseUp = (e) => {
    if (event === "start") {
      setEvent("end");
      ePos.x = 0;
      ePos.y = 0;

      // setDelta({
      //   x: e.x - ePos.x,
      //   y: e.y - ePos.y,
      // });
    }
  };

  const onClear = () => {
    setDelta({
      x: 0,
      y: 0,
    });
  };

  const addListener = () => {
    refDom?.addEventListener("pointerdown", onMouseDown);
    refDom?.addEventListener("pointermove", onMouseMove);
    document.addEventListener("pointerup", onMouseUp);
  };

  const removeListener = () => {
    refDom?.removeEventListener("pointerdown", onMouseDown);
    document.removeEventListener("pointerup", onMouseUp);
  };

  return [event, delta, onClear];
};

export default useDragDom;
