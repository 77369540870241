import React, { useState } from "react";
import {
  Grid,
  Typography,
  IconButton,
  Tooltip,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import CloseFullscreenIcon from "@mui/icons-material/CloseFullscreen";
import Icon from "../../common/Icon";

const SearchBox = (props) => {
  const { search, classes, handleChange } = props;
  return (
    <TextField
      sx={classes.searchBox}
      size="small"
      value={search}
      onChange={handleChange}
    />
  );
};

const PopperHeader = (props) => {
  const {
    title,
    classes,
    onClose,
    needFullscreen,
    fullScreen,
    toggleFullscreen,
    search,
    onSearch,
  } = props;

  const [openSearch, setOpenSearch] = useState(false)

  const handleSearchButton = () => {
    setOpenSearch(!openSearch)
  }

  return (
    <Grid
      container
      padding={2}
      className="headerContainer"
      style={{
        paddingTop: "2px",
      }}
    >
      <Grid
        item
        xs={12}
        style={{
          display: "flex",
          borderBottom: "1px solid #DCE4EC",
        }}
        justifyContent={"space-between"}
        alignItems={"center"}
        className="textSettingHeader"
      >
        <Typography
          color={"primary"}
          sx={{ fontSize: "16px", fontWeight: "700", color: "#000" }}
        >
          {title}
        </Typography>
        <Grid sx={classes.textFormatLabel} justifyContent={"end"}>
          {needFullscreen ? (
            <Grid sx={{position: 'relative'}}>
              <IconButton className={openSearch ? 'searchContainer' : ''} onClick={handleSearchButton}><Icon icon={openSearch ? 'closeIcon' : 'SearchIcon'} /></IconButton>
              {openSearch &&
                <SearchBox
                  classes={classes}
                  search={search}
                  handleChange={onSearch}
                />}
            </Grid>         
          ) : null}
          {needFullscreen ? (
            <Tooltip title="Toggle Fullscreen" arrow>
              <IconButton
                style={{ marginRight: "8px", marginLeft: "8px" }}
                className="close-popupbtn"
                onClick={toggleFullscreen}
              >
                {fullScreen ? <CloseFullscreenIcon /> : <OpenInFullIcon />}
              </IconButton>
            </Tooltip>
          ) : null}
          <Tooltip title="Close" arrow>
            <IconButton
              sx={classes.closeBtn}
              className="close-popupbtn"
              onClick={onClose}
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PopperHeader;
