import React from "react";
import { Box, List, ListItemButton } from "@mui/material";

const More = (props) => {
  const { handleClick } = props;
  return (
    <Box>
      <List className="item-list-wrpr">
        <ListItemButton
          className="item-wrapper"
          onClick={handleClick("addSection")}
        >
          Add Section
        </ListItemButton>
        <ListItemButton
          className="item-wrapper"
          onClick={handleClick("duplicateSection")}
        >
          Duplicate Section
        </ListItemButton>
      </List>
    </Box>
  );
};

export default More;
