import { useState } from "react";
import { Button } from "@mui/material";
import PaintIcon from "../../../assets/svg/PaintIcon";
import ColorPicker from "../../../Elements/Color Picker/ColorPicker";
import { activeMark } from "../../../utils/SlateUtilityFunctions";

function MiniColorPicker(props) {
    const { activeColor, format, classes, id, editor } = props;
    const [openColorTool, setOpenColorTool] = useState(null);

    return (
        <div>
            <Button className="fontColorBtn" onClick={(e) => setOpenColorTool(e.currentTarget)}>
                {format === "color" ? "A" : <PaintIcon />}

                <div className="selectedColor" style={{ background: activeColor }}></div>
            </Button>

            <ColorPicker
                format={format}
                activeMark={activeMark}
                editor={editor}
                showHex={false}
                title="Text Color"
                key={id}
                id={id}
                classes={classes}
                forMiniTool={true}
                openColorTool={openColorTool}
                closeColorTool={() => setOpenColorTool(null)}
            />
        </div>
    )
}

export default MiniColorPicker;