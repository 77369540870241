import { Transforms } from "slate";

export const gridItem = (props = {}) => {
  return {
    type: "grid-item",
    grid: 6,
    children: [
      {
        type: "paragraph",
        children: [{ text: "" }],
      },
    ],
    bgColor: "rgba(255, 255, 255, 0)",
    bannerSpacing: {
      left: 8,
      right: 8,
      top: 8,
      bottom: 8,
    },
    ...(props || {}),
  };
};

export const insertGridItem = (editor) => {
  Transforms.insertNodes(editor, { ...gridItem() });
  Transforms.move(editor);
};
