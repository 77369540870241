import React from "react";
import VariableStyles from "./Style";

const Variables = (props) => {
    const classes = VariableStyles();

    const { attributes, element, children } = props
    const { name } = element;

    return (
        <span {...attributes} contentEditable={false}>
            <span className={`variables-item`} style={classes.variablesItem}>{`{{${name}}}`}</span>
            <span>{children}</span>
        </span>
    );
};

export default Variables;
