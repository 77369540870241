import React from "react";

const CornerHandle = ({ style }) => {
  return (
    <div
      style={{
        width: "8px",
        height: "8px",
        background: "#FFF",
        border: "1px solid #2563EB",
        position: "absolute",
        left: "6px",
        top: "6px",
        ...style,
      }}
    />
  );
};

export default CornerHandle;
