import { Box, SwipeableDrawer } from "@mui/material";
import DrawerStyles from "./style";
import { grey } from "@mui/material/colors";
import { styled } from "@mui/material/styles";
import { useEditorContext } from "../../hooks/useMouseMove";

const Puller = styled("div")(({ theme }) => ({
  width: 30,
  height: 6,
  backgroundColor: theme?.palette?.mode === "light" ? grey[300] : grey[900],
  borderRadius: 3,
  margin: "auto",
  marginTop: "14px",
}));

function SwipeableDrawerComponent({ open, onClose, children, customClass }) {
  const { theme } = useEditorContext();
  const classes = DrawerStyles(theme);

  const handleClose = (e) => {
    e.stopPropagation();
    onClose();
  }
  return (
    <SwipeableDrawer
      disableSwipeToOpen={true}
      anchor="bottom"
      open={open}
      onClose={handleClose}
      sx={classes.drawerContainer}
    >
      <Puller />

      <Box className={customClass} sx={classes.childContainer}>
        {children}
      </Box>
    </SwipeableDrawer>
  );
}

export default SwipeableDrawerComponent;
