import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import StandardImageList from "../../ImageList";

const ChooseAssets = (props) => {
  const { onUploaded, customProps } = props;
  const { readOnly, services } = customProps;
  const [items, setItems] = useState([]);

  useEffect(() => {
    getItems();
  }, []);

  const getItems = async () => {
    if (services) {
      const result = await services("getAssets", {});
      setItems(result);
    }
  };

  const onSelectChange = (img) => {
    onUploaded(img);
  };

  return (
    <Grid item xs={12} className="ims-right">
      <StandardImageList
        itemData={[...items]}
        selected={[]}
        onSelectChange={onSelectChange}
        readOnly={readOnly}
      />
    </Grid>
  );
};

export default ChooseAssets;
