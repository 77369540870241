import React from "react";
import { Grid, Card, CardMedia, CardContent, Box, Button } from "@mui/material";

const PreviewAndSelect = (props) => {
  const { classes, data, onSelectTemplate } = props;
  return (
    <Box
      className="template-card-action"
      component={"div"}
      sx={classes.templateCardBtnGrp}
    >
      <Button className="blueBtn" onClick={onSelectTemplate(data)}>
        Select
      </Button>
      <Button href={data?.previewLink} target="_blank" className="outlineBtn">
        Preview
      </Button>
    </Box>
  );
};

const TemplateCard = (props) => {
  const { classes, m, onSelectTemplate, fullScreen } = props;

  return (
    <Grid
      key={`template_${m.id}`}
      item
      xs={4}
      style={{ minWidth: "150px", minHeight: "200px" }}
    >
      <Card sx={classes.templateCard}>
        <Box sx={classes.templateCardMediaWrpr}>
          <div className="img-loader-wrapper" />
          <CardMedia
            className={`template-card-media ${fullScreen ? "fullscreen" : ""}`}
            component="div"
            image={m?.thumbnail}
            alt={m?.title}
            sx={classes.templateCardMedia}
          />
          <PreviewAndSelect
            classes={classes}
            onSelectTemplate={onSelectTemplate}
            data={m}
          />
        </Box>
        <CardContent sx={classes.templateCardTitle}>{m?.title}</CardContent>
      </Card>
    </Grid>
  );
};

export default TemplateCard;
