import React from "react";
import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

const ImageTexts = (props) => {
  const { value, data, onChange } = props;
  const texts = value || [""];
  const { key } = data;

  const handleChange = (e) => {
    const updatedTexts = texts?.map((m, i) => {
      if (`text-${i}` === e.target.name) {
        return e.target.value;
      }
      return m;
    });
    onChange({
      [key]: updatedTexts,
    });
  };

  const onAddText = () => {
    onChange({
      [key]: [...texts, ""],
    });
  };

  const handleDelete = (deleteIndex) => () => {
    const updatedTexts = texts?.filter((m, i) => {
      return `text=${deleteIndex}` !== i;
    });
    onChange({
      [key]: updatedTexts,
    });
  };

  return (
    <Grid container padding={3} spacing={2} className="input-adorn">
      {texts?.map((m, i) => {
        return (
          <Grid key={`imt_${i}`} item xs={12}>
            <TextField
              fullWidth
              name={`text-${i}`}
              placeholder="Enter Image Text"
              onChange={handleChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleDelete(i)}>
                      <DeleteIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        );
      })}
      <Grid item xs={12}>
        <Button onClick={onAddText}>Add Text</Button>
      </Grid>
    </Grid>
  );
};

export default ImageTexts;
