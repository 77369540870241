import React from "react";
import {
  Grid,
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
  FormControlLabel,
  Typography,
} from "@mui/material";
import {
  TextCenterAlign,
  TextLeftAlign,
  TextRightAlign,
} from "../../iconslist";

const TextAlign = (props) => {
  const { value, data, onChange } = props;
  const { key, label } = data;

  const handleChange = (e) => {
    onChange({
      [key]: e.target.value,
    });
  };

  return (
    <Grid item xs={12}>
      <FormControl>
        <FormLabel id="demo-row-radio-buttons-group-label">
          <Typography
            variant="body1"
            color={"primary"}
            style={{ fontWeight: 500, fontSize: "14px" }}
          >
            {label || "Text Alignment"}
          </Typography>
        </FormLabel>
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="textAlign"
          value={value || "left"}
          onChange={handleChange}
          className="iconRadioButton"
        >
          {/* <FormControlLabel value="left" control={<Radio />} label="Left" /> */}
          <FormControlLabel
            value="left"
            className={`${value === "left" && "active"}`}
            control={<Radio />}
            label={<TextLeftAlign />}
          />
          <FormControlLabel
            value="center"
            className={`${value === "center" && "active"}`}
            control={<Radio />}
            label={<TextCenterAlign />}
          />
          <FormControlLabel
            value="right"
            className={`${value === "right" && "active"}`}
            control={<Radio />}
            label={<TextRightAlign />}
          />
        </RadioGroup>
      </FormControl>
    </Grid>
  );
};

export default TextAlign;
