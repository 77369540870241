import React, { useState } from "react";
import {
  ImageList,
  ImageListItem,
  ImageListItemBar,
  IconButton,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

function srcset(image, size, rows = 1, cols = 1) {
  return {
    src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${size * cols}&h=${
      size * rows
    }&fit=crop&auto=format&dpr=2 2x`,
  };
}
const QuiltedImageList = (props) => {
  const {
    itemData,
    onSelectChange,
    readOnly,
    cols,
    rowHeight,
    uploaderComp: UploaderComp,
  } = props;

  const [selected, setSelected] = useState([]);

  const onImageSelect = (item) => () => {
    setSelected([{ img: item.img }]);
    onSelectChange(item.img);
  };

  return (
    <ImageList
      sx={{ width: "100%", height: "100%" }}
      cols={cols}
      // rowHeight={rowHeight || 121}
      // style={{ marginTop: "0px" }}
    >
      {UploaderComp ? (
        <ImageListItem
          key={`img_upload_btn`}
          className="img_upload_btn_list"
          cols={2}
          rows={2}
          style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
        >
          <UploaderComp />
        </ImageListItem>
      ) : null}
      {(itemData || []).map((item) => {
        const isSelected = (selected || []).find((f) => f.img === item.img);
        return (
          <ImageListItem
            key={item.img}
            // cols={item.cols || 1}
            // rows={item.rows || 1}
            sx={{
              padding: "2px",
              overflow: "hidden", 
              // position: "relative",
            }}
          >
            <img
              {...srcset(item.img, rowHeight || 121, item.rows, item.cols)}
              alt={item.title}
              loading="lazy"
              style={{
                width: "100%", 
                height: "145px", 
                borderRadius: "12px", 
                // display: "block",
              }}
            />
            {!readOnly ? (
              <ImageListItemBar
                sx={{ background: "none" }}
                position="start"
                actionPosition="right"
                actionIcon={
                  <IconButton onClick={onImageSelect(item, !isSelected)}>
                    <CheckCircleIcon
                      style={{ color: isSelected ? "#2563eb" : "#ccc" }}
                    />
                  </IconButton>
                }
              />
            ) : null}
          </ImageListItem>
        );
      })}
    </ImageList>
  );
};

export default QuiltedImageList;
