import { Transforms } from "slate";
import insertNewLine from "./insertNewLine";
import { windowVar } from "./helper";

export const insertButton = (editor) => {
  const button = {
    type: "button",
    children: [{ text: "" }],
    buttonLink: {
      linkType: "webAddress",
    },
    iconPosition: "start",
    bgColor: "#2563EB",
    textColor: "#FFF",
    borderRadius: {
      topLeft: 30,
      topRight: 30,
      bottomLeft: 30,
      bottomRight: 30,
    },
    bannerSpacing: {
      left: 16,
      top: 8,
      right: 16,
      bottom: 8,
    },
    ...(windowVar.lastButtonProps || {})
  };
  Transforms.insertNodes(editor, button);
  Transforms.move(editor);
  insertNewLine(editor);
};
