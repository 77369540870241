import React from "react";
import StyleBuilder from "../../common/StyleBuilder";
import chipTextStyle from "../../common/StyleBuilder/chipTextStyle";

const ChipTextPopup = (props) => {
  const { element, onSave, onClose, onDelete, customProps } = props;
  return (
    <StyleBuilder
      title="Chip Text"
      type="ChipTextPopup"
      element={element}
      onSave={onSave}
      onClose={onClose}
      onDelete={onDelete}
      renderTabs={chipTextStyle}
      customProps={customProps}
    />
  );
};

export default ChipTextPopup;
