import React from "react";

const PageSettings = (props) => {
  const { attributes, children } = props;
  return (
    <div {...attributes} className="page-settings" contentEditable={false}>
      {children}
    </div>
  );
};

export default PageSettings;
