import React, { useEffect, useState, useRef } from "react";
import { 
  Grid, 
  Checkbox, 
  FormControlLabel, 
  FormHelperText,
 } from "@mui/material";

const SelectBox = (props) => {
  const { value, data, onChange, elementProps } = props;
  const { key, placeholder } = data;
  const [checkedValue, setCheckedValue] = useState(false);
  const isFirstRender = useRef(true);

  useEffect(() => {
    if (isFirstRender.current && elementProps.element === "email" && value === undefined) {
      setCheckedValue(true);
      isFirstRender.current = false;
    } else {
      setCheckedValue(value);
    }
  }, [elementProps.element, value]);

  const handleChange = (e) => {
    onChange({ [key]: e.target.checked });
  };

  return (
    <Grid item xs={12} style={{ marginBottom: "12px" }}>
      <FormControlLabel
        control={
          <Checkbox value={value} checked={checkedValue} onChange={handleChange} />
        }
        label={placeholder}
      />
      <FormHelperText>{data?.helperText}</FormHelperText>
    </Grid>
  );
};

export default SelectBox;
