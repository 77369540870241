import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  TextField,
  Button,
  IconButton,
  Typography,
  Checkbox,
  Divider,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import LinkPopupStyles from "./LinkPopupStyles";
import { getEditorTheme } from "../../utils/helper";

const LinkPopup = (props) => {
  const { open, handleClose, linkData, handleInputChange, handleInsertLink } =
    props;

  const themeType = localStorage.getItem("themeType");
  const classes = LinkPopupStyles(themeType);
  const themeStyle = getEditorTheme(themeType);

  return (
    <Dialog
      fullWidth
      open={open}
      className={`dialogComp`}
      sx={classes.dialogRoot}
    >
      <DialogTitle sx={{ padding: "13px 19px 9px 19px" }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Typography
            variant="h6"
            className="popupTitle"
            sx={{ textTransform: "none" }}
          >
            Link
          </Typography>
          <div style={{ display: "flex" }}>
            <IconButton
              onClick={handleClose}
              sx={{ color: themeStyle?.editor?.svgStroke }}
            >
              <CloseIcon />
            </IconButton>
          </div>
        </div>
      </DialogTitle>
      <Divider
        sx={{
          width: "auto", // Or set a fixed width like "90%"
          margin: "0px 20px", // Horizontal margin
        }}
      />
      <DialogContent sx={{ padding: "5px 19px 0px 19px" }}>
        <Grid item xs={12} style={{ paddingTop: "12px" }}>
          <Typography
            sx={{
              fontFamily: "Inter, sans-serif",
              fontSize: "14px",
              marginBottom: "6px",
            }}
          >
            Title
          </Typography>
          <TextField
            size="small"
            fullWidth
            value={linkData?.name}
            name={"name"}
            placeholder="Link Title"
            onChange={handleInputChange}
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: "8px",
              },
              "& .MuiInputBase-input": {
                color: themeType === "dark" ? "#FFFFFF" : "#000",
              },
              "& .MuiInputBase-input::placeholder": {
                color: "#94A3B8",
                fontFamily: '"Inter", sans-serif',
              },
            }}
            // sx={classes.addLinkField}
          />
        </Grid>
        <Grid item xs={12} style={{ paddingTop: "12px" }}>
          <Typography
            sx={{
              fontFamily: "Inter, sans-serif",
              fontSize: "14px",
              marginBottom: "6px",
            }}
          >
            URL
          </Typography>
          <TextField
            size="small"
            fullWidth
            name={"url"}
            value={linkData?.url}
            placeholder="https://google.com"
            onChange={handleInputChange}
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: "8px",
              },
              "& .MuiInputBase-input": {
                color: themeType === "dark" ? "#FFFFFF" : "#000",
              },
              "& .MuiInputBase-input::placeholder": {
                color: "#94A3B8",
                fontFamily: '"Inter", sans-serif',
              },
            }}
            // sx={classes.addLinkField}
          />
        </Grid>
        <Grid item xs={12} sx={{ ml: 1 }}>
          <FormControl>
            <FormControlLabel
              control={
                <Checkbox
                  name={"showInNewTab"}
                  checked={linkData?.showInNewTab}
                  onChange={handleInputChange}
                />
              }
              label={
                <Typography
                  variant="body1"
                  sx={{
                    paddingLeft: "0px",
                    fontSize: "14px",
                    fontFamily: '"Inter", sans-serif',
                  }}
                >
                  Open in new tab
                </Typography>
              }
            />
          </FormControl>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} className="secondaryBtn">
          Cancel
        </Button>
        <Button onClick={handleInsertLink} className="primaryBtn">
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LinkPopup;
