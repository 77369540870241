import React from "react";
import {
  Paper,
  Popper,
  Tooltip,
  Typography,
  Box,
  Divider,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { settingsLabel } from "../settingsConstants";
import SettingsComponents from "../Settings";

const Settings = (props) => {
  const {
    path,
    childType,
    open,
    anchorEl,
    placement,
    onClose,
    editor,
    classes,
    customProps,
    theme,
  } = props;
  const title = settingsLabel[childType] || "Settings";
  const Settings = SettingsComponents[childType];

  return (
    <>
      <Popper
        open={open}
        anchorEl={anchorEl}
        placement={"auto-start"}
        sx={classes.root}
        modifiers={[
          {
            name: "flip",
            enabled: true,
            options: {
              altBoundary: true,
              rootBoundary: "viewport",
            },
          },
          {
            name: "preventOverflow",
            enabled: true,
            options: {
              padding: 8,
            },
          },
        ]}
      >
        <Paper className="papper-root">
          <Typography variant="h6" className="item-wrapper title">
            {title}
            <Box component={"div"}>
              <Tooltip title="Close" arrow>
                <IconButton className="closeBtn" onClick={onClose}>
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            </Box>
          </Typography>
          <Divider />

          <Box component={"div"} className="item-wrapper">
            {Settings ? (
              <Settings
                editor={editor}
                path={path}
                customProps={customProps}
                theme={theme}
              />
            ) : null}
          </Box>
        </Paper>
      </Popper>
    </>
  );
};
Settings.defaultProps = {};
export default Settings;
