const boxStyle = [
  {
    tab: "Colors",
    value: "sectionColors",
    fields: [
      {
        label: "Background Color",
        key: "sectionBgColor",
        type: "color",
      },
    ],
  },
  {
    tab: "Background",
    value: "sectionBackgroundImage",
    fields: [
      {
        label: "Background Image URL",
        key: "sectionBackgroundImage",
        type: "text",
      },
      {
        label: "Background Image",
        key: "sectionBackgroundImage",
        type: "backgroundImage",
      },
    ],
  },
  {
    tab: "Border",
    value: "sectionBorderRadius",
    fields: [
      {
        label: "Border",
        key: "sectionBorderRadius",
        type: "borderRadius",
      },
      {
        label: "Border Color",
        key: "borderColor",
        type: "color",
      },
      {
        label: "Border Width",
        key: "borderWidth",
        type: "text",
        placeholder: "1px",
        width: 6,
      },
      {
        label: "Border Style",
        key: "borderStyle",
        type: "textOptions",
        width: 6,
        options: [
          {
            text: "Solid",
            value: "solid",
          },
          {
            text: "Dotted",
            value: "dotted",
          },
          {
            text: "Dashed",
            value: "dashed",
          },
        ],
        renderOption: (option) => {
          return <span>{option.text}</span>;
        },
      },
    ],
  },
];

export default boxStyle;
