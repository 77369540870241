import React from "react";
import {
    Grid,
    Switch,
    styled,
    FormControlLabel,
    FormHelperText,
} from "@mui/material";


const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
    '&:active': {
        '& .MuiSwitch-thumb': {
            width: 15,
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
            transform: 'translateX(9px)',
        },
    },
    '& .MuiSwitch-switchBase': {
        padding: 2,
        '&.Mui-checked': {
            transform: 'translateX(12px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
            },
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
        width: 12,
        height: 12,
        borderRadius: 6,
        transition: theme.transitions.create(['width'], {
            duration: 200,
        }),
    },
    '& .MuiSwitch-track': {
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor:
            theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
        boxSizing: 'border-box',
    },
}));


const SelectSwitch = (props) => {
    const { value, data, onChange, elementProps } = props;
    const { key, label } = data;

    const handleChange = (e) => {
        if (elementProps?.metadatamapping === data?.value){
            onChange({ [key]: null });
        } else {
            onChange({ [key]: data?.value });
        }
    };

    return (
        <Grid item xs={12} style={{ marginBottom: "12px" }}>
            <FormControlLabel 
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%',
                    marginLeft: 0,
                    '& .MuiFormControlLabel-label': {
                        marginLeft: 0,
                        fontSize: '14px',
                        fontWeight: 700,
                    },
                }}
                labelPlacement = "start"
                label={label}
                control={
                    <AntSwitch inputProps={{ 'aria-label': 'ant design' }} onChange={handleChange} checked={Boolean(value) || false} />
                }
            />
            <FormHelperText>{data?.helperText}</FormHelperText>
        </Grid>
    );
};

export default SelectSwitch;

