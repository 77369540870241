import React from "react";
import { Box, useTheme } from "@mui/material";
import useGuideLineStyle from "./styles";

const GuideLines = (props) => {
  const theme = useTheme();
  const classes = useGuideLineStyle({ theme });
  const { lines } = props;

  const renderGuideLine = ({ x, y, width, height }, index) => {
    return (
      <Box
        sx={classes.root}
        key={`rnd-gl-${index}_${x}_${y}`}
        component={"span"}
        className="rnd-guideline"
        style={{
          left: x,
          top: y,
          width,
          height,
        }}
      />
    );
  };

  return lines?.length ? lines?.map(renderGuideLine) : null;
};

export default GuideLines;
