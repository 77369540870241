import React, { useState } from "react";
import { useSlateStatic } from "slate-react";
import ToolbarIcon from "../../common/ToolbarIcon";
import Icon from "../../common/Icon";
import ImageSelector from "../../common/ImageSelector/ImageSelector";
import { insertAttachments } from "../../utils/attachments";

const AttachmentsButton = (props) => {
  const editor = useSlateStatic();
  const { customProps, icoBtnType } = props;
  const [open, SetOpen] = useState(false);

  const handleClick = () => {
    SetOpen(true);
  };

  const onSelectImage = (url) => {
    insertAttachments(editor, {
      url,
    });
    handleClose();
  };

  const handleClose = () => {
    SetOpen(false);
  };

  return (
    <>
      <ToolbarIcon
        title={"Doc Upload"}
        onClick={handleClick}
        icon={<Icon icon={"docsUpload"} />}
        icoBtnType={icoBtnType}
      />
      <ImageSelector
        open={open}
        onClose={handleClose}
        customProps={customProps}
        onSelectImage={onSelectImage}
        title={"Document"}
      />
    </>
  );
};

export default AttachmentsButton;
