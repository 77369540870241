const embedScriptStyle = [
  {
    tab: "General",
    value: "general",
    fields: [
      {
        label: "Embed Code",
        key: "code",
        type: "text",
      },
    ],
  },
];

export default embedScriptStyle;
