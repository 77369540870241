import React, { useEffect, useRef, useState } from "react";
import { TextField, IconButton } from "@mui/material";
import {
  addMarkData,
  activeMark,
  isBlockActive,
} from "../../utils/SlateUtilityFunctions.js";
import { headingMap, sizeMap } from "../../utils/font.js";
import { TextMinusIcon, TextPlusIcon } from "../../common/iconslist.js";
import { getBreakPointsValue } from "../../helper/theme.js";
import useWindowResize from "../../hooks/useWindowResize.js";
import { BREAKPOINTS_DEVICES } from "../../helper/theme.js";

const TextSize = ({ classes, editor, format, fullWidth }) => {
  const [size] = useWindowResize();
  const val = activeMark(editor, format);
  const value = getBreakPointsValue(val, size?.device);
  const [fontSize, setFontSize] = useState();
  const timerRef = useRef();

  useEffect(() => {
    setFontSize(getSizeVal());
  }, [value]);

  const updateMarkData = (newVal) => {
    let upData = {
      ...getBreakPointsValue(val),
      [size?.device]: `${newVal}px`,
    };

    // if desktop update to all other devices
    // to avoid default normal size
    if (size?.device === "lg") {
      upData = BREAKPOINTS_DEVICES.reduce((a, b) => {
        a[b] = `${newVal}px`;
        return a;
      }, {});
    }

    addMarkData(editor, {
      format,
      value: {
        ...upData,
      },
    });
  };

  const onChangeSize = (value) => {
    if (value) {
      let inc = parseInt(value);
      inc = inc > 200 ? 200 : inc;
      updateMarkData(inc);
    } else {
      setFontSize(null);
    }
  };

  const getSizeVal = () => {
    try {
      let size =
        `${value}`?.indexOf("px") >= 0 ? value : sizeMap[value] || value;

      Object.entries(headingMap).forEach(([format, value]) => {
        if (isBlockActive(editor, format) && isNaN(parseInt(size))) {
          size = value;
        }
      });

      return parseInt(size);
    } catch (err) {
      return "";
    }
  };

  const combinedOldVal = getSizeVal();

  const onIncreaseSize = () => {
    let inc = (combinedOldVal || 0) + 1;
    inc = inc > 200 ? 200 : inc;
    updateMarkData(inc);
  };

  const onDecreaseSize = () => {
    const newVal = combinedOldVal - 1 < 0 ? 0 : combinedOldVal - 1;
    updateMarkData(newVal);
  };

  const onChange = (e) => {
    clearTimeout(timerRef.current);

    const value = e.target.value;

    setFontSize(value);

    timerRef.current = setTimeout(() => {
      onChangeSize(value);
    }, 500);
  };

  return (
    <>
      <TextField
        sx={classes?.textSize}
        value={fontSize}
        onChange={onChange}
        size="small"
        inputProps={{
          style: {
            width: fullWidth ? "100%" : "30px",
            // textAlign: "center",
            height: "19px",
            fontSize: "12px",
          },
        }}
        fullWidth={fullWidth}
        InputProps={{
          style: { borderRadius: "8px" },
          endAdornment: (
            <div className="textFontArrows">
              <IconButton onClick={onIncreaseSize} size="small">
                <TextPlusIcon />
              </IconButton>
              <IconButton onClick={onDecreaseSize} size="small">
                <TextMinusIcon />
              </IconButton>
            </div>
          ),
        }}
      />
    </>
  );
};

TextSize.defaultProps = {
  fullWidth: false,
};

export default TextSize;
