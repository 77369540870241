import { Transforms } from "slate";
import insertNewLine from "./insertNewLine";
import { getSelectedText } from "./helper";

export const insertAccordion = (editor, path) => {
  try {
    const selectedText = getSelectedText(editor);
    const accordion = {
      type: "accordion",
      children: [
        {
          type: "accordion-summary",
          children: [
            { type: "paragraph", children: [{ text: selectedText || "" }] },
          ],
        },
        {
          type: "accordion-details",
          children: [{ type: "paragraph", children: [{ text: "" }] }],
        },
      ],
    };
    const props = path ? { at: path, select: true } : { select: true };
    Transforms.insertNodes(editor, accordion, props);
    const curPath = [...editor?.selection?.anchor?.path];
    const upPath = [...curPath];
    // get title index
    const summaryIndex = upPath.length - 3;
    upPath[summaryIndex] =
      upPath[summaryIndex] === 0 ? 0 : upPath[summaryIndex] - 1;
    // select accordion title by default
    Transforms.select(editor, {
      path: upPath,
      offset: 0,
    });
    insertNewLine(editor);
  } catch (err) {
    console.log(err);
  }
};
