const CheckListStyle = () => ({
    
checkBoxHover: {
    "&:hover": {
        "& svg": {
            "& rect": {
                stroke: "#2563EB"
            }
        }
    }
}
})

export default CheckListStyle