import React from "react";
import StyleBuilder from "../../common/StyleBuilder";
import appHeaderStyle from "../../common/StyleBuilder/appHeaderStyle";

const AppHeaderPopup = (props) => {
  const { element, onSave, onClose, onDelete, customProps } = props;
  return (
    <StyleBuilder
      title="App Header"
      type="gridItemStyle"
      element={element}
      onSave={onSave}
      onClose={onClose}
      onDelete={onDelete}
      renderTabs={appHeaderStyle}
      customProps={customProps}
    />
  );
};

export default AppHeaderPopup;
