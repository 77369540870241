import React from "react";
import { Box, Card, CardMedia, CardContent, Typography } from "@mui/material";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import { formatDate } from "../../utils/helper";
import Icon from "../../common/Icon";
import { getEmbedURL } from "../../helper";
import Video from "../Embed/Video";

const Attachments = (props) => {
  const { attributes, element, children } = props;
  const { url, type, date } = element;
  const { isEmbed } = getEmbedURL(element, true);
  const getLastName = url?.split("/").pop();
  const fileName = `${decodeURIComponent(getLastName)}`;
  return !isEmbed ? (
    <Box
      component={"div"}
      className={"attachment-wrpr-ev2"}
      {...attributes}
      contentEditable={false}
      style={{ display: "block" }}
    >
      <Card
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          padding: "8px",
          boxShadow: "none",
          border: "1px solid #eae9e9",
          backgroundColor: "#F7F7F7",
        }}
      >
        <CardMedia
          sx={{
            "& svg": {
              width: 32,
              height: 32,
              "&.pdf-i": { fill: "#e5252a" },
              "&.doc-i": { fill: "#0263d1" },
            },
          }}
        >
          {type === "pdf" ? (
            <PictureAsPdfIcon className="pdf-i" />
          ) : type === "xls" ? (
            <Icon icon="excelIcon" />
          ) : type === "csv" ? (
            <Icon icon="csvIcon" />
          ) : (
            <TextSnippetIcon className="doc-i" />
          )}
        </CardMedia>
        <CardContent
          component={"a"}
          href={url}
          target="_blank"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "start",
            padding: "8px",
            textDecoration: "none",
            flexDirection: "column",
            color: "#0F172A",
          }}
        >
          <Typography
            style={{ fontWeight: "bold", color: "#0F172A", fontSize: "14px" }}
            component={"div"}
          >
            {fileName}
          </Typography>
          <Typography
            style={{ fontWeight: "normal", color: "#64748b", fontSize: "11px" }}
            component={"div"}
          >
            {formatDate(date)}
          </Typography>
        </CardContent>
      </Card>
      <span contentEditable={false}>{children}</span>
    </Box>
  ) : (
    <Video {...props} />
  );
};

export default Attachments;
