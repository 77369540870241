const FramesStyles = (theme) => ({
    sliderContainer: {
        width: 200,
        padding: "0px 16px",
        boxShadow: "5px 5px 5px 1px rgb(0,0,0,0.07)",
        border: "1px solid rgba(0,0,0,0.07)",
        borderRadius: "23px",
        backgroundColor: theme?.palette?.editor?.background,
        marginBottom: "8px",
        position: "relative",
        paddingRight: "30px",
    },
    sliderText:{
        fontSize: "12px",
        fontWeight: 600,
        padding: "0px 4px",
        color: theme?.palette?.editor?.textColor,
        borderRadius: "22px",
        minWidth: "50px",
        display: "block",
        textAlign: "center",
        border: `1px solid ${theme?.palette?.primary?.border13}`,
        boxShadow: '0px 4px 16px 0px #0000000D'
    }
});

export default FramesStyles;