import React from "react";
import StyleBuilder from "../../common/StyleBuilder";
import pageSettingsStyle from "../../common/StyleBuilder/pageSettingsStyle";

const PageSettingsPopup = (props) => {
  const { element, onSave, onClose, customProps } = props;
  return (
    <StyleBuilder
      title="Page Settings"
      type="pageSettingsStyle"
      element={element}
      onSave={onSave}
      onClose={onClose}
      renderTabs={pageSettingsStyle}
      customProps={customProps}
    />
  );
};

export default PageSettingsPopup;
