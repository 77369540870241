export const uploadFile = async (formData, props) => {
  try {
    const response = await fetch(
      `${props.API_HOST}/general/notes/file_upload`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${props.token}`,
        },
        body: formData,
      }
    );
    const result = await response.json();
    return result.data;
  } catch (err) {
    console.log(err);
    return err;
  }
};
