import { Transforms } from "slate";

export const formField = () => {
  return {
    type: "form-field",
    grid: 6,
    element: "text",
    name: `field_${new Date().getTime()}`,
    key: `field_${new Date().getTime()}`,
    label: `field_${new Date().getTime()}`,
    placeholder: "Placeholder...",
    children: [
      {
        text: "",
      },
    ],
    field_type: "text",
    bgColor: "rgba(255, 255, 255, 1)",
    borderColor: "#ccc",
    bannerSpacing: {
      left: 16,
      right: 16,
      top: 16,
      bottom: 16,
    },
  };
};

export const insertGridItem = (editor) => {
  Transforms.insertNodes(editor, { ...formField() });
  Transforms.move(editor);
};
