import InstaFrame from "./InstaFrame";
import InstaFrameDark from "./InstaFrameDark";
import DarkFrame from "./DarkFrame";
import RoundedDark from "./RoundedDark";
import RoundedLight from "./RoundedLight";
import RoundedLightB2 from "./RoundedLightB2";
import LiteFrame from "./LiteFrame";

const frames = {
  InstaFrame: InstaFrame,
  InstaFrameDark: InstaFrameDark,
  DarkFrame: DarkFrame,
  LiteFrame: LiteFrame,
  RoundedDark: RoundedDark,
  RoundedLight: RoundedLight,
  RoundedLightB2: RoundedLightB2,
};

export default frames;
