import { useState } from "react";
import { getBreakPointsValue, getDevice } from "../../helper/theme";

const useResize = ({ parentDOM, size: allSize, isGrid, onChange }) => {
  const device = getDevice(window.innerWidth);
  const defaultSize = getBreakPointsValue(allSize);
  const { width, height = 370 } = parentDOM?.getBoundingClientRect() || {
    ...defaultSize,
  };
  const [size, setSize] = useState({
    height: isGrid ? height : 300,
    widthInPercent: 100,
    ...defaultSize,
  });
  const [resizing, setResizing] = useState(false);
  let latest = { ...size };

  const onLoad = (defaultSize) => {
    setSize({
      widthInPercent: 100,
      height: height,
      ...defaultSize[device],
    });
  };

  const onMouseDown = () => {
    document.addEventListener("pointermove", onMouseMove);
    document.addEventListener("pointerup", onMouseUp);
    setResizing(true);
  };
  const onMouseUp = () => {
    document.removeEventListener("pointermove", onMouseMove);
    document.removeEventListener("pointerup", onMouseUp);
    if (onChange) {
      onChange({
        ...getBreakPointsValue(allSize),
        [device]: latest,
      });
    }
    setResizing(false);
  };

  const onMouseMove = (e) => {
    setSize((currentSize) => {
      const calcWidth = (currentSize.width || width) + e.movementX;
      const cWP = (calcWidth / width) * 100;
      const calcHeight = (parseInt(currentSize.height) || height) + e.movementY;

      const calc = {
        width: Math.max(calcWidth, 140),
        height: Math.max(calcHeight, 50),
        widthInPercent: cWP > 100 ? 100 : Math.max(cWP, 15),
      };

      latest = calc;
      return calc;
    });
  };

  return [size, onMouseDown, resizing, onLoad];
};

export default useResize;
