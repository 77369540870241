import React, { useEffect, useState } from "react";
import analyticsUtil from "./analytics";
let DURATION_TIMEOUT = null;
const DURATION_THRESHOLD = 10000;

const Analytics = () => {
  const [startTime] = useState(new Date());
  const [duration, setDuration] = useState(0);
  useEffect(() => {
    if (window.siteKey) {
      analyticsUtil?.page();
      startDuration();
      document.addEventListener("click", addClickTrack);
      return () => {
        clearTimeout(DURATION_TIMEOUT);
        document.removeEventListener("click", addClickTrack);
      };
    }
  }, []);

  useEffect(() => {
    if (duration > 0 && window.siteKey) {
      addDurationTrack();
    }
  }, [duration]);

  const startDuration = () => {
    if (startTime) {
      clearTimeout(DURATION_TIMEOUT);
      DURATION_TIMEOUT = setTimeout(() => {
        const difference = new Date() - startTime;
        const minutes = (difference % (1000 * 60 * 60)) / (1000 * 60);
        setDuration(minutes);
        startDuration();
      }, DURATION_THRESHOLD);
    }
  };

  const addClickTrack = (e) => {
    if (analyticsUtil && e && e?.target) {
      if (e?.target?.tagName === "A" || e?.target?.tagName === "BUTTON") {
        analyticsUtil?.track("buttonClick", {
          apiType: "track",
          event_type: "buttonClick",
          event_props: JSON.stringify({
            tagName: e?.target?.tagName,
            href: e?.target?.getAttribute("href"),
            text: e?.target?.textContent || "",
          }),
          url: window.location.origin + "/",
          path: window.location.pathname,
          hash: window.location.hash,
        });
      }
    }
  };

  const addDurationTrack = () => {
    if (analyticsUtil) {
      analyticsUtil?.track("duration", {
        apiType: "duration",
        duration: duration,
        url: window.location.origin + "/",
        path: window.location.pathname,
        hash: window.location.hash,
      });
    }
  };

  return <div id="flozy-analytics-wrapper" style={{ display: "none" }} />;
};

export default Analytics;
