import { Transforms, Path, Node } from "slate";
const AvoidCopying = ["headingOne", "headingTwo", "headingThree", "blockquote"];

const withEmbeds = (editor) => {
  const { isVoid, insertBreak } = editor;

  editor.isVoid = (element) => {
    if (["video", "htmlCode"].includes(element.type)) {
      return true;
    } else if (element.type === "image") {
      return true;
    } else {
      return isVoid(element);
    }
  };

  editor.insertBreak = (...args) => {
    const parentPath = Path.parent(editor.selection.focus.path);
    const parentNode = Node.get(editor, parentPath);
    if (editor.isVoid(parentNode)) {
      const nextPath = Path.next(parentPath);
      Transforms.insertNodes(
        editor,
        {
          type: "paragraph",
          children: [{ text: "" }],
        },
        {
          at: nextPath,
          select: true, // Focus on this node once inserted
        }
      );
    } else if (AvoidCopying.indexOf(parentNode?.type) >= 0) {
      const nextPath = Path.next(parentPath);
      Transforms.insertNodes(
        editor,
        {
          type: "paragraph",
          children: [{ text: "" }],
        },
        {
          at: nextPath,
          select: true, // Focus on this node once inserted
        }
      );
    } else {
      insertBreak(...args);
    }
  };
  return editor;
};

export default withEmbeds;
