import React, { useEffect, useState } from "react";
import { Node, Transforms, Editor } from "slate";
import { ReactEditor } from "slate-react";
import LinkSettings from "../../../LinkSettings";
import { insertLink, removeLink } from "../../../../utils/link";
import {
  getBlockActive,
  isBlockActive,
  upateBlockActive,
} from "../../../../utils/SlateUtilityFunctions";

const Link = (props) => {
  const { onClose, customProps, editor, path, childType, theme } = props;
  const pPath = path?.split("|")?.map((m) => parseInt(m));
  const element_path = [...pPath, 0];
  const element = Node.get(editor, element_path);
  const { actionTrigger } = element?.buttonLink || {};
  const { onClick } = actionTrigger || { options: [] };
  const [blockProps, setBlockProps] = useState();
  const [isActiveTextLink, setIsActiveTextLink] = useState(false);

  useEffect(() => {
    if (childType === "text") {
      selectTextNode();
      const p = getBlockActive(editor, "link")?.props || {};
      const bp = {
        name: Editor.string(editor, editor.selection),
        navType: "webAddress",
        navValue: p?.href,
        openInNewTab: p?.target === "_blank",
      };
      setIsActiveTextLink(isBlockActive(editor, "link"));
      setBlockProps(bp);
    } else {
      setBlockProps(getProps());
    }
  }, []);

  const selectTextNode = () => {
    try {
      ReactEditor.focus(editor);
      const range = Editor.range(editor, element_path);
      Transforms.select(editor, range);
    } catch (err) {
      console.log(err);
    }
  };

  const getProps = () => {
    switch (childType) {
      case "button":
        return {
          navType: element?.buttonLink?.linkType,
          navValue: element?.url,
          openInNewTab: element?.openInNewTab,
        };
      case "image":
        return {
          navType: element?.linkType,
          navValue: element?.webAddress,
          openInNewTab: element?.isNewTab,
        };
      default:
        return {};
    }
  };

  const getTransformedData = (d) => {
    switch (childType) {
      case "text":
        selectTextNode();
        return {
          name: Editor.string(editor, editor.selection),
          url: d?.navValue,
          showInNewTab: d?.openInNewTab,
        };
      case "image":
        return {
          linkType: d?.linkType,
          webAddress: d?.navValue,
          isNewTab: d?.openInNewTab,
        };
      case "button":
        return {
          buttonLink: { linkType: d?.linkType, onClick },
          url: d?.navValue,
          openInNewTab: d?.openInNewTab,
        };
      default:
        return {};
    }
  };

  const updateTextLink = (data) => {
    // remove link
    if (!data?.url && isActiveTextLink) {
      removeLink(editor);
      return;
    }
    const upData = isActiveTextLink
      ? {
          href: data?.url,
          target: data?.showInNewTab ? "_blank" : "_self",
          name: data?.name,
        }
      : data;
    if (isActiveTextLink) {
      // update text link
      upateBlockActive(editor, "link", upData);
    } else {
      insertLink(editor, upData);
    }
  };

  const onSave = (data) => {
    try {
      const updateData = { ...data };
      delete updateData.children;

      // for text we have defined method already
      // to update leaf data
      if (childType === "text") {
        updateTextLink(data);
        return;
      }

      Transforms.setNodes(
        editor,
        {
          ...updateData,
        },
        {
          at: element_path,
        }
      );
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <LinkSettings
      handleClose={onClose}
      onSave={(d) => {
        const upData = getTransformedData(d);
        onSave({
          ...upData,
        });
        onClose();
      }}
      {...(blockProps || {})}
      customProps={customProps}
      theme={theme}
    />
  );
};

export default Link;
