const styles = (theme) => ({
  SttContainer: {
    display: "flex",
    width: "100%",
    padding: "12px",
    zIndex: 1,
  },
  STTInput: {
    width: "100%",
    borderRadius: "8px",

    "&& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#2563EB",
    },

    "&& .MuiOutlinedInput-root": {
      background: theme?.palette?.editor?.aiInputBackground,
    },
  },
  AudioVizualizerContainer: {
    width: "100%",
    display: "flex",
    background: theme?.palette?.editor?.aiInputBackground,
    borderRadius: "8px",
    border: "1px solid #2563EB",
    alignItems: "center",
    flexWrap: "wrap",

    "& .ai-icon": {
      "& circle": {
        fill: theme?.palette?.editor?.aiInputBackground,
      },
      "& rect": {
        fill: theme?.palette?.editor?.aiInputBackground,
      },
    },
  },
  AudioVizualizerContent: {
    display: "flex",
    height: "50px",
    width: "100%",
  },
  AudioVisualiser: {
    flexGrow: "1",
    height: "100%",
  },
  TranscriptionContainer: {
    padding: "12px",
    color: theme?.palette?.editor?.textColor,
  },
});

export default styles;
