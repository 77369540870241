import React from "react";
import { IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { Transforms } from "slate";
import { ReactEditor, useSlateStatic } from "slate-react";

const ImageText = (props) => {
  const { attributes, element, children } = props;
  const editor = useSlateStatic();

  const onDelete = () => {
    const path = ReactEditor.findPath(editor, element);
    Transforms.removeNodes(editor, { at: [...path] });
  };

  return (
    <div
      className="image-text"
      {...attributes}
      style={{ position: "relative" }}
    >
      {children}
      <div>
        <IconButton
          onClick={onDelete}
          style={{
            background: "#FFF",
            position: "absolute",
            right: "0px",
            top: "0px",
          }}
        >
          <DeleteIcon />
        </IconButton>
      </div>
    </div>
  );
};

export default ImageText;
