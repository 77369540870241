import React from "react";
import {
  Box,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import Icon from "../../../common/Icon";

const FREE_GRID_ELEMENTS = [
  {
    actionType: "addText",
    label: "Text",
    icon: "text",
  },
  {
    actionType: "addButton",
    label: "Button",
    icon: "button",
  },
  {
    actionType: "addImage",
    label: "Image",
    icon: "image",
  },
  {
    actionType: "addVideo",
    label: "Embed or Video",
    icon: "embed",
  },
  {
    actionType: "addBox",
    label: "Box",
    icon: "colorbox",
  },
  {
    actionType: "addTable",
    label: "Table",
    icon: "table",
  },
  {
    actionType: "addCode",
    label: "Code",
    icon: "embedScript",
  },
  {
    actionType: "addForm",
    label: "Form",
    icon: "form",
  },
  {
    actionType: "addAppHeader",
    label: "App Header",
    icon: "appHeader",
  },
];

const AddElement = (props) => {
  const { handleClick } = props;
  return (
    <Box>
      <List className="item-list-wrpr">
        {FREE_GRID_ELEMENTS.map((m) => {
          return (
            <ListItemButton
              key={m.actionType}
              className="item-wrapper"
              onClick={handleClick(m.actionType)}
            >
              <ListItemIcon>
                <Icon icon={m.icon} />
              </ListItemIcon>
              <ListItemText>{m.label}</ListItemText>
            </ListItemButton>
          );
        })}
      </List>
    </Box>
  );
};

export default AddElement;
