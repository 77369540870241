import CMDIcon from "@mui/icons-material/KeyboardCommandKey";

const CMenus = {
  child: [
    {
      name: "bringForward",
      label: "Move to Front",
      autoClose: false,
      render: () => {
        return (
          <>
            Move to Front{" "}
            <span className="shortcut-help-info">
              <CMDIcon /> &uarr;
            </span>
          </>
        );
      },
    },
    {
      name: "bringBackward",
      label: "Move to Back",
      autoClose: false,
      render: () => {
        return (
          <>
            Move to Back{" "}
            <span className="shortcut-help-info">
              <CMDIcon /> &darr;
            </span>
          </>
        );
      },
    },
    {
      name: "cut",
      label: "Cut",
      render: () => {
        return (
          <>
            Cut{" "}
            <span className="shortcut-help-info">
              <CMDIcon /> X
            </span>
          </>
        );
      },
    },
    {
      name: "copy",
      label: "Copy",
      render: () => {
        return (
          <>
            Copy{" "}
            <span className="shortcut-help-info">
              <CMDIcon /> C
            </span>
          </>
        );
      },
    },
    {
      name: "paste",
      label: "Paste",
      render: () => {
        return (
          <>
            Paste{" "}
            <span className="shortcut-help-info">
              <CMDIcon /> V
            </span>
          </>
        );
      },
    },
    {
      name: "delete",
      label: "Delete",
    },
  ],
  parent: [
    {
      name: "cut",
      label: "Cut",
      render: () => {
        return (
          <>
            Cut{" "}
            <span className="shortcut-help-info">
              <CMDIcon /> X
            </span>
          </>
        );
      },
    },
    {
      name: "copy",
      label: "Copy",
      render: () => {
        return (
          <>
            Copy{" "}
            <span className="shortcut-help-info">
              <CMDIcon /> C
            </span>
          </>
        );
      },
    },
    {
      name: "paste",
      label: "Paste",
      render: () => {
        return (
          <>
            Paste{" "}
            <span className="shortcut-help-info">
              <CMDIcon /> V
            </span>
          </>
        );
      },
    },
    {
      name: "delete",
      label: "Delete",
    },
  ],
  "parent-container": [
    {
      name: "cut",
      label: "Cut",
      render: () => {
        return (
          <>
            Cut{" "}
            <span className="shortcut-help-info">
              <CMDIcon /> X
            </span>
          </>
        );
      },
    },
    {
      name: "copy",
      label: "Copy",
      render: () => {
        return (
          <>
            Copy{" "}
            <span className="shortcut-help-info">
              <CMDIcon /> C
            </span>
          </>
        );
      },
    },
    {
      name: "paste",
      label: "Paste",
      render: () => {
        return (
          <>
            Paste{" "}
            <span className="shortcut-help-info">
              <CMDIcon /> V
            </span>
          </>
        );
      },
    },
    {
      name: "delete",
      label: "Delete",
    },
  ],
};

export default CMenus;
