const UploadStyles = (theme) => ({
  uploadContainer: {
    border: "1px solid #2563EB",
    padding: "10px",
    borderRadius: "7px",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.16)",
  },
  uploadField: {
    width: "100%",
    backgroundSize: "100% auto",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    height: "250px",
    position: "relative",
    backgroundColor: theme?.palette?.editor?.background,
    borderRadius: "9px",
    border: "1px dashed #2563EB",
  },
  uploadIcon: {
    "& svg": {
      "& path": {
        stroke: "#2563EB",
      },
    },
  },
});

export default UploadStyles;
