import React from "react";
import { insertAccordion } from "../../utils/accordion";
import ToolbarIcon from "../../common/ToolbarIcon";
import Icon from "../../common/Icon";

const AccordionButton = (props) => {
  const { editor, icoBtnType } = props;
  const handleInsertAccordion = () => {
    insertAccordion(editor);
  };
  return (
    <ToolbarIcon
      icon={<Icon icon="accordion" />}
      title="Accordion"
      onClick={handleInsertAccordion}
      style={{ marginLeft: "0px" }}
      icoBtnType={icoBtnType}
    />
  );
};

export default AccordionButton;
