import React, { useEffect, useRef } from "react";
import sanitizeHtml from "sanitize-html";
import { allowedDomains, decodeString } from "../../utils/helper";
import { IconButton, Tooltip } from "@mui/material";
import { DeleteIcon } from "../../assets/svg/AIIcons";
import { ReactEditor, useSlateStatic } from "slate-react";
import { Transforms } from "slate";

// const sanitize = (input) => {
//   const doc = new DOMParser().parseFromString(input, "text/html");
//   for (const elm of doc.querySelectorAll("*")) {
//     for (const attrib of elm.attributes) {
//       if (attrib.name.startsWith("on")) {
//         elm.removeAttribute(attrib.name);
//       }
//     }
//   }
//   return doc.body.innerHTML;
// };

const getCode = (embedData, isEncoded) => {
  if (isEncoded) {
    return decodeString(embedData);
  }

  return embedData;
};

const ToolBar = ({ onDelete }) => {
  return (
    <div className="element-toolbar visible-on-hover" contentEditable={false}>
      <Tooltip title="Delete" arrow>
        <IconButton onClick={onDelete}>
          <DeleteIcon />
        </IconButton>
      </Tooltip>
    </div>
  );
};

const Code = (props) => {
  const codeRef = useRef();
  const { element, attributes, children, customProps } = props;
  const { embedData, isEncoded, isSanitized } = element;
  const { readOnly } = customProps;
  const editor = useSlateStatic();
  const path = ReactEditor.findPath(editor, element);

  const onDelete = () => {
    Transforms.removeNodes(editor, { at: path });
  };

  useEffect(() => {
    if (codeRef?.current) {
      const code = getCode(embedData, isEncoded);

      const clean = isSanitized
        ? code
        : sanitizeHtml(code, {
            allowedTags: false, // Allow all tags
            allowedAttributes: false, // Allow all attributes
            allowedScriptDomains: allowedDomains, // for old code's that are already inserted without sanitization
          });
      if (clean) {
        const slotHtml = document.createRange().createContextualFragment(clean); // Create a 'tiny' document and parse the html string
        codeRef.current.innerHTML = ""; // Clear the container
        codeRef.current.appendChild(slotHtml);
      }
    }
  }, [embedData]);

  return (
    <div
      className="embed-code has-hover"
      contentEditable={false}
      {...attributes}
      key={element?.updatedOn}
    >
      <div ref={codeRef} />
      {children}
      {!readOnly && <ToolBar onDelete={onDelete} />}
    </div>
  );
};

export default Code;
