export const actionButtonRedirect = async (formData, props) => {
  try {
    const response = await fetch(`${props.url}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    });
    const result = await response.json();
    return result.data;
  } catch (err) {
    console.log(err);
    return err;
  }
};
