import React from "react";
import Icon from "../../common/Icon";
import Button from "../../common/Button";
import {
  toggleBlock,
  isBlockActive,
  isMarkActive,
  toggleMark,
} from "../../utils/SlateUtilityFunctions.js";
const MARK_TYPES = ["doublequote"];

const BlockButton = (props) => {
  const { editor, format, title } = props;
  const isMark = MARK_TYPES?.indexOf(format) >= 0;
  const isActive = isMark
    ? isMarkActive(editor, format)
    : isBlockActive(editor, format);
  return (
    <Button
      active={isActive}
      format={format}
      onMouseDown={(e) => {
        e.preventDefault();
        if (isMark) {
          toggleMark(editor, format);
        } else {
          toggleBlock(editor, format);
        }
      }}
      title={title}
    >
      <Icon icon={format} />
    </Button>
  );
};

export default BlockButton;
