import Box from "@mui/material/Box";
import { useEditorContext } from "../../hooks/useMouseMove";
import { useEffect } from "react";

const RnDCopy = (props) => {
  const { readOnly } = props;
  const { selectedElement } = useEditorContext();

  useEffect(() => {
    if (selectedElement?.path) {
      window.selectedRnDElement = selectedElement;
    }
  }, [selectedElement?.path, selectedElement?.enable]);

  return !readOnly ? <Box sx={{ display: "none" }} /> : null;
};

export default RnDCopy;
