import React from "react";
import { insertButton } from "../../utils/button";
import ToolbarIcon from "../../common/ToolbarIcon";
import Icon from "../../common/Icon";

const ButtonToolIcon = (props) => {
  const { editor, icoBtnType } = props;
  const handleInsertButton = () => {
    insertButton(editor);
  };

  return (
    <ToolbarIcon
      title="Button"
      onClick={handleInsertButton}
      icon={<Icon icon="button" />}
      icoBtnType={icoBtnType}
    />
  );
};

export default ButtonToolIcon;
