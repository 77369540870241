import { useEffect, useState } from "react";
import { getDevice } from "../helper/theme";

const useWindowResize = () => {
  const [size, setSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
    device: getDevice(window.innerWidth),
  });

  const onResize = () => {
    setSize({
      width: window.innerWidth,
      height: window.innerHeight,
      device: getDevice(window.innerWidth),
    });
  };

  useEffect(() => {
    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  return [size];
};

export default useWindowResize;
