const gridItemStyle = [
  {
    tab: "Colors",
    value: "colors",
    fields: [
      {
        label: "Background Color",
        key: "bgColor",
        type: "color",
      },
      {
        label: "Border Color",
        key: "borderColor",
        type: "color",
      },
    ],
  },
  {
    tab: "Position",
    value: "position",
    fields: [
      {
        label: "Hide on Mobile",
        key: "xsHidden",
        type: "selectBox",
        placeholder: "Hide on Mobile",
      },
      {
        label: "Set Postion (Vertical & Horizantal)",
        key: "alignment",
        type: "alignment",
      },
    ],
  },
  {
    tab: "Margin",
    value: "margin",
    fields: [
      {
        label: "Margin",
        key: "margin",
        type: "bannerSpacing",
      },
    ],
  },
  {
    tab: "Padding",
    value: "bannerSpacing",
    fields: [
      {
        label: "Banner Spacing",
        key: "bannerSpacing",
        type: "bannerSpacing",
      },
    ],
  },
  {
    tab: "Border",
    value: "border",
    fields: [
      {
        label: "Border Radius",
        key: "borderRadius",
        type: "borderRadius",
      },
      {
        label: "Border Width",
        key: "borderWidth",
        type: "text",
        placeholder: "1px",
      },
      {
        label: "Border Style",
        key: "borderStyle",
        type: "textOptions",
        options: [
          {
            text: "Solid",
            value: "solid",
          },
          {
            text: "Dotted",
            value: "dotted",
          },
          {
            text: "Dashed",
            value: "dashed",
          },
        ],
        renderOption: (option) => {
          return <span>{option.text}</span>;
        },
      },
    ],
  },
  {
    tab: "Size",
    value: "gridSize",
    fields: [
      {
        label: "Width Size",
        key: "grid",
        type: "gridSize",
      },
      {
        label: "Height",
        key: "cellGHeight",
        type: "textOptions",
        isBreakpoint: true,
        options: [
          {
            text: "Auto",
            value: "auto",
          },
          {
            text: "Full",
            value: "100%",
          },
        ],
        renderOption: (option) => {
          return <span>{option.text}</span>;
        },
      },
    ],
  },
  {
    tab: "Animation",
    value: "animation",
    fields: [
      {
        label: "Animation",
        key: "animation",
        type: "textOptions",
        options: [
          {
            text: "No Animation",
            value: "No Animation",
          },
          {
            text: "Fade In",
            value: "animate__animated animate__fadeIn",
          },
          {
            text: "Zoom In",
            value: "animate__animated animate__zoomIn",
          },
          {
            text: "Bounce In Left",
            value: "animate__animated animate__bounceInLeft",
          },
        ],
        renderOption: (option) => {
          return <span>{option.text}</span>;
        },
      },
    ],
  },
  {
    tab: "Hover Colors",
    value: "hoverColors",
    fields: [
      {
        label: "Hover Background Color",
        key: "bgColorHover",
        type: "color",
      },
      {
        label: "Hover Border Color",
        key: "borderColorHover",
        type: "color",
      },
    ],
  },
];

export default gridItemStyle;
