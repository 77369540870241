export const onPageLoaded = async (payload) => {
  try {
    const response = await fetch(`/analytics/v1/logs`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${window.siteKey}`,
      },
      body: JSON.stringify(payload),
    });
    const result = await response.json();
    return result.data;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const onTrack = async (payload) => {
  try {
    const { properties } = payload;
    const { apiType } = properties;
    const response = await fetch(`/analytics/v1/${apiType}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${window.siteKey}`,
      },
      body: JSON.stringify(payload),
    });
    const result = await response.json();
    return result.data;
  } catch (err) {
    console.log(err);
    return err;
  }
};
