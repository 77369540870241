import React from "react";
import ToolbarIcon from "../../common/ToolbarIcon";
import Icon from "../../common/Icon";
import { toggleBlock } from "../../utils/SlateUtilityFunctions";
import { useSlateStatic } from "slate-react";
import insertNewLine from "../../utils/insertNewLine";

const ColorboxButton = (props) => {
  const editor = useSlateStatic();
  const { icoBtnType } = props;

  const onClick = () => {
    toggleBlock(editor, "blockquote", false, {
      bgColor: "rgb(227, 236, 255)",
    });
    insertNewLine(editor);
  };

  return (
    <>
      <ToolbarIcon
        title="Colorbox"
        onClick={onClick}
        icon={<Icon icon="colorbox" />}
        icoBtnType={icoBtnType}
      />
    </>
  );
};

export default ColorboxButton;
