import React, { useState } from "react";
import Stack from "@mui/material/Stack";
import Slider from "@mui/material/Slider";
import { Typography } from "@mui/material";

const ContinuousSlider = (props) => {
  const { classes, val, min, max, onChange } = props;
  const [value, setValue] = useState(val || 100);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    onChange(newValue);
  };

  return (
    <Stack spacing={2} direction="row" sx={{ mb: 0, ml: '8px' }} alignItems="center">
      <Slider
        aria-label="Volume"
        value={value}
        onChange={handleChange}
        min={min}
        step={1}
        max={max}
        sx={{
          height: 6,
          '& .MuiSlider-thumb': {
            width: 16,
            height: 16,
            background: 'linear-gradient(180deg, #4D61FF 0%, #2C92FF 100%)'
          },
          '& .MuiSlider-thumb::after': {
            position: 'absolute',
            content: '""',
            borderRadius: '50%',
            width: 18,
            height: 18,
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            border: '2px solid #fff',
            boxShadow: '0px 4px 4px 0px #0000001A'
          },
          '& .MuiSlider-track': {
            border: '1px solid #2563EB',
            background: '#2563EB',
          }
        }}
      />
      <Typography sx={classes?.sliderText}
        variant="div"
      >
        {value}%
      </Typography>
    </Stack>
  );
};

export default ContinuousSlider;
