import React from "react";
import CommonEditor from "./CommonEditor";
import useWindowMessage from "./hooks/useWindowMessage";

const IframeEditor = () => {
  const [message, sendMessage] = useWindowMessage({ type: "editorProps" });

  const onSave = (a, b) => {
    sendMessage({
      type: "Editor:onSave",
      payload: { a, b },
    });
  };

  if (message?.id && message?.content_status) {
    return <CommonEditor {...message} isIframe={true} onSave={onSave} />;
  } else {
    return <div>Waiting to receive data</div>;
  }
};

export default IframeEditor;
