import { useEffect, useRef, useState } from "react";
import { IconButton, Popper } from "@mui/material";
import Box from "@mui/material/Box";
import ContinuousSlider from "./Slider";
import { useEditorContext } from "../../../hooks/useMouseMove";
import { CloseIcon } from "../../../common/iconslist";
import useDragDom from "../../../hooks/useDragDom";
import FramesStyles from "./Styles";
import frames from ".";

const ImageFrame = (props) => {
  const { frame, id, framePos, onChange, readOnly, handleImageClick } = props;
  const svgRef = useRef();
  const [dom, setDOM] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const imageRef = useRef(null);
  const [event, delta, clear] = useDragDom({
    refDom: imageRef?.current,
    readOnly,
  });
  const { x, y } = delta || { x: 0, y: 0 };
  const { x: lx, y: ly, scale } = framePos || { x: 0, y: 0, scale: 100 };
  const calX = lx + x;
  const calY = ly + y;
  const SVGFrame = frame ? frames[frame] : null;
  const {
    selectedPath,
    setSelectedPath,
    selectedElement,
    theme,
    setSelectedElement,
  } = useEditorContext();
  const freeGridItemPath = `${selectedElement?.path?.split("|")?.join(",")},0`;
  const isFreegridSelection = freeGridItemPath === id;
  const open =
    (selectedPath === id || isFreegridSelection) && Boolean(anchorEl);
  const classes = FramesStyles(theme);

  useEffect(() => {
    if (imageRef?.current && !readOnly) {
      setDOM(dom);
    }
  }, [imageRef]);

  useEffect(() => {
    if (svgRef?.current && selectedElement?.selectedAction === "imageFrame") {
      const imageFrameDom = document.getElementById(
        `opt_ref_${selectedElement?.path}`
      );
      setAnchorEl(imageFrameDom);
    } else {
      setAnchorEl(null);
    }
  }, [isFreegridSelection, svgRef?.current, selectedElement?.selectedAction]);

  useEffect(() => {
    if (event === "end" && !readOnly) {
      onChange({ framePos: { x: calX, y: calY, scale } });
      clear();
    }
  }, [event]);

  const handleClick = () => {
    if (!readOnly) {
      setSelectedPath(id);
      setAnchorEl(svgRef?.current);
    } else {
      handleImageClick();
    }
  };

  const onClose = () => {
    setAnchorEl(null);
  };

  const onScaleChange = (newVal) => {
    if (!readOnly) {
      onChange({ framePos: { x: calX, y: calY, scale: newVal } });
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedPath(null);
    setSelectedElement({
      ...selectedElement,
      enable: 1,
      selectedAction: null,
    });
  };

  return (
    <>
      <SVGFrame
        ref={svgRef}
        {...props}
        handleClick={handleClick}
        imagePos={{
          calX,
          calY,
          scale,
        }}
        imageRef={imageRef}
        defaultScale={100}
      />
      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={onClose}
        sx={{
          zIndex: 100,
        }}
        placement={isFreegridSelection ? "bottom" : "top"}
        disablePortal={false}
      >
        <Box sx={classes?.sliderContainer}>
          <ContinuousSlider
            classes={classes}
            val={scale}
            onChange={onScaleChange}
            min={50}
            max={200}
          />
          <IconButton
            onClick={handleClose}
            sx={{ position: "absolute", right: 0, top: "-2px" }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </Popper>
    </>
  );
};

export default ImageFrame;
