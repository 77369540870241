import React from "react";
import { insertAppHeader } from "../../utils/insertAppHeader";
import ToolbarIcon from "../../common/ToolbarIcon";
import Icon from "../../common/Icon";
import insertNewLine from "../../utils/insertNewLine";

const AppHeaderButton = (props) => {
  const { editor, icoBtnType } = props;
  const handleClick = () => {
    insertAppHeader(editor, {});
    insertNewLine(editor);
  };
  return (
    <ToolbarIcon
      title="App Header"
      onClick={handleClick}
      icon={<Icon icon="appHeader" />}
      icoBtnType={icoBtnType}
    />
  );
};

export default AppHeaderButton;
