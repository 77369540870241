import Analytics from "analytics";
import ct from "countries-and-timezones";
import { onPageLoaded, onTrack } from "./service";

const customIOPlugin = () => {
  // return object for analytics to use
  return {
    /* All plugins require a name */
    name: "flozy-custom-analytics-plugin",
    /* Everything else below this is optional depending on your plugin requirements */
    config: {},
    initialize: () => {},
    page: ({ payload }) => {
      // call provider specific page tracking
      payload.timeZoneOffset = new Date().getTimezoneOffset();
      try {
        const timeZone = ct.getTimezone(
          Intl.DateTimeFormat().resolvedOptions().timeZone
        );
        payload.timeZone = timeZone?.name || null;
        payload.country = ct.getCountry(timeZone?.countries[0])?.name || null;
        onPageLoaded(payload);
      } catch (err) {
        payload.country = null;
        payload.err = "ERR:100";
      }
    },
    track: ({ payload }) => {
      onTrack(payload);
    },
    identify: () => {},
    loaded: () => {
      return true;
    },
  };
};

const analytics = Analytics({
  app: `flozy-analytics`,
  version: 100,
  plugins: [customIOPlugin({})],
});

export default analytics;
