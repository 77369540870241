const withErrorHandling = (editor) => {
  const { apply } = editor;

  editor.apply = (operation) => {
    try {
      apply(operation);
    } catch (error) {
      console.error("Error applying Slate operation:", error);
    }
  };

  return editor;
};

export default withErrorHandling;
