import { useEffect, useState } from "react";

const useDrag = () => {
  const [event, setEvent] = useState("");
  useEffect(() => {
    addListener();
    return () => {
      removeListener();
    };
  }, []);

  const onMouseDown = () => {
    setEvent("start");
  };

  const onMouseUp = () => {
    setEvent("end");
  };

  const addListener = () => {
    document.addEventListener("pointerdown", onMouseDown);
    document.addEventListener("pointerup", onMouseUp);
  };

  const removeListener = () => {
    document.removeEventListener("pointerdown", onMouseDown);
    document.removeEventListener("pointerup", onMouseUp);
  };

  return [event];
};

export default useDrag;
