import CornerHandle from "./CornerHandle";
import SizeHandle from "./SizeHandle";

const Handles = ({ type }) => {
  return {
    top: <SizeHandle style={{ left: 0, right: 0 }} />,
    bottom: (
      <SizeHandle
        className={`bottom-handle ${type}`}
        style={{ left: 0, right: 0, bottom: 0 }}
      />
    ),
    right: (
      <SizeHandle
        style={{ top: 0, right: "0px", bottom: 0, width: "8px", height: "8px" }}
      />
    ),
    left: (
      <SizeHandle
        style={{ top: 0, left: "0px", bottom: 0, width: "8px", height: "8px" }}
      />
    ),
    topLeft: (
      <CornerHandle style={{ left: "6px", top: "6px", display: "none" }} />
    ),
    topRight: <CornerHandle style={{ right: "6px", top: "6px" }} />,
    bottomLeft: <CornerHandle style={{ left: "6px", top: "6px" }} />,
    bottomRight: <CornerHandle style={{ left: "6px", top: "6px" }} />,
  };
};

export default Handles;
