import { Box } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import WaveSurfer from "wavesurfer.js";

const SoundWave = (props) => {
  const { audioChunks = null } = props; // Initialize to null if not provided
  const waveContent = useRef(null);
  const wavesurfer = useRef(null);
  const [playOnce, setPlayOnce] = useState(false);

  useEffect(() => {
    if (waveContent.current) {
      if (wavesurfer.current) {
        wavesurfer.current.destroy();
      }
      wavesurfer.current = WaveSurfer.create({
        container: waveContent.current,
        waveColor: "#2563EB",
        progressColor: "#2563EB",
        cursorWidth: 0, // Disable the cursor
        barWidth: 2,
        barGap: 1,
        barRadius: 2,
        scrollParent: false, // Prevent scrolling
        height: waveContent.current.clientHeight, // Set height to fit container
      });

      // Fit the waveform to the container width
      const resize = () => {
        if (wavesurfer.current) {
          const containerWidth = waveContent.current.clientWidth;
          const duration = wavesurfer.current.getDuration();
          if (duration > 0) {
            const newZoom = containerWidth / duration;
            wavesurfer.current.zoom(newZoom);
          }
        }
      };

      window.addEventListener("resize", resize);
      resize();

      return () => {
        window.removeEventListener("resize", resize);
      };
    }
  }, []);

  useEffect(() => {
    if (wavesurfer.current && audioChunks) {
      const audioBlob = new Blob(audioChunks, { type: "audio/webm" });
      const recordedUrl = URL.createObjectURL(audioBlob);
      wavesurfer.current
        .load(recordedUrl)
        .then()
        .catch((e) => console.error("Error loading audio: ", e));

      if (!playOnce) {
        setPlayOnce(true);
      }
    } else if (wavesurfer.current && !audioChunks) {
      // Clear the waveform when audioChunks is null
      wavesurfer.current.empty();
    }
  }, [audioChunks, playOnce]);

  return (
    <Box
      ref={waveContent}
      style={{ width: "100%", height: "100%", overflow: "hidden" }}
    ></Box>
  );
};

export const AudioWave = SoundWave;
