import React, { useState } from "react";
import ToolbarIcon from "../../common/ToolbarIcon";
import Icon from "../../common/Icon";
import { insertTopBanner } from "../../utils/topBanner";
import ImageSelector from "../../common/ImageSelector/ImageSelector";

const TopBannerButton = (props) => {
  const { editor, icoBtnType, customProps } = props;
  const [open, setOpen] = useState(false);

  const onSelectImage = (url) => {
    if (url) {
      insertTopBanner(editor, {
        url,
      });
    }
    setOpen(false);
  };

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <ToolbarIcon
        title="Top Banner"
        onClick={handleClick}
        icon={<Icon icon={"topbanner"} />}
        icoBtnType={icoBtnType}
      />
      <ImageSelector
        open={open}
        onClose={handleClose}
        customProps={customProps}
        onSelectImage={onSelectImage}
      />
    </>
  );
};

export default TopBannerButton;
