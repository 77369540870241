/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { useTheme } from "@mui/material";
import { Transforms } from "slate";
import { useSlateStatic, ReactEditor } from "slate-react";
import GridItemPopup from "./GridItemPopup";
import { IconButton, Tooltip, Box, Grid as Item } from "@mui/material";
import { GridSettingsIcon } from "../../common/iconslist";
import { useEditorContext, useEditorSelection } from "../../hooks/useMouseMove";
import {
  groupByBreakpoint,
  getBreakPointsValue,
  getBRValue,
  getTRBLBreakPoints,
} from "../../helper/theme";
import { isEmptyNode } from "../../utils/helper";
import { wrapThemeBreakpoints } from "../FreeGrid/breakpointConstants";

const GridItemToolbar = ({ selected, showTool, onSettings }) => {
  return selected && !showTool ? (
    <div
      contentEditable={false}
      className="grid-item-toolbar"
      style={{ top: "-14px", left: "-14px" }}
    >
      <Tooltip title="Grid Item Settings" arrow>
        <IconButton size="small" onClick={onSettings}>
          <GridSettingsIcon />
        </IconButton>
      </Tooltip>
    </div>
  ) : null;
};

const GridItem = (props) => {
  const theme = useTheme();
  const { attributes, children, element, customProps } = props;
  const { readOnly } = customProps;
  const [openSetttings, setOpenSettings] = useState(false);
  const {
    grid,
    bannerSpacing,
    bgColor,
    alignment,
    borderRadius,
    borderStyle,
    borderWidth,
    borderColor,
    margin,
    bgColorHover,
    borderColorHover,
    textColor,
    animation,
    cellGHeight,
    xsHidden,
  } = element;
  const { vertical, horizantal, flexDirection } = alignment || {};
  const editor = useSlateStatic();
  const path = ReactEditor.findPath(editor, element);
  const { hoverPath } = useEditorContext();
  const selected = hoverPath === path.join(",");
  const [showTool] = useEditorSelection(editor);
  const isEmpty =
    !readOnly && isEmptyNode(editor, element?.children, path) ? "empty" : "";
  const onSettings = () => {
    setOpenSettings(true);
  };

  const onSave = (data) => {
    const updateData = { ...data };
    delete updateData.children;
    Transforms.setNodes(
      editor,
      {
        ...updateData,
      },
      {
        at: path,
      }
    );
    onClose();
  };

  const onClose = () => {
    setOpenSettings(false);
  };

  const onDelete = () => {
    if (path) {
      Transforms.removeNodes(editor, { at: path });
    }
  };

  const getBorderColor = () => {
    return borderColor || "transparent";
  };

  const getBRProps = groupByBreakpoint(
    {
      display: {
        xs: xsHidden ? "none" : "inline-block",
        lg: "inline-block",
      },
      width: {
        ...getBreakPointsValue(grid, null, "overrideGridSize", true),
      },
      height: {
        ...getBreakPointsValue(cellGHeight || "auto"),
      },
      borderRadius: {
        ...getBreakPointsValue(
          borderRadius || {},
          null,
          "overrideBorderRadius",
          true
        ),
      },
      margin: {
        ...getTRBLBreakPoints(margin),
      },
    },
    theme
  );

  const giInProps = groupByBreakpoint(
    {
      padding: {
        ...getTRBLBreakPoints(bannerSpacing),
      },
    },
    theme
  );

  return (
    <Item
      item
      component={"div"}
      className={`grid-item element-root gi-top-wrpr dpath`}
      {...attributes}
      sx={{
        ...getBRProps,
        display: "flex",
        flexDirection: flexDirection || "column",
        background: bgColor || "transparent",
        borderColor: getBorderColor(),
        borderWidth: borderWidth || "1px",
        borderStyle: borderStyle || "solid",
        alignItems: horizantal,
        justifyContent: vertical,
        "&:hover": {
          background: `${bgColorHover || bgColor || "transparent"}`,
        },
      }}
      data-path={path.join(",")}
      style={{ textAlign: element.alignment || "left" }}
    >
      {!readOnly && (
        <div
          className={`element-selector ${selected ? "selected" : ""}`}
          contentEditable={false}
          style={{ zIndex: 1000 }}
        >
          <div className="element-selector-dots tl"> </div>
          <div className="element-selector-dots tr"> </div>
          <div className="element-selector-dots bl"> </div>
          <div className="element-selector-dots br"> </div>

          <GridItemToolbar
            selected={selected}
            showTool={showTool}
            onSettings={onSettings}
          />
        </div>
      )}
      <Box
        className={`gi-inner-cw ${
          animation || ""
        } content-editable ${isEmpty} np`}
        component={"div"}
        data-path={path.join(",")}
        sx={{
          display: "flex",
          flexDirection: flexDirection || "column",
          ...giInProps,
          width: "100%",
          height: "100%",
          color: textColor || "#000",
          "&:hover": {
            borderColor: borderColorHover || borderColor || "transparent",
          },
        }}
        placeholder="Grid Item"
      >
        {children}
      </Box>
      {openSetttings ? (
        <GridItemPopup
          element={element}
          onSave={onSave}
          onClose={onClose}
          onDelete={onDelete}
          customProps={customProps}
        />
      ) : null}
    </Item>
  );
};

export default GridItem;
