import React from "react";
import {
  Grid,
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
  FormControlLabel,
  Typography,
} from "@mui/material";

import {
  JustifyStartIcon,
  JustifyCenterIcon,
  JustifyEndIcon,
  AlignEndIcon,
  AlignCenterIcon,
  AlignStartIcon,
  DirectionRowIcon,
  DirectionColumIcon,
  DirectionReverseIcon,
} from "../../iconslist";

const Alignment = (props) => {
  const { value, data, onChange } = props;
  const { key } = data;

  const handleChange = (e) => {
    onChange({
      [key]: {
        ...value,
        [e.target.name]: e.target.value,
      },
    });
  };

  const flexDirection = value?.flexDirection || "row";

  return (
    <>
      <Grid item xs={12}>
        <FormControl>
          <FormLabel id="pos-align-tems">
            <Typography
              variant="body1"
              color={"primary"}
              style={{ fontSize: "14px", fontWeight: 500 }}
            >
              Alignment
            </Typography>
          </FormLabel>
          <div style={{ display: "flex" }}>
            <RadioGroup
              row
              aria-labelledby="pos-align-tems"
              name="horizantal"
              value={value?.horizantal || "start"}
              onChange={handleChange}
              className="iconRadioButton"
              style={{ display: "flex" }}
            >
              <FormControlLabel
                value="start"
                control={<Radio />}
                label={<AlignStartIcon />}
              />
              <FormControlLabel
                value="center"
                control={<Radio />}
                label={<AlignCenterIcon />}
              />
              <FormControlLabel
                value="end"
                control={<Radio />}
                label={<AlignEndIcon />}
              />
            </RadioGroup>
            <RadioGroup
              row
              aria-labelledby="pos-jc"
              name="vertical"
              value={value?.vertical || "start"}
              onChange={handleChange}
              className="iconRadioButton"
              style={{ display: "flex" }}
            >
              <FormControlLabel
                value="start"
                control={<Radio />}
                label={<JustifyStartIcon />}
              />
              <FormControlLabel
                value="center"
                control={<Radio />}
                label={<JustifyCenterIcon />}
              />
              <FormControlLabel
                value="end"
                control={<Radio />}
                label={<JustifyEndIcon />}
              />
            </RadioGroup>
          </div>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl>
          <FormLabel id="pos-fd">
            <Typography
              variant="body1"
              color={"primary"}
              style={{ fontSize: "14px", fontWeight: 500 }}
            >
              Direction
            </Typography>
          </FormLabel>
          <RadioGroup
            row
            aria-labelledby="pos-fd"
            name="flexDirection"
            value={flexDirection}
            onChange={handleChange}
            className="iconRadioButton"
          >
            <FormControlLabel
              value="row"
              control={<Radio />}
              label={<DirectionColumIcon />}
            />
            <FormControlLabel
              value="column"
              control={<Radio />}
              label={<DirectionRowIcon />}
            />
            <FormControlLabel
              value="row-reverse"
              control={<Radio />}
              label={<DirectionReverseIcon />}
            />
          </RadioGroup>
        </FormControl>
      </Grid>
    </>
  );
};

export default Alignment;
