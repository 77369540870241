import React from "react";
import { Grid, Typography } from "@mui/material";

import FormStyles from "./Styles";
import MoreOptions from "./MoreOptions";

const ListWorkflow = (props) => {
    const { workflow, handleEditFormWorkflow, handleDeleteFormWorkflow } = props;
    const classes = FormStyles();
    const optionsList = ["Edit", "Delete"]

    const onMenuClick = (selectedMenu, selectedFlow) => () => {
        if (selectedMenu === "Edit") {
            handleEditFormWorkflow(selectedFlow)
        } else if (selectedMenu === "Delete") {
            handleDeleteFormWorkflow(selectedFlow)
        }
    }


    return (
        <Grid container gap={2}>
            {
                workflow?.map((flow, index) => {
                    const bodyData = flow?.body_data
                    return(
                    <Grid item key={`workflow_list_${index}`} xs={12}>
                        <Grid container>
                            <Grid item sx={classes.emailIndexCard}>
                                <Typography sx={{ fontSize: "12px", fontWeight: 600 }}>Email {index + 1}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container sx={classes.flowListCard} alignItems='center' justifyContent='space-between'>
                                    <Grid item xs={11}>
                                        <Grid container gap={1}>
                                            <Grid item xs={12}>
                                                <Grid container alignItems="center">
                                                    <Grid item>
                                                        <Typography sx={classes.listHeading}>Subject:</Typography>
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography sx={classes.listSubHeading} style={{ paddingLeft: '24px' }}>{flow.subject_data}</Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container alignItems="center">
                                                    <Grid item>
                                                        <Typography sx={classes.listHeading}>Body:</Typography>
                                                    </Grid>
                                                    <Grid item>
                                                            <Typography sx={classes.listSubHeading} style={{ paddingLeft: '40px' }} >{typeof(bodyData) === 'string' ? bodyData : bodyData[0]?.children[0]?.text}</Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container alignItems="center">
                                                    <Grid item>
                                                        <Typography sx={classes.listHeading}>Scheduled:</Typography>
                                                    </Grid>
                                                    <Grid item>
                                                        <Grid container>
                                                            <Grid item>
                                                                <Typography sx={classes.listSubHeading} style={{ paddingLeft: '5px' }}>{flow.schedule_type}</Typography>
                                                            </Grid>
                                                            {flow.schedule_type === 'after' &&
                                                                <Grid item>
                                                                    <Typography sx={classes.listSubHeading} style={{ paddingLeft: '5px' }}>{flow.schedule_on}{flow.schedule_every}</Typography>
                                                                </Grid>
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                        </Grid>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <MoreOptions classes={classes} menus={optionsList} selectedFlow={index} onMenuClick={onMenuClick} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                )})
            }
        </Grid>
    )
}

export default ListWorkflow