export const colors = [
  "#292D32",
  "#578CFF",
  "#A58CFF",
  "#46BBFF",
  "#FFC93A",
  "#33B24F",
  "#F06C83",
  "#64748B",
  "#82A9FE",
  "#C4B3FF",
  "#71CBFF",
  "#FFD055",
  "#61D57A",
  "#FA91A4",
  "#A2B0B9",
  "#A4C1FF",
  "#D7CBFF",
  "#9FDCFF",
  "#FFDC80",
  "#8AE59E",
  "#FCB0BE",
  "#E4ECF3",
  "#CDDCFF",
  "#E0D7FF",
  "#BEE7FF",
  "#FFE49E",
  "#B9F6C6",
  "#FDCAD3",
  "#FFFFFF",
  "#489FF8",
  "#FE7A00",
  "linear-gradient(327.62deg, #EBB9E0 13.53%, #9CEEE5 86.82%)",
  "linear-gradient(310.6deg, #7FA4F4 15.53%, #805EF5 83.64%)",
  "linear-gradient(95.19deg, #A831E7 4.17%, #F05339 88.89%)",
  "linear-gradient(90deg, #3F2AB7 12.5%, #0DC9B5 100%)",
  "#0F172A",
  "#2563EB",
  "#8360FD",
  "#19A9FC",
  "#FDB52A",
  "#0E8E2A",
  "#E1425E",
  // "#E6E6E6",
  // "#BEBEBE"
];
