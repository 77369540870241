const useGuideLineStyle = ({ theme }) => ({
  root: {
    position: "fixed",
    top: "0",
    left: "0",
    width: "2px",
    height: "1px",
    backgroundColor: "#FF70B4",
    opacity: 1,
    pointerEvents: "none",
    zIndex: 1200,
  },
  boundaryLine: {
    "& .rnd-guideline-lv": {
      position: "absolute",
      left: "calc(50% - 490px)",
      top: "0",
      height: "100%",
      width: "1px",
      backgroundColor: "#FFF",
      pointerEvents: "none",
      zIndex: 1,
      [theme.breakpoints.between("xs", "md")]: {
        left: "calc(50% - 160px)",
      },
    },
    "& .rnd-guideline-rv": {
      position: "absolute",
      left: "calc(50% + 490px)",
      top: "0",
      height: "100%",
      width: "1px",
      backgroundColor: "#FFF",
      pointerEvents: "none",
      zIndex: 1,
      [theme.breakpoints.between("xs", "md")]: {
        left: "calc(50% + 160px)",
      },
    },
    "& .white-wrapper": {
      overflowX: "hidden",
      [theme.breakpoints.between("xs", "md")]: {
        position: "absolute",
        width: "calc(50% - 162px)",
        height: "calc(100% - 2px)",
        backgroundColor: "rgba(255,255,255,1)",
        pointerEvents: "none",
        top: 2,
        "&.w_left": {
          left: 2,
        },
        "&.w_right": {
          right: 2,
          width: "calc(50% - 163px)",
        },
      },
    },
  },
});

export default useGuideLineStyle;
