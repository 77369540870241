import React from "react";

const TextIcon = () => {
  return (
    <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.66895 4.9813V3.8438C1.66895 3.12505 2.2502 2.55005 2.9627 2.55005H12.0377C12.7564 2.55005 13.3314 3.1313 13.3314 3.8438V4.9813" stroke="#64748B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M7.5 13.4501V3.06885" stroke="#64748B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M5.0376 13.45H9.9626" stroke="#64748B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default TextIcon;
