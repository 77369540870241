import { Editor, Transforms } from "slate";

const selectAll = (event, { editor, needLayout }) => {
  try {
    if (needLayout) {
      event.preventDefault();
      // Select the entire document
      const [firstNode] = Editor.nodes(editor, { at: [0] }); // First node
      const [lastNode] = Editor.nodes(editor, {
        at: [editor.children.length - 1],
      }); // Last node

      if (firstNode && lastNode) {
        Transforms.select(editor, {
          anchor: Editor.start(editor, [0]), // Start at the first node
          focus: Editor.end(editor, [editor.children.length - 1]), // End at the last node
        });
      }
    }
  } catch (err) {
    console.log(err);
  }
};

const EDITORCMDS = {
  a: selectAll,
};

export default EDITORCMDS;
