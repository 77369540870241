const TableStyles = (themeType) => ({
  tableToolBar: {
    position: "absolute",
    top: "-34px",
    left: 0,
    backgroundColor: "#FFF",
    border: "1px solid rgba(37, 99, 235, 0.32)",
    boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.16)",
    borderRadius: "0px",
    width: "32px",
    height: "32px",
    overflow: "hidden",
    transition: "all 0.3s",
    "& button": {
      "& svg": {
        width: "16px",
        height: "16px",
      },
    },
    "&.active": {
      width: "auto",
      "& .toggle": {
        rotate: "180deg",
      },
    },
    "& .toolbtn": {
      "& svg": {
        stroke: "rgb(100, 116, 139)",
      },
    },
  },
  table: {
    "& td": {
      padding: "8px 8px",
      border: "1px solid #E0E0E0",
      "&.selection": {
        "& *::selection": {
          background: "transparent",
        },
      },
    },
    "& .selection-area-tc": {
      position: "absolute",
      width: "100%",
      height: "100%",
      top: 0,
      left: 0,
      background: "rgba(149, 218, 255, .3)",
      pointerEvents: "none",
    },
  },
  cellResizer: {
    position: "absolute",
    cursor: "col-resize",
    right: "-2px",
    top: 0,
    background: "transparent",
    width: "4px",
    borderRadius: "0px",
    zIndex: 1,
    "&:hover": {
      background: "#ccc",
    },
  },
  deleteCellsPopUp: {
    backgroundColor: themeType === "dark" ? "#292C32" : "#FFFFFF",
    borderRadius: "7px !important",
    border: themeType === "dark" ? "1px solid #5B5E64" : "1px solid #E4E8EB",
    zIndex: 100,
    minWidth: "186px !important",
  },
  menuItemPopUp: {
    padding: "5px 7px !important",
    margin: "6px !important",
    borderRadius: 1,
    userSelect: "none",
    color: themeType === "dark" ? "#2563EB" : "#0F172A",
    "&:hover": {
      // backgroundColor: "rgba(0, 123, 255, 0.1)",
      backgroundColor: themeType === "dark" ? "#2563EB" : "#E9F3FE",
    },
    "&.Mui-selected": {
      backgroundColor: themeType === "dark" ? "#2563EB" : "#E9F3FE",
      color: themeType === "dark" ? "#F6F6F6" : "#0F172A",
    },
  },
});

export default TableStyles;
