const useContextMenuStyle = ({ theme }) => ({
  root: {
    zIndex: 1200,
    "& .MuiPaper-root": {
      backgroundColor: theme?.palette?.editor?.background,
      color: theme?.palette?.editor?.textColor,
    },
    "& .cm-menu-item": {
      width: "190px",
      justifyContent: "space-between",
      color: theme?.palette?.editor?.textColor,
      "& .shortcut-help-info": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: "12px",
        color: "#bbb",
        "& svg": {
          width: "12px",
          marginRight: "4px",
        },
      },
      "&:hover": {
        color: theme?.palette?.editor?.activeColor,
        background: "rgba(233, 243, 254, 1)",
        "& .shortcut-help-info": {
          color: theme?.palette?.editor?.background,
        },
      },
    },
  },
});

export default useContextMenuStyle;
