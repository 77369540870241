import React from "react";
import { Transforms } from "slate";
import { useSlateStatic, ReactEditor } from "slate-react";
import SignaturePopup from "./SignaturePopup";
import { formatDate } from "../../utils/helper";

const Signature = (props) => {
  const { attributes, children, element, customProps } = props;
  const editor = useSlateStatic();
  const isSigned = element?.children[0]?.type === "sign";
  const path = ReactEditor.findPath(editor, element);

  const onSave = (data = {}) => {
    onClear();
    Transforms.insertNodes(
      editor,
      [
        {
          type: "sign",
          signature: null,
          fontFamily: "",
          signedBy: "",
          signedOn: formatDate(new Date(), "MM/DD/YYYY"),
          signedText: "",
          children: [{ text: "" }],
          ...data,
        },
      ],
      { at: path }
    );
  };

  const onClear = () => {
    Transforms.removeNodes(editor, { at: path });
  };

  return (
    <div
      className={`signature-container`}
      {...attributes}
      contentEditable={false}
    >
      {!isSigned ? (
        <SignaturePopup
          customProps={customProps}
          onSave={onSave}
          onClear={onClear}
        />
      ) : null}
      <span style={{ display: "none" }}>{children}</span>
    </div>
  );
};

export default Signature;
