const tableStyle = [
  {
    tab: "Table",
    value: "tableSettings",
    fields: [
      {
        label: "Table Background",
        key: "table.bgColor",
        type: "color",
      },
      {
        label: "Cell Border",
        key: "table.borderColor",
        type: "color",
      },
    ],
  },
  {
    tab: "Row",
    value: "rowSettings",
    fields: [
      {
        label: "Row Background",
        key: "row.bgColor",
        type: "color",
      },
      {
        label: "Row Border",
        key: "row.borderColor",
        type: "color",
      },
    ],
  },
  {
    tab: "Column",
    value: "columnSettings",
    fields: [
      {
        label: "Entire Column Background",
        key: "col.entireBgColor",
        type: "color",
      },
      {
        label: "Selected Cell Background",
        key: "col.bgColor",
        type: "color",
      },
      {
        label: "Selected Cell Border Color",
        key: "col.borderColor",
        type: "color",
      },
    ],
  },
  {
    tab: "Visibility",
    value: "visibility",
    fields: [
      {
        label: "Hide on Mobile",
        key: "table.xsHidden",
        type: "selectBox",
        placeholder: "Hide on Mobile",
      },
    ],
  },
];

export default tableStyle;
