import React from "react";

const AddTemplateIcon = () => {
  return (
    <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.21541 2.18999L13.3946 4.15499C14.5987 4.71749 14.5987 5.64749 13.3946 6.20999L9.21541 8.17499C8.74083 8.39999 7.96166 8.39999 7.48708 8.17499L3.30791 6.20999C2.10374 5.64749 2.10374 4.71749 3.30791 4.15499L7.48708 2.18999C7.96166 1.96499 8.74083 1.96499 9.21541 2.18999Z" stroke="#64748B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M2.125 8.25C2.125 8.88 2.57125 9.6075 3.11667 9.8625L7.92625 12.1275C8.29458 12.3 8.7125 12.3 9.07375 12.1275L13.8833 9.8625C14.4287 9.6075 14.875 8.88 14.875 8.25" stroke="#64748B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M2.125 12C2.125 12.6975 2.51458 13.3275 3.11667 13.6125L7.92625 15.8775C8.29458 16.05 8.7125 16.05 9.07375 15.8775L13.8833 13.6125C14.4854 13.3275 14.875 12.6975 14.875 12" stroke="#64748B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default AddTemplateIcon;
