import React from "react";
import { Transforms } from "slate";
import { ReactEditor, useSlateStatic } from "slate-react";
import ToolbarIcon from "../../common/ToolbarIcon";
import { NewLineElementIcon } from "../../common/iconListV2";

const NewLineButton = ({ icoBtnType }) => {
  const editor = useSlateStatic();

  const onAddNewLine = () => {
    Transforms.insertNodes(
      editor,
      [
        {
          type: "paragraph",
          children: [{ text: "" }],
        },
      ],
      { at: [editor.children.length], select: true }
    );
    ReactEditor.focus(editor);
  };

  return (
    <ToolbarIcon
      title="New Line"
      onClick={onAddNewLine}
      icon={
        <NewLineElementIcon
          sx={{ fill: "#64748B", width: "18px", height: "18px" }}
        />
      }
      icoBtnType={icoBtnType}
    />
  );
};

export default NewLineButton;
