import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import FormStyles from "./Styles";
import FormWorkflow from "./FormWorkflow";

//Constants
import { minutes, hours, days } from "./constant";
import ListWorkflow from "./ListWorkflow";
import { PlusIcon } from "../../../common/iconslist";
import { useEditorContext } from "../../../hooks/useMouseMove";

const Workflow = (props) => {
  const { openWorkflow, element: propElement, closeWorkflow, onSave, customWorkflowElement = null } = props;
  // replacing element for forms in DND
  const element = customWorkflowElement ? customWorkflowElement : propElement;
  const { workflow } = element;
  const { theme } = useEditorContext();
  const classes = FormStyles(theme);
  const [workflowList, setWorkflowList] = useState([]);
  const [formData, setFormData] = useState(false);
  const [schedule, setSchedule] = useState("immediately");
  const [scheduleEvery, setScheduleEvery] = useState("minutes");
  const currentInstant =
    scheduleEvery === "minutes"
      ? minutes
      : scheduleEvery === "hours"
        ? hours
        : scheduleEvery === "days"
          ? days
          : [];
  const [scheduleOn, setScheduleOn] = useState(currentInstant[0]);
  const [subject, setSubject] = useState("Welcome to Flozy!");
  const [bodyData, setBodyData] = useState(
    [{ type: "paragraph", children: [{ text: "Write Something" }] }]
  );
  const [flowEdited, setFlowEdited] = useState({
    isEdited: false,
    listIndex: null,
  });

  useEffect(() => {
    setWorkflowList(workflow || []);
  }, [workflow]);

  useEffect(() => {
    setScheduleOn(currentInstant[0]);
  }, [currentInstant]);

  const handleAddFormWorkflow = () => {
    setFormData(true);
  };

  const onFormBack = () => {
    setFormData(false);
    setBodyData([{ type: "paragraph", children: [{ text: "" }] }]);
    setSubject("");
    setSchedule("immediately");
  };

  const saveFormWorkflow = () => {
    let workflowData = [...workflowList];
    let subjectText = document?.getElementById('email_subject')?.value
    let bodyHtml = document?.getElementsByClassName('editorWorkflow')[0]?.innerHTML
    let data = {
      schedule_type: schedule,
      schedule_every: schedule === "after" ? scheduleEvery : 0,
      schedule_on: schedule === "after" ? scheduleOn : 0,
      subject_data: subject,
      body_data: bodyData,
      subject_text: subjectText,
      body_html: bodyHtml,
    };
    if (flowEdited.isEdited) {
      workflowData[flowEdited.listIndex] = data;
    } else {
      workflowData.push(data);
    }
    let saveData = { workflow: workflowData };
    onSave(saveData);
    setFormData(false);
    setBodyData([{ type: "paragraph", children: [{ text: "" }] }]);
    setSubject("");
    setSchedule("immediately");
    setFlowEdited({ isEdited: false, listIndex: null });
  };

  const handleEditFormWorkflow = (index) => {
    const result = workflowList.find((flow, indx) => indx === index);
    setBodyData(result.body_data);
    setSubject(result.subject_data);
    setSchedule(result.schedule_type);
    setFormData(true);
    setFlowEdited({ isEdited: true, listIndex: index });
  };

  const handleDeleteFormWorkflow = (index) => {
    const result = workflowList.filter((flow, indx) => indx !== index);
    let saveData = { workflow: result };
    onSave(saveData);
    setWorkflowList(result);
  };

  const handleSelectedVariables = (data, type) => () => {
    if (type === 1) {
      let newString = subject.concat(` {{${data.name}}}`);
      setSubject(newString);
    } else if (type === 2) {
      let newString = bodyData.concat(` {{${data.name}}}`);
      setBodyData(newString);
    }
  };

  const handleInputReset = (type) => () => {
    if (type === 1) {
      setSubject("");
    } else if (type === 2) {
      setBodyData("");
    }
  };

  return (
    <Dialog
      sx={classes?.formWorkflow}
      open={openWorkflow}
      onClose={closeWorkflow}
      fullWidth={true}
    >
      <DialogTitle
        sx={classes.popupTitle}
        style={{
          padding: formData ? "16px 12px" : "16px 24px",
          justifyContent: !formData ? "space-between" : "",
        }}
      >
        {formData && (
          <IconButton onClick={onFormBack}>
            <ArrowBackIcon />
          </IconButton>
        )}
        Follow-Up Email
        {!formData && workflowList?.length > 0 && (
          <Button
            variant="outlined"
            sx={{
              textTransform: "none",
              background: "#EBF1F9",
              padding: "7px 15px",
            }}
            onClick={handleAddFormWorkflow}
            size="small"
            startIcon={<PlusIcon />}
          >
            {" "}
            New Email
          </Button>
        )}
      </DialogTitle>
      <DialogContent>
        {!formData && workflowList?.length > 0 && (
          <ListWorkflow
            workflow={workflowList}
            handleEditFormWorkflow={handleEditFormWorkflow}
            handleDeleteFormWorkflow={handleDeleteFormWorkflow}
          />
        )}
        {(formData || workflowList?.length === 0) && (
          <FormWorkflow
            subject={subject}
            schedule={schedule}
            bodyData={bodyData}
            element={element}
            currentInstant={currentInstant}
            scheduleEvery={scheduleEvery}
            scheduleOn={scheduleOn}
            onFormBack={onFormBack}
            setSubject={setSubject}
            setBodyData={setBodyData}
            setSchedule={setSchedule}
            setScheduleEvery={setScheduleEvery}
            setScheduleOn={setScheduleOn}
            handleSelectedVariables={handleSelectedVariables}
            handleInputReset={handleInputReset}
            classes={classes}
          />
        )}
      </DialogContent>
      <DialogActions sx={classes.dialogFooter}>
        <Button
          className="secondaryBtn"
          sx={classes.closeBtn}
          onClick={closeWorkflow}
          size="small"
        >
          Close
        </Button>
        {(formData || workflowList?.length === 0) && (
          <Button
            className="primaryBtn"
            sx={classes.saveBtn}
            onClick={saveFormWorkflow}
            size="small"
          >
            Save
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default Workflow;
