import React, { useState } from "react";
import { Transforms } from "slate";
import { useSlateStatic, ReactEditor } from "slate-react";
import { Box } from "@mui/material";
import ChipTextPopup from "./ChipTextPopup";
import { getTRBLBreakPoints, getBreakPointsValue } from "../../helper/theme";
import MUIIcon from "../../common/StyleBuilder/fieldTypes/loadIcon";

const ChipText = (props) => {
  const { attributes, element, customProps, children } = props;
  const { readOnly } = customProps;
  const {
    bgColor,
    textColor,
    bannerSpacing,
    borderRadius,
    borderColor,
    buttonIcon,
    textSize,
  } = element;
  const [openSetttings, setOpenSettings] = useState(false);
  const editor = useSlateStatic();
  const path = ReactEditor.findPath(editor, element);

  const handleStyle = () => {
    if (!readOnly) {
      setOpenSettings(true);
    }
  };

  const onClose = () => {
    setOpenSettings(false);
  };

  const onSave = (data) => {
    try {
      const updateData = { ...data };
      delete updateData.children;
      Transforms.setNodes(editor, { ...updateData }, { at: path });
      onClose();
    } catch (err) {
      console.log(err);
    }
  };

  const onDelete = () => {
    try {
      Transforms.removeNodes(editor, { at: path });
      onClose();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Box
      component={"button"}
      {...attributes}
      className="editor-chip-text"
      contentEditable={false}
      sx={{
        display: "inline-block",
        verticalAlign: "middle",
        padding: {
          ...getTRBLBreakPoints(bannerSpacing),
        },
        border: borderColor ? `1px solid ${borderColor}` : "none",
        borderRadius: {
          ...getBreakPointsValue(
            borderRadius || {},
            null,
            "overrideBorderRadius",
            true
          ),
        },
        background: bgColor || "#CCC",
        color: textColor,
      }}
    >
      <MUIIcon
        iconName={buttonIcon || "Check"}
        style={{
          fontSize: textSize || "16px",
          lineHeight: textSize || "16px",
          display: "inline-block",
          verticalAlign: "middle",
        }}
        onClick={handleStyle}
        props={customProps}
      />
      <div
        contentEditable={false}
        style={{
          display: "inline-block",
          verticalAlign: "middle",
          fontSize: textSize || "16px",
          width: "0px",
        }}
      >
        {children}
      </div>
      {openSetttings !== false ? (
        <ChipTextPopup
          element={element || {}}
          onSave={onSave}
          onClose={onClose}
          onDelete={onDelete}
          customProps={customProps}
        />
      ) : null}
    </Box>
  );
};

export default ChipText;
