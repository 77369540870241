const accordionTitleBtnStyle = [
  {
    tab: "Colors",
    value: "accordionColor",
    fields: [
      {
        label: "Accordion Color",
        key: "accordionTextColor",
        type: "color",
        needPreview: true,
        hideGradient: true,
      },
      {
        label: "Accordion Background Color",
        key: "accordionBgColor",
        type: "color",
      },
      {
        label: "Accordion Border Color",
        key: "accordionBorderColor",
        type: "color",
      },
    ],
  },
];

export default accordionTitleBtnStyle;
