import React from "react";
import {
  Box,
  Paper,
  Popper,
  Typography,
  Tooltip,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import useOptionsPopupStyle from "./style";

const OptionsPopup = (props) => {
  const { theme } = props;
  const classes = useOptionsPopupStyle({ theme });
  const { title, children, open, anchorEl, placement, handleClose } = props;
  return anchorEl ? (
    <Popper
      sx={classes.root}
      open={open}
      anchorEl={anchorEl}
      placement={placement}
      onClose={handleClose}
    >
      <Paper className="papper-root">
        <Typography variant="div" className="item-wrapper title">
          {title}
          <Box component={"div"}>
            <Tooltip title="Close" arrow>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Tooltip>
          </Box>
        </Typography>
        {children}
      </Paper>
    </Popper>
  ) : null;
};

export default OptionsPopup;
