import React from "react";
import { Transforms, Node } from "slate";
import { Box } from "@mui/material";
import { StyleContent } from "../../../StyleBuilder";
import boxStyle from "../../../StyleBuilder/boxStyle";

const BoxSettings = (props) => {
  const { editor, path, customProps } = props;
  const item_path = path?.split("|").map((m) => parseInt(m));
  const element_path = [...item_path];
  const element = Node.get(editor, element_path);

  const onChange = (data) => {
    const updated_props = {
      ...element,
      ...data,
      field_type: data?.element,
    };
    delete updated_props.children;
    Transforms.setNodes(
      editor,
      {
        ...updated_props,
      },
      {
        at: element_path,
      }
    );
  };

  const handleClose = () => {
    console.log("close");
  };

  return (
    <Box component={"div"} className="item-w">
      {boxStyle?.map((m, i) => {
        return (
          <StyleContent
            key={`tab_${m.value}_$${i}`}
            renderTabs={boxStyle}
            value={m.value}
            element={element}
            onChange={onChange}
            customProps={customProps}
            handleClose={handleClose}
          />
        );
      })}
    </Box>
  );
};

export default BoxSettings;
