import { Box, Button, IconButton, Popper, Typography } from "@mui/material";
import React, { useRef, useState } from "react";
import { FaChevronRight } from "react-icons/fa";

function CustomSelect({ classes, options, onSend, show }) {
  if (show) {
    return (
      <Box component="div" sx={classes.customSelectContainer}>
        {options?.map((groupOption, index) => {
          const { options, groupLabel } = groupOption;

          return (
            <React.Fragment key={index}>
              {groupLabel && (
                <Typography variant="body2" sx={classes.optionHeading}>
                  {groupLabel}
                </Typography>
              )}

              {options?.map((option, i) => {
                return (
                  <DisplayOption
                    key={i}
                    option={option}
                    classes={classes}
                    onSend={onSend}
                  />
                );
              })}
            </React.Fragment>
          );
        })}
      </Box>
    );
  } else {
    return <></>;
  }
}

export default CustomSelect;

function DisplayOption({ option, classes, onSend }) {
  const { Icon } = option;
  const [open, setOpen] = useState(false);
  const optionRef = useRef();

  const openOptions = (e) => {
    if (option.options?.length && !open) {
      setOpen(e.currentTarget);
      return;
    }
  };

  return (
    <Box
      sx={classes.optionWrapper}
      ref={optionRef}
      onMouseEnter={openOptions}
      onMouseLeave={() => setOpen(null)}
    >
      <Button
        sx={classes.optionBtn}
        onClick={(e) => {
          e.stopPropagation();

          // is having child options
          if (option.options?.length) {
            openOptions(e);
            return;
          }

          setOpen(null);
          onSend(option.value, option);
        }}
        id="infinity-select-popover"
        className={open ? "active" : ""}
      >
        <div className="option-label" id="infinity-select-popover">
          {Icon && <Icon />}

          {option.label}
        </div>

        {option.options?.length && (
          <IconButton>
            <FaChevronRight color="#94A3B8" size={12} />
          </IconButton>
        )}
      </Button>

      <Popper
        open={open && option.options}
        anchorEl={open}
        sx={{ zIndex: 9001, background: "transparent" }}
        onClose={() => {
          setOpen(null);
        }}
        placement="right-start"
      >
        <Box>
          <CustomSelect
            options={option.options}
            onSend={onSend}
            classes={classes}
            show={open}
          />
        </Box>
      </Popper>
    </Box>
  );
}
