import { Transforms } from "slate";

const insertNewLine = (editor) => {
  Transforms.insertNodes(
    editor,
    { type: "paragraph", children: [{ text: "" }] },
    { at: [editor.children.length] }
  );
};

export default insertNewLine;
