import React, { useRef, useState } from "react";
import {
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import Icon from "../../common/Icon";
import { Transforms } from "slate";
import { TableUtil } from "../../utils/table.js";
import "./TableSelector.css";
import CloseIcon from "@mui/icons-material/Close";
import ToolbarIcon from "../../common/ToolbarIcon";

const TableSelector = ({ editor, icoBtnType }) => {
  const tableOptionsRef = useRef();
  const [selection, setSelection] = useState();
  const [showOptions, setShowOptions] = useState(false);
  const [tableData, setTableData] = useState({
    row: 2,
    column: 2,
  });
  const table = new TableUtil(editor);

  const handleButtonClick = () => {
    setSelection(editor.selection);
    setShowOptions(true);
  };

  const handleInsert = () => {
    selection && Transforms.select(editor, selection);
    table.insertTable(tableData.row, tableData.column);
    setShowOptions(false);
  };

  const handleChange = (e) => {
    setTableData({
      ...tableData,
      [e.target.name]: e.target.value,
    });
  };

  const onCancel = () => {
    setShowOptions(false);
  };

  return (
    <div ref={tableOptionsRef} className="popup-wrapper1 ">
      <ToolbarIcon
        title="Table"
        style={{ border: showOptions ? "1px solid lightgray" : "none" }}
        onClick={handleButtonClick}
        icon={<Icon icon="table" />}
        icoBtnType={icoBtnType}
      />
      {showOptions ? (
        <Dialog open={showOptions} className={`dialogComp tablePopup`}>
          <Grid item xs={12} sx={{ p: 3 }}>
            <DialogTitle sx={{ p: 0, pb: 2 }}>
              <Grid container justifyContent={"space-between"}>
                <Typography
                  variant="h6"
                  className="popupTitle"
                  color={"primary"}
                >
                  Table
                </Typography>
                <Grid style={{ display: "flex" }}>
                  <IconButton onClick={onCancel} className="close-popupbtn">
                    <CloseIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </DialogTitle>
            <DialogContent sx={{ p: 0 }}>
              <Grid container>
                <Grid item xs={12} sx={{ pb: 2, pt: 0 }}>
                  <Grid item xs={12} style={{ marginBottom: "5px" }}>
                    <Typography
                      variant="body1"
                      color={"primary"}
                      sx={{ fontSize: "14px", fontWeight: 500 }}
                    >
                      No.of Rows
                    </Typography>
                  </Grid>
                  <TextField
                    fullWidth
                    name="row"
                    value={tableData?.row}
                    onChange={handleChange}
                    // label="No.of Rows"
                    placeholder="No.of Rows"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Grid item xs={12} style={{ marginBottom: "5px" }}>
                    <Typography
                      variant="body1"
                      color={"primary"}
                      sx={{ fontSize: "14px", fontWeight: 500 }}
                    >
                      No.of Columns
                    </Typography>
                  </Grid>
                  <TextField
                    fullWidth
                    name="column"
                    value={tableData?.column}
                    onChange={handleChange}
                    // label="No.of Columns"
                    placeholder="No.of Columns"
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions sx={{ p: 0, pt: 2 }}>
              <Button onClick={onCancel} className="secondaryBtn">
                Cancel
              </Button>
              <Button onClick={handleInsert} className="primaryBtn">
                Save
              </Button>
            </DialogActions>
          </Grid>
        </Dialog>
      ) : null}
    </div>
  );
};

export default TableSelector;
// export default styled(commonStyle)(TableSelector);
