import React, { useState } from "react";
import { useSlateStatic } from "slate-react";
import { Box, Divider, Grid, IconButton, Tooltip } from "@mui/material";
import { toolbarGroups } from "../toolbarGroups";
import { MarkButton } from "../FormatTools";
import LinkButton from "../../Elements/Link/LinkButton";
import { addMarkData, isBlockActive } from "../../utils/SlateUtilityFunctions";
import ColorPickerButton from "../../common/ColorPickerButton";
import { colors } from "../../Elements/Color Picker/defaultColors";
import VariableButton from "../../Elements/Variables/VariableButton";
import Icon from "../../common/Icon";

const allTools = toolbarGroups.flat();
const BasicToolbar = (props) => {
    const editor = useSlateStatic();

    // props
    const { otherProps: { variableOptions, fontStyleOptions, hideLink = false, hideTextColor = false, hideResetIcon= true, onResetClick = () => {} } } = props;
    // state
    const [activeColor, setActiveColor] = useState("#000000");

    const fontStyle = allTools.filter((f) => (f.basic || fontStyleOptions?.includes(f.format)));

    const link = allTools.find((f) => f.format?.indexOf("link") >= 0);

    const handleTextColor = (color) => {
        setActiveColor(color);
        addMarkData(editor, { format: 'color', value: color })
    };

    return (
        <Box component={'div'} className="basic-toolbar">
            <Grid container sx={{padding: '10px'}} alignItems={'center'} justifyContent={'space-between'}>
                <Grid item>
                    <Grid container alignItems={'center'}>
                        <Grid item> 
                            {fontStyle?.map((m, i) => {
                                return (
                                    <MarkButton
                                        key={`pptool_mark_${i}_${m.id}`}
                                        editor={editor}
                                        {...m}
                                    />
                                );
                            })}
                        </Grid>
                        {!hideLink &&
                            <Grid item>
                                <LinkButton
                                    key={link.id}
                                    active={isBlockActive(editor, link.format)}
                                    editor={editor}
                                />
                            </Grid>
                        }
                        {!hideTextColor && 
                            <Grid item>
                                <Tooltip title="Font Color">
                                    <>
                                        <ColorPickerButton value={activeColor || "#0000"} onSave={(color) => { handleTextColor(color) }} defaultColors={colors} />
                                    </>
                                </Tooltip>
                            </Grid>
                        }
                    </Grid>
                </Grid>
                <Grid item>
                    <Grid container alignItems={"center"}>
                        {!hideResetIcon &&
                            <Grid item>
                                <IconButton onClick={onResetClick}><Icon icon="resetIcon" /></IconButton>
                            </Grid>
                        }
                        <Grid item>
                            {(variableOptions && (variableOptions.length > 0)) && 
                            <VariableButton
                                placeholder={"Variables"}
                                options={variableOptions}
                            />
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Divider />
        </Box>
    )
}

BasicToolbar.defaultProps = {
    otherProps : {
        variableOptions: [],
        fontStyleOptions: []
    }
}

export default BasicToolbar;