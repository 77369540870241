import React from "react";
import { useTheme, ThemeProvider, createTheme } from "@mui/material";
import { isStimulator, STIMULATOR_MOCK } from "../../../hooks/useBreakpoints";

// Custom breakpoints functions
const customUp = (key, breakpoints) => {
  const MOCK_BREAKPOINT = isStimulator();
  if (key === "xs") {
    // Simulate xs 'up' as being up from 0px (xs)
    return "@media (min-width: 0px)";
  }
  if (MOCK_BREAKPOINT && key === "lg") {
    return `@media (min-width: 10000px)`;
  }
  return `@media (min-width: ${breakpoints[key]}px)`;
};

const customDown = (key, breakpoints) => {
  if (key === "md") {
    // Simulate 'down' from md
    return "@media (max-width: 960px)";
  }
  return `@media (max-width: ${breakpoints[key]}px)`;
};

const customBetween = (start, end, breakpoints) => {
  // Custom logic for between, simulating xs for demonstration
  const MOCK_BREAKPOINT = isStimulator();
  if (MOCK_BREAKPOINT && start === "xs" && end === "md") {
    return STIMULATOR_MOCK[MOCK_BREAKPOINT];
  }
  return `@media (min-width: ${breakpoints[start]}px) and (max-width: ${breakpoints[end]}px)`;
};

const customOnly = (key, breakpoints) => {
  // Custom logic for between, simulating xs for demonstration
  const MOCK_BREAKPOINT = isStimulator();
  if (MOCK_BREAKPOINT && key === "xs") {
    return STIMULATOR_MOCK[MOCK_BREAKPOINT];
  }
  return `@media (min-width: ${breakpoints[key]}px) and (max-width: ${
    breakpoints[key + 1]
  }px)`;
};

// Extend the existing theme with custom breakpoint functions
const extendedTheme = (prevTheme) =>
  createTheme({
    ...prevTheme,
    breakpoints: {
      ...prevTheme.breakpoints,
      up: (key) => customUp(key, prevTheme.breakpoints.values),
      down: (key) => customDown(key, prevTheme.breakpoints.values),
      between: (start, end) =>
        customBetween(start, end, prevTheme.breakpoints.values),
      only: (key) => customOnly(key, prevTheme.breakpoints.values),
    },
  });

const ViewportStimulator = ({ children }) => {
  const theme = useTheme();
  const viewportTheme = extendedTheme(theme);
  return <ThemeProvider theme={viewportTheme}>{children}</ThemeProvider>;
};

export default ViewportStimulator;
