export const serializeToText = (node) => {
  try {
    if (!node?.type && node?.text) {
      return node?.text;
    }
    let n = Array.isArray(node) ? node : node?.children;
    n = n && Array.isArray(n) ? n : n ? [n] : [];
    let block = n
      .map((m) => {
        return serializeToText(m);
      })
      .join(" ");
    return block;
  } catch (err) {
    console.log(err);
    return null;
  }
};
