import React, { useState } from "react";
import { Editor, Transforms } from "slate";
import { IconButton } from "@mui/material";
import SmartButtonIcon from "@mui/icons-material/SmartButton";
import { removeChipText } from "../../utils/insertChipText";
import { insertIconText } from "../../utils/insertIconText";
import ChipTextPopup from "./InlineIconPopup";

const InlineIconButton = (props) => {
  const { editor, customProps } = props;
  const [openSetttings, setOpenSettings] = useState(false);
  const handleClick = () => {
    const [parent, parentPath] = Editor.parent(
      editor,
      editor.selection.focus.path
    );
    if (parent && parent?.type === "chip-text") {
      setOpenSettings({
        element: parent,
        path: parentPath,
      });
      // removeChipText(editor, parentPath);
    } else {
      insertIconText(editor, { url: "", showInNewTab: false });
    }
  };

  const onSave = (data) => {
    const updateData = { ...data };
    delete updateData.children;
    Transforms.setNodes(editor, { ...updateData }, { at: openSetttings?.path });
    onClose();
  };

  const onClose = () => {
    setOpenSettings(false);
  };

  const onDelete = () => {
    removeChipText(editor, openSetttings?.path);
    onClose();
  };

  return (
    <>
      <IconButton title="Icon" onClick={handleClick}>
        <SmartButtonIcon sx={{ fill: "#64748B" }} />
      </IconButton>
      {openSetttings !== false ? (
        <ChipTextPopup
          element={openSetttings?.element || {}}
          onSave={onSave}
          onClose={onClose}
          onDelete={onDelete}
          customProps={customProps}
        />
      ) : null}
    </>
  );
};

export default InlineIconButton;
