import { Transforms } from "slate";
import carousel_item from "../Elements/Grid/templates/carousel_item";

export const carouselItem = (itemNo = "") => {
  return {
    type: "carousel-item",
    image: null,
    children: [{ ...carousel_item(itemNo) }],
  };
};

export const insertCarouselItem = (editor) => {
  Transforms.insertNodes(editor, { ...carouselItem() });
  Transforms.move(editor);
};
