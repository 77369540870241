import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";

const iconLoader = async (iconName, ...props) => {
  try {
    const [{ props: {ICON_API} }]=props 
    const iconResponse = await fetch(`${ICON_API}/icon/${iconName}`);
    const icon = await iconResponse.text();
    return icon;
  } catch (error) {
    console.error(`Icon "${iconName}" could not be loaded.`, error);
    return null; // Or return a default fallback icon
  }
};

const DynamicIcon = ({ iconName, ...props }) => {
  const [icon, setIcon] = useState("");

  useEffect(() => {
    let mounted = true;
    iconLoader(iconName, props).then((iconHTML) => {
      if (mounted) {
        setIcon(iconHTML);
      }
    });
    return () => {
      mounted = false;
    };
  }, [iconName]);

  if (!icon) {
    return null; // Or return a placeholder/spinner
  }

  return (
    <Box
      sx={{
        display: "flex",
        width: "auto",
        height: "auto",
        paddingLeft: "4px",
        paddingRight: "4px",
      }}
      dangerouslySetInnerHTML={{ __html: icon }}
    />
  );
};

export default DynamicIcon;
