import TextIcon from "../../../assets/svg/TextIcon";
import AddElementIcon from "../../../assets/svg/AddElementIcon";
import AddTemplateIcon from "../../../assets/svg/AddTemplateIcon";
import PageSettingsButton from "../../../Elements/PageSettings/PageSettingsButton";
import UndoButton from "../../../Elements/Undo/UndoButton";
import RedoButton from "../../../Elements/Redo/RedoButton";


const MENU_OPTIONS = [
  {
    type: "textFormat",
    icon: TextIcon,
    label: "Text Style",
  },
  {
    type: "addElement",
    icon: AddElementIcon,
    label: "Add Element",
  },
  {
    type: "addTemplate",
    icon: AddTemplateIcon,
    label: "Add Template",
  },
  {
    type: "page-settings",
    icon: PageSettingsButton,
    label: "Page Settings",
  },
  {
    type: "undo",
    icon: UndoButton,
    label: "Undo",
  },
  {
    type: "redo",
    icon: RedoButton,
    label: "Redo",
  },
];

export default MENU_OPTIONS;
