import { Editor, Transforms, Path, Range, Element } from "slate";

export const createDrawerMenuNode = ({ text }) => ({
  type: "drawer",
  anchor: "left",
  menus: [
    {
      type: "menu",
      text: "Menu 1",
      url: "http://google.com",
    },
  ],
  children: [{ text: "Menu" }],
});

export const insertDrawerMenu = (editor, props) => {
  const { selection } = editor;
  const drawerMenu = createDrawerMenuNode(props);
  if (!!selection) {
    const [parent, parentPath] = Editor.parent(editor, selection.focus.path);

    if (editor.isVoid(parent)) {
      Transforms.insertNodes(
        editor,
        { type: "paragraph", children: [drawerMenu] },
        {
          at: Path.next(parentPath),
          select: true,
        }
      );
    } else if (Range.isCollapsed(selection)) {
      Transforms.insertNodes(editor, drawerMenu, { select: true });
    } else {
      Transforms.wrapNodes(editor, drawerMenu, { split: true });
    }
  } else {
    Transforms.insertNodes(editor, {
      type: "paragraph",
      children: [drawerMenu],
    });
  }
};

export const removeDrawerMenu = (editor, path) => {
  Transforms.unwrapNodes(editor, {
    at: path,
    match: (n) =>
      !Editor.isEditor(n) && Element.isElement(n) && n.type === "drawer",
  });
};
