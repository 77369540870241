import { Editor, Transforms, Path, Range, Element } from "slate";

export const appHeaderNode = ({ menus }) => {
  return {
    type: "app-header",
    appTitle: "Logo",
    appLogo: "none",
    menus: menus || [
      { type: "menu", url: "home", target: "", text: "Home", linkType: "page" },
      {
        type: "menu",
        url: "wireframe2",
        target: "",
        text: "Wireframe",
        linkType: "page",
      },
      {
        type: "menu",
        url: "https://www.google.com",
        target: "",
        text: "Work",
        linkType: "webAddress",
      },
      {
        type: "menu",
        url: "https://www.google.com",
        target: "",
        text: "Contact",
        linkType: "webAddress",
      },
    ],
    menuStyle: "stacked",
    children: [{ text: " " }],
    logoFontSize: "32px",
    titleFontFamily: "PoppinsBold",
    bgColor: "rgb(255, 255, 255, 0)",
    borderColor: "rgba(255, 255, 255, 0)",
    textColor: "rgb(0, 0, 0)",
    cellBgColor: "#FFFFFF",
  };
};

export const createAppHeaderNode = ({ menus }) => ({
  type: "grid",
  grid: "container",
  children: [
    {
      type: "grid-item",
      grid: 12,
      children: [...appHeaderNode({ menus })],
      bgColor: "rgba(255, 255, 255, 0)",
      lockSpacing: true,
      bannerSpacing: { top: "8", left: "8", right: "8", bottom: "8" },
      alignment: { horizantal: "center" },
    },
  ],
  alignment: { flexDirection: "column" },
  lockSpacing: true,
  bannerSpacing: { top: "8", left: "8", right: "8", bottom: "8" },
  size: { widthInPercent: 100 },
  bgColor: "rgba(255, 255, 255, 1)",
});

export const insertAppHeader = (editor, props) => {
  try {
    const { selection } = editor;
    const appHeader = createAppHeaderNode(props || {});
    if (!!selection) {
      const [parent, parentPath] = Editor.parent(editor, selection.focus.path);

      if (editor.isVoid(parent)) {
        Transforms.insertNodes(
          editor,
          { type: "paragraph", children: [appHeader] },
          {
            at: Path.next(parentPath),
            select: true,
          }
        );
      } else if (Range.isCollapsed(selection)) {
        Transforms.insertNodes(editor, [appHeader], {
          at: selection.focus.path,
        });
      } else {
        Transforms.wrapNodes(editor, appHeader, { split: true });
      }
    } else {
      Transforms.insertNodes(editor, {
        type: "paragraph",
        children: [appHeader],
      });
    }
  } catch (err) {
    alert("Something went wrong");
    console.log(err);
  }
};

export const removeAppHeader = (editor, path) => {
  Transforms.unwrapNodes(editor, {
    at: path,
    match: (n) =>
      !Editor.isEditor(n) && Element.isElement(n) && n.type === "app-header",
  });
};
