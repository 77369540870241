import { Editor, Range, Point, Element, Transforms, Node } from "slate";
import { TableUtil, createTableCell } from "../utils/table";
const NON_DELETABLE_BLOCKS = ["table-cell", "carousel-item"];

const withTable = (editor) => {
  const { deleteBackward, deleteForward, delete: slateDelete } = editor;

  editor.delete = (arg) => {
    if (arg?.reverse) {
      const table = new TableUtil(editor);
      const cellsSelected = table.isCellSelected(editor.selection);
      if (cellsSelected && cellsSelected.length > 1) {
        cellsSelected.forEach((cellPath) => {
          Transforms.removeNodes(editor, { at: cellPath });
          Transforms.insertNodes(editor, createTableCell(""), { at: cellPath });
        });

        Transforms.deselect(editor, { at: editor.selection });
      } else {
        slateDelete(arg);
      }
    } else {
      slateDelete(arg);
    }
  };

  editor.deleteBackward = (unit) => {
    const { selection } = editor;
    if (selection) {
      const [cell] = Editor.nodes(editor, {
        match: (n) =>
          !Editor.isEditor(n) &&
          Element.isElement(n) &&
          NON_DELETABLE_BLOCKS.indexOf(n.type) > -1,
      });
      const prevNodePath = Editor.before(editor, selection);

      const [tableNode] = Editor.nodes(editor, {
        at: prevNodePath,
        match: (n) =>
          !Editor.isEditor(n) &&
          Element.isElement &&
          NON_DELETABLE_BLOCKS.indexOf(n.type) > -1,
      });

      if (cell) {
        const [, cellPath] = cell;

        const start = Editor.start(editor, cellPath);
        if (Point.equals(selection.anchor, start)) {
          return;
        }
      }
      if (!cell && tableNode) {
        return;
      }
    }

    if (selection) {
      try {
        const [selectedNode] = Editor.nodes(editor, {
          match: (n) =>
            !Editor.isEditor(n) &&
            Element.isElement(n) &&
            n.type === "paragraph",
        });
        if (selectedNode && selectedNode[1]) {
          const [nodeEle, nodePath] = selectedNode;
          const dataText = Node.string(nodeEle);
          // remove first node if no text and has other nodes in index 1 or more
          if (
            editor.children.length > 1 &&
            nodePath[0] === 0 &&
            nodePath.length === 1 &&
            dataText?.length === 0
          ) {
            Transforms.removeNodes(editor, { at: nodePath });
          }
        }
      } catch (err) {
        console.log(err);
      }
    }

    deleteBackward(unit);
  };
  editor.deleteForward = (unit) => {
    const { selection } = editor;
    if (selection && Range.isCollapsed(selection)) {
      const [cell] = Editor.nodes(editor, {
        match: (n) =>
          !Editor.isEditor(n) &&
          Element.isElement(n) &&
          NON_DELETABLE_BLOCKS.indexOf(n.type) > -1,
      });

      const prevNodePath = Editor.after(editor, selection);
      const [tableNode] = Editor.nodes(editor, {
        at: prevNodePath,
        match: (n) =>
          !Editor.isEditor(n) &&
          Element.isElement &&
          NON_DELETABLE_BLOCKS.indexOf(n.type) > -1,
      });

      if (cell) {
        const [, cellPath] = cell;
        const end = Editor.end(editor, cellPath);

        if (Point.equals(selection.anchor, end)) {
          return;
        }
      }
      if (!cell && tableNode) {
        return;
      }
    }

    deleteForward(unit);
  };

  return editor;
};

export default withTable;
