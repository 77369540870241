const breakpointConstants = {
  freegrid: ["height"],
  freegridItem: ["left", "top", "marginTop", "width", "height", "gridArea"],
  freegridBox: ["left", "top", "marginTop", "width", "height", "gridArea"],
};

export function CssVar(device = "lg", key, props) {
  const keyTo = `${key}_${device}`;
  const value = props[keyTo] === undefined ? props[key] : props[keyTo];
  return value;
}

export function breakpointValues(type, breakpoint = "lg", props) {
  try {
    const breakpointDatas = breakpointConstants[type]?.reduce((a, b) => {
      const bpKey = `${b}_${breakpoint}`;
      a[b] = props[bpKey] === undefined ? props[b] : props[bpKey];
      return a;
    }, {});
    return breakpointDatas;
  } catch (err) {
    console.log(err);
    return {};
  }
}

export function formatBreakpointValues(type, breakpoint = "lg", data) {
  try {
    const bpValues = breakpointConstants[type];
    if (breakpoint === "lg") {
      return data;
    } else {
      let rtData = {};
      Object.keys(data).forEach((key) => {
        if (bpValues.indexOf(key) > -1) {
          rtData[`${key}_${breakpoint}`] = data[key];
        } else {
          rtData[key] = data[key];
        }
      });
      return rtData;
    }
  } catch (err) {
    console.log(err);
    return {};
  }
}

export function wrapThemeBreakpoints(sxProp, prop, theme) {
  try {
    const formatedSxProp = Object.keys(sxProp).reduce((a, b) => {
      switch (b) {
        case "md":
          a[theme.breakpoints.up("lg")] = {
            [prop]: sxProp["md"] || sxProp["lg"],
          };
          break;
        case "sm":
          a[theme.breakpoints.up("lg")] = {
            [prop]: sxProp["sm"] || sxProp["lg"],
          };
          break;
        case "xs":
          a[theme.breakpoints.between("xs", "md")] = {
            [prop]: sxProp["xs"] || sxProp["lg"],
          };
          break;
        default:
          a[theme.breakpoints.up("lg")] = {
            [prop]: sxProp["lg"],
          };
      }
      return a;
    }, {});
    return formatedSxProp;
  } catch (err) {
    console.log(err);
  }
}
