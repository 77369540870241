import React from "react";
import { Grid } from "@mui/material";
import Uploader from "../../Uploader";

const Upload = (props) => {
  const {
    classes,
    value,
    onUploaded,
    customProps,
    disableUpload = false,
    title,
  } = props;

  const onDone = (img) => {
    onUploaded(img);
  };

  return (
    <Grid item xs={12} sx={{ padding: 0 }} className="ims-right">
      <Uploader
        classes={classes}
        value={value}
        data={{ key: "url" }}
        customProps={customProps}
        onUploaded={onDone}
        disableUpload={disableUpload}
        title={title}
      />
    </Grid>
  );
};

export default Upload;
