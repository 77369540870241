const GridStyles = () => ({
  gridButton: {
    background: "#007FFF",
    color: "#fff",
    "&:hover": {
      background: "#0066CC",
      color: "#FFF",
    },
  },
});

export default GridStyles;
