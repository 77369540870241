import { invertColor } from "../../helper";

const SimpleTextStyle = ({ pageColor }) => ({
  root: {
    position: "relative",
    padding: "0px",
    "& .placeholder-simple-text": {
      color: "#94A3B8",
      background: "transparent",
      position: "absolute",
      zIndex: -1,
      left: "0px",
      top: "0px",
      bottom: 0,
      margin: "auto",
      pointerEvents: "none",
      height: "24px",
      overflow: "hidden",
      fontSize: "14px",
      "& .bg-pad-sl": {
        padding: "2px 4px 2px 4px",
        background: "transparent",
        color: invertColor(pageColor),
      },
    },
    "& .freegrid-section": {
      "&.enable-1:before": {
        content: "' '",
        pointerEvents: "none",
        position: "absolute",
        left: "2px",
        top: "2px",
        width: "calc(100% - 4px)",
        height: "calc(100% - 4px)",
        outline: "2px solid #2563EB",
        zIndex: 1,
      },
      "&:hover, &.enable-1": {
        "& .freegrid-section-infos": {
          pointerEvents: "none",
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          zIndex: 1,
          ":before": {
            position: "absolute",
            content: "attr(placeholder)",
            left: "0px",
            top: "0px",
            width: "auto",
            height: "auto",
            backgroundColor: "#8360FD",
            padding: "4px 6px",
            fontSize: "12px",
            fontWeight: "500",
            color: "#FFF",
            lineHeight: "12px",
          },
        },
      },
      "& .bottom-handle": {
        "&.parent": {
          bottom: "2px !important",
          zIndex: 1,
        },
      },
    },
  },
  section: {
    display: "flex",
    width: "100%",
    backgroundSize: "cover",
    justifyContent: "center",
    alignItems: "center",
    "& .simple-text": {
      width: "80%",
      maxWidth: "1440px",
    },
  },
});

export default SimpleTextStyle;
