import React from "react";
import { Box } from "@mui/material";
import { getTRBLBreakPoints, getBreakPointsValue } from "../../../helper/theme";

const FormCheckbox = (props) => {
    const { fieldProps } = props;
    const {
        bannerSpacing,
        height,
        borderRadius,
        borderStyle,
        borderWidth,
        borderColor,
        textColor,
        bgColor,
        lockSpacing,
        ...rest
    } = fieldProps;

    const onChange = (e) => {
        e.preventDefault();
    };
    return (
        <div style={{ width: "100%", display: "flex" }} contentEditable={false}>
            <Box
                component={"input"}
                {...rest}
                type="checkbox"
                onChange={onChange}
                sx={{
                    width: "100%",
                    border: `1px solid ${borderColor || "#FFF"}`,
                    padding: {
                        ...getTRBLBreakPoints(bannerSpacing),
                    },
                    height: height || "auto",
                    borderColor: borderColor || "transparent",
                    borderWidth: borderWidth || "1px",
                    borderRadius: {
                        ...getBreakPointsValue(
                            borderRadius || {},
                            null,
                            "overrideBorderRadius",
                            true
                        ),
                    },
                    borderStyle: borderStyle || "solid",
                    color: textColor || "#000",
                    background: bgColor || "transparent",
                }}
            />
        </div>
    )
}

export default FormCheckbox;