import React from "react";
import { Grid, Slider, Typography } from "@mui/material";
import useWindowResize from "../../../hooks/useWindowResize";
import { getBreakPointsValue } from "../../../helper/theme";

const GridSize = (props) => {
  const { value: val, data, onChange } = props;
  const { key } = data;
  const [size] = useWindowResize();
  const value = getBreakPointsValue(val, size?.device);

  const handleChange = (e) => {
    onChange({
      [key]: {
        ...getBreakPointsValue(val),
        [size?.device]: e.target.value,
      },
    });
  };
  return (
    <Grid item xs={12}>
      <Typography
        variant="body1"
        color={"primary"}
        sx={{ fontSize: "14px", fontWeight: 500, marginBottom: "5px" }}
      >
        {data?.label || "Grid Size"}: {value || 12}
      </Typography>
      <div className="sld-wrpr">
        <Slider
          defaultValue={12}
          value={value || 12}
          className="spacingSlider"
          step={1}
          min={1}
          max={12}
          onChange={handleChange}
        />
      </div>
    </Grid>
  );
};

export default GridSize;
