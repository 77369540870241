import React from "react";
import { Grid } from "@mui/material";
import { toolbarGroups } from "../toolbarGroups";
import { RenderToolbarIcon } from "../Toolbar";

const allTools = toolbarGroups.flat();
const elements = allTools.filter((f) => f?.group === "elements");

const AddElements = (props) => {
  const { classes, editor, customProps } = props;
  const { hideTools } = customProps;

  const filteredElements = elements.filter(
    (f) => (hideTools || []).indexOf(f.type) === -1
  );
  return (
    <Grid
      container
      className="elements-wrpr-pp"
      sx={classes.textFormatWrapper}
      spacing={2}
      style={{ margin: 0 }}
    >
      {filteredElements.map((m) => {
        return (
          <Grid
            className="ele-item-single"
            key={`ri_mi_${m.id}`}
            item
            xs={6}
            md={4}
            lg={4}
          >
            <RenderToolbarIcon
              editor={editor}
              element={m}
              customProps={customProps}
              icoBtnType={"mini"}
            />
          </Grid>
        );
      })}
    </Grid>
  );
};

export default AddElements;
