const TopBannerStyles = () => ({
  root: {
    "&:hover": {
      "& .tb-toolbar": {
        display: "block",
      },
    },
  },
  toolbar: {
    position: "absolute",
    width: "auto",
    bottom: "8px",
    left: 0,
    right: 0,
    margin: "auto",
    display: "none",
    "& button": {
      background: "#FFF",
      border: "1px solid #ccc",
      borderRadius: "6px",
      color: "#373232",
      marginRight: "8px",
      padding: "4px 8px",
      textTransform: "capitalize",
      fontSize: "14px",
      "&:hover": {
        background: "#ccc",
        color: "#373232",
      },
    },
  },
});

export default TopBannerStyles;
