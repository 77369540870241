import React, { useEffect, useRef, useState } from "react";
import Button from "../../common/Button";
import Icon from "../../common/Icon";
import {
  activeMark,
  addMarkData,
  isBlockActive,
} from "../../utils/SlateUtilityFunctions";
import usePopup from "../../utils/customHooks/usePopup";
import { sizeMap } from "../../utils/font";

const FontSize = ({ editor, format }) => {
  const urlInputRef = useRef();
  let activeValue = activeMark(editor, format);

  const [active, setActive] = useState(false);
  const [fontSize, setFontSize] = useState(16);
  const [showInput, setShowInput] = usePopup(urlInputRef);

  useEffect(() => {
    setFontSize(activeValue === "normal" ? 16 : getSizeVal(activeValue));
  }, [activeValue]);

  const getSizeVal = (value) => {
    try {
      const size =
        `${value}`?.indexOf("px") >= 0 ? value : sizeMap[value] || value;
      return parseInt(size);
    } catch (err) {
      return "";
    }
  };

  const handleButtonClick = (e) => {
    e.preventDefault();
    if (!active) {
      let activeValue = activeMark(editor, format);
      let defaultValue = activeValue
        ? sizeMap[activeValue]
          ? getSizeVal(sizeMap[activeValue])
          : +activeValue
        : 16;
      setFontSize(defaultValue);
    } else {
      setFontSize(16);
    }
    setShowInput((prev) => !prev);
    setActive(!active);
  };

  const onInputChange = (e) => {
    if (e.target.value) {
      setFontSize(e.target.value);
      addMarkData(editor, { format, value: e.target.value });
    }
  };

  const changeWithButton = (value) => {
    setFontSize(value);
    addMarkData(editor, { format, value: value });
  };

  return (
    <div ref={urlInputRef} className="popup-wrapper">
      <Button
        className={`${isBlockActive(editor, format) && "btnActive"}`}
        active={isBlockActive(editor, format)}
        style={{
          border: showInput ? "1px solid lightgray" : "",
          borderBottom: "none",
        }}
        format={format}
        onClick={handleButtonClick}
      >
        <Icon className="fontsize" icon={format} />
      </Button>
      {showInput && (
        <div
          className="fontSizeWrapper popup imagePopup"
          style={{
            display: "flex",
            justifyContent: "center",
            maxWidth: "120px",
          }}
        >
          <span
            className="controls"
            onClick={() => {
              if (activeValue && activeValue > 1) {
                changeWithButton(+fontSize - 1);
              }
            }}
          >
            -
          </span>
          <input
            value={fontSize}
            type="text"
            style={{
              border: "unset",
              outline: "unset",
              marginBottom: "unset",
              maxWidth: "36px",
              overflow: "hidden",
            }}
            onChange={onInputChange}
            disabled={!activeValue && activeValue !== 0}
          />
          <span
            className="controls"
            onClick={() => {
              if (activeValue || activeValue === 0) {
                changeWithButton(+fontSize + 1);
              }
            }}
          >
            +
          </span>
        </div>
      )}
    </div>
  );
};

export default FontSize;
