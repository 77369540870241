import { Transforms } from "slate";
import { carouselItem } from "./carouselItem";
import insertNewLine from "./insertNewLine";

export const insertCarousel = (editor) => {
  const grid = {
    type: "carousel",
    grid: "container",
    children: [{ ...carouselItem(1) }, { ...carouselItem(2) }],
  };
  Transforms.insertNodes(editor, grid, { select: true });
  insertNewLine(editor);
};
